import React, { Component } from "react";



class CurrentState extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            errors: [],
            options : []
        }
    }
    returnClass(e){
        console.log("Valor: " , e)
        // if (this.props.state.currentState == e.target){
        //     console.log("true")
        // }
        return 'e'
    }
    pageChange(){

    }

    render(){
        return(
            <div className="Botones-Pagina">
                <button name='1' className="btnUpdateImg" onClick={() => this.props.changePageButtons(1)}>1</button>
                <button name='2' className="btnUpdateImg" onClick={() => this.props.changePageButtons(2)}>2</button>
                <button name='3' className="btnUpdateImg" onClick={() => this.props.changePageButtons(3)}>3</button>
                <button name='4' className="btnUpdateImg" onClick={() => this.props.changePageButtons(4)}>4</button>
                <button name='5' className="btnUpdateImg" onClick={() => this.props.changePageButtons(5)}>5</button>
                <button name='6' className="btnUpdateImg" onClick={() => this.props.changePageButtons(6)}>6</button>
                <button name='7' className="btnUpdateImg" onClick={() => this.props.changePageButtons(7)}>7</button>
                {/* <button name='8' className="btnUpdateImg" onClick={() => this.props.changePageButtons(8)}>8</button> */}
            </div>
        )
    }
}

export default CurrentState;