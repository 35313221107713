import React, { Component } from "react";
import axios from "axios";
import NavUL from "../navbarUL";
import Header from "../Header";
import Pdf_drop from "../PDFDrop"
import ProgressBar from 'react-bootstrap/ProgressBar'
import swal from 'sweetalert';

const domain = "https://cato.skleo.com"
var intervalID;
let TestList = ["198-2731","120-9318", "1029-009"]
var indexFile = 0;
class main_marketplace extends Component {
    
    constructor(props) {
        super(props);
        this.state = {      
            upc: "999999999999", 
            uploadPercentage : 0,
            ProductsGeneral : [],
            productsYuju : [],
            currentPage : 1,
            Renderintems : 10,   
            BotonesMap : [],         
            allcheck : false,
            UPCList: [],
            currentStorePK : 0 ,
            currentMarketPK : 0 ,
            files : [],
            marketplaces : [],
            upcToMarket:[],
            product: [],
            ExtendedInfo : [],
            StockDataInfo:[],
            stockChange : [],
            ListaMarketPlace : [],
            serverResponce : '',
            checkStatus : [],
            UPCERROR:[],
            
        }
    }
    componentDidMount() {
      this.cookieCheck();         
    };
    tableHeaderRender(){
        let headers = ["FILE NAME","STATUS","ERP ORDER","orderNumber","SKU","NAME","LASTNAME","state","address1","address2","address3","ZIP","SUMA TOTAL"]
        return headers.map((header, index) => {
            return <th key={index}>{header.toUpperCase()}</th>
        })
    }
    cookieCheck = async () => {
      var token = this.getCookie( 'token' );
      //console.log( token )
      const  userBool = localStorage.getItem('user')
      const  phoneBool = localStorage.getItem('phone')
      const  emailBool = localStorage.getItem('email')
      const  mobileBool = localStorage.getItem('mobile')
      const  skypeBool = localStorage.getItem('skype')
      const myarrayBool = localStorage.getItem('myArray')
      if(emailBool === null || userBool === null || phoneBool === null ||mobileBool === null || skypeBool === null || myarrayBool === null){
        this.logout();
      }
      if ( token == null ) { this.props.history.push( '/' ) }
      
    };
    logout(){
      var cookies = document.cookie.split( ";" );
      for ( let i = 0; i < cookies.length; i++ ) {
          var cookie = cookies[ i ];
          var eqPos = cookie.indexOf( "=" );
          var name = eqPos > -1 ? cookie.substr( 0, eqPos ) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";          
      }
    };
    getCookie = function( name ) {
      var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
      return r ? r[ 1 ] : null;
    };
    uploadFile = async() =>{
      var token = this.getCookie( 'token' );
      let headerProposal ={
        headers: {
          'X-Viastara-Token': token,
        }
        }
        let copyfiles = this.state.files;
        console.log( this.state.files[indexFile].file )
        let data = new FormData();
        
        data.append ( 'pdf', this.state.files[indexFile].file )
        const options = {
          onUploadProgress: (ProgressEvent) => {
            const {loaded, total} = ProgressEvent;
            let percent = Math.floor((loaded * 100) / total)
            console.log( `${loaded}kb of ${total}kb | ${percent}% | ${this.state.files[indexFile].file.name}`);
            if (percent <100){
              this.setState({uploadPercentage : percent})
            }
          }
        }
        axios.post('https://cato.skleo.com/api/v1/amazon/processPDF', data, headerProposal, options).then(res => {
          console.log(res.data)
           this.setState({uploadPercentage:100}, ()=> {
            setTimeout(() => {
              this.setState({uploadPercentage : 0})
            if (res.data != null)
            {
              copyfiles[indexFile].status = "Uploaded";
              copyfiles[indexFile].lastname = res.data.lastname;
              copyfiles[indexFile].name = res.data.name;
              copyfiles[indexFile].orderNumber =res.data.orderNumber;
              copyfiles[indexFile].state =res.data.state;
              copyfiles[indexFile].zip =res.data.zip;
              copyfiles[indexFile].address1 =res.data.address1;
              copyfiles[indexFile].address2 =res.data.address2;
              copyfiles[indexFile].address3 =res.data.address3;
              copyfiles[indexFile].items =res.data.items;
              copyfiles[indexFile].total =res.data.total;
              this.setState({files:copyfiles});
              //this.props.update(this.state.files);
              
            }
            else{
              copyfiles[indexFile].status = "Fail";
              this.setState({files:copyfiles});
            }
            if(indexFile+1 < this.state.files.length){
              console.log(indexFile);
              indexFile = indexFile + 1
              this.uploadFile();
              
            }
            else
            {
              
              indexFile = indexFile + 1
            }
            }, 1000);
          })
        },
        (error) => { 
          //console.log(error) 
          this.setState({uploadPercentage : 0})
          
            copyfiles[indexFile].status = "Fail";
            this.setState({files:copyfiles});
            //this.props.update(this.state.files);
            if(indexFile+1 < this.state.files.length){
              console.log(indexFile);
              this.uploadFile();
              indexFile = indexFile + 1
            }
            else
            {
              indexFile = indexFile + 1
            }
        }
        );
      }
    borrarProducto= async(id)=>
    {
      {
        indexFile = indexFile - 1;
        const copiedItems = [ ...this.state.files ];
        const arr = copiedItems.filter(e => e.id !== id);
				await this.setState({
					files: arr					
				});           
      }
     }
    handelInputChange = async (event, index) =>{
        event.persist();
        let namevar = event.target.name;
        let valuevar = event.target.value;
        
        let copyElemnts = this.state.files;
        copyElemnts[index][namevar] = valuevar;
        await this.setState({files: copyElemnts})
        console.log(this.state.files[index][namevar])
      };

      handelInputChangeSku = async (event, index, indexSKU) =>{
    
        event.persist();
        let namevar = event.target.name;
        let valuevar = event.target.value;
        
        let copyElemnts = this.state.files;
  
        console.log( copyElemnts[index][namevar][indexSKU].sku )
        copyElemnts[index][namevar][indexSKU].sku = valuevar;
        
        await this.setState({files: copyElemnts})
      };
    
    update= async (e) => {
        this.setState({files:e})
      }
      renderTable = ()=>{
          return<tbody>
            <tr>{this.tableHeaderRender()}</tr>
            {this.state.files.map( ({ 
                id ,
                file , 
                status, 
                name,
                lastname,
                orderNumber,
                state,
                zip,
                address1,
                address2,
                address3,
                total,
                erpOrder,
                items}, index) => {
                
                return(
                    <tr key={index + 1 + "tr1"}>
                            <td style={{width:"10%"}}> <p><button onClick={() =>this.borrarProducto(id)} className="btnUpdateImg">x</button> {file.name}</p>  </td>
                            <td><p>{status}</p></td>
                            <td style={{width:"5%"}}> <input name='erpOrder' className="editPDF" value={erpOrder} type="text" style={{width:"50%"}}></input></td>
                            <td> <input name='orderNumber' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={orderNumber} type="text" style={{width:"50%"}}></input></td>
                            <td style={{width:"5%"}}>{items.map( ({sku,qty} ,index2,) => {
                                return ( <input name='items' key={"id" + index2} onChange={(e) => this.handelInputChangeSku(e,index,index2)} className="editPDF" value={sku} type="text" style={{width:"50%"}}></input> )
                            })}</td>
                            
                            
                            <td > <input name='name' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={name} type="text" style={{width:"50%"}}></input></td>
                            <td style={{width:"10%"}}> <input name='lastname' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={lastname} type="text" style={{width:"50%"}}></input></td>
                           

                            <td> <input name='state' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={state} type="text" style={{width:"50%"}}></input></td>
                            <td style={{width:"15%"}}> <input name='address1' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={address1} type="text" ></input></td>
                            <td style={{width:"10%"}}> <input name='address2' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={address2} type="text" ></input></td>
                            <td style={{width:"10%"}}> <input name='address3' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={address3} type="text" ></input></td>
                            <td> <input name='zip' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={zip} type="text" style={{width:"50%"}}></input></td>
                            <td> <input name='total' onChange={(e) => this.handelInputChange(e,index)} className="editPDF" value={total} type="text" style={{width:"50%"}}></input></td>
                            
                    </tr>
                    //<div key={index}><h1>{file.name}</h1></div>
                )}
            )}
            
        </tbody>
      }
      postOrder= async()=>{

        let apiOrder = "https://cato.skleo.com/api/v1/amazon/processBatch"
        var token = this.getCookie( 'token' );
        let headerProposal ={
          headers: {
            'X-Viastara-Token': token,
          }
        }
          let order = []
          for (let index = 0; index < this.state.files.length; index++) {
            let state = this.state.files[index].state
            let zip = this.state.files[index].zip
            let name = this.state.files[index].name
            let address1 = this.state.files[index].address1
            let items = this.state.files[index].items
            let orderNumber = this.state.files[index].orderNumber
            let lastname = this.state.files[index].lastname
            let address2 = this.state.files[index].address2
            let address3 = this.state.files[index].address3
            let total = this.state.files[index].total
            order.push(
              {
                state:state,
                zip:zip,
                name :name,
                address1 :address1,
                items :items,
                orderNumber:orderNumber,
                lastname:lastname,
                address2 :address2,
                address3 :address3,
                total : total
              }
            )
            
          }
          let json = JSON.stringify(order)


          let copyfiles = this.state.files;
        
        swal({
          title: "¿Estas seguro?",
          text: "Actualizar órdenes",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then(async (willDelete) => {
          if (willDelete) {
          await axios.post(apiOrder, json, headerProposal).then(res => {
            //console.log(res.data)
            for (let index = 0; index < res.data.length; index++) {
              console.log( res.data[index])
                for (let i = 0; i < copyfiles.length; i++) {
                  if(res.data[index].orderNumber === copyfiles[i].orderNumber){
                    copyfiles[i].status = res.data[index].status;
                    copyfiles[i].erpOrder = res.data[index].erpOrder;
                    
                  }
                  
                }
            }
            this.setState({ files : copyfiles })  
          })
          }
        });
       
      }

render(){
    const {uploadPercentage} = this.state;
return(
    
    <React.Fragment>
    <Header renderProposal = {() => this.renderShoppinCartList()} />
        <NavUL powers={ localStorage.getItem('level') }  newProposal = {() => this.newProposal()} site = "shooping" state = { this.state } />
        
        <div className="containerFormulario">
            
            <Pdf_drop files={this.state.files} update={this.update}/>
            <div className="ScrollTable">
            <table id="students">
                {this.renderTable()}
            </table>
            </div>
            <ProgressBar now={uploadPercentage} active="true" label={`${uploadPercentage}%`}/>
            <div className="ButtonDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
            <button className="btnForm" onClick={() => this.uploadFile()} style={{width:"200px", margin:"10px", textAlign: 'center', alignItems: "center"}}>Cargar PDF</button>
              <button className="btnForm" onClick={() => this.postOrder()} style={{width:"200px", margin:"10px", textAlign: 'center', alignItems: "center"}}>Enviar Orden</button>
              
            </div>
            
        </div>
        
                        
    </React.Fragment> );
}
 
}
export default main_marketplace;
		