import React, { Component } from "react";

class ExtendInfoStep5 extends Component {
    render(){
        return(
            <div className="">
                <h1>Medidas Producto y Paquete</h1>
                <div className="formExt">
                    <div className="filtroData4">
                        <label htmlFor="">PACKAGE CONTENT</label>
                        <input name="packageContent" onChange={this.props.handelInputChange} value={this.props.state.packageContent} className="" placeholder="PACKAGE CONTENT" type="text"></input>

                        <label htmlFor="">PACKAGING IMAGE</label>
                        <input name="packagingImage" onChange={this.props.handelInputChange} value={this.props.state.packagingImage} className="" placeholder="PACKAGING IMAGE"  type="text"></input>

                        <div className="divNumericOptions" name="">
                            <label htmlFor="productDimensionsUnit">UNIDAD PARA DIMENSIONES</label>
                            <select name="productDimensionsUnit" onChange={this.props.updateDimensions} value={this.props.state.productDimensionsUnit}className="selectUnits" id="productDimensionsUnit">
                                <option value="">- Seleccionar opcion -</option>
                                <option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="m">m</option>
                                <option value="in">in</option>
                                <option value="ft">ft</option>
                            </select>
                        </div>

                        <label htmlFor="">PRODUCT LENGTH</label>
                        <div className="divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="productLenght" onChange={this.props.handelInputChange} value={this.props.state.productLenght} type="number" id="PRODUCT_LENGHT" min="1" max="50000" step="0.001" className="agrupados" placeholder="PRODUCT LENGTH/PRODUCT LENGTH UNIT"></input>
                        {/*
                            
                                <option value="CM">CM</option>
                                <option value="M">M</option>
                                <option value="IN">IN</option>
                            </select>
                        */}
                        </div>
                        
                        <label htmlFor="">PRODUCT WIDTH</label>
                        <div className="divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="productWidht" onChange={this.props.handelInputChange} value={this.props.state.productWidht} className="agrupados" type="number" id="PRODUCT_WIDTH" min="1" max="50000" step="0.001" placeholder="PRODUCT WIDTH/PRODUCT WIDTH UNIT"></input>
                        {/*    
                            <select name="productWidhtUnit" onChange={this.props.handelInputChange} defaultValue={this.props.state.productWidhtUnit} className="selectUnits" id="PRODUCT_WIDTH_UNIT">
                                <option value="CM">CM</option>
                                <option value="M">M</option>
                                <option value="IN">IN</option>
                            </select>
                        */}
                        </div>

                        <label htmlFor="">PRODUCT HEIGHT</label>
                        <div className="divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="productHeight" onChange={this.props.handelInputChange} value={this.props.state.productHeight} type="number" id="PRODUCT_HEIGHT" min="1" max="50000" step="0.001" className="agrupados" placeholder="PRODUCT HEIGHT/PRODUCT HEIGHT UNIT"></input>
                        {/*    
                            <select name="productHeightUnit" onChange={this.props.handelInputChange} defaultValue={this.props.state.productHeightUnit} className="selectUnits" id="PRODUCT_HEIGHT_UNIT">
                                <option value="CM">CM</option>
                                <option value="M">M</option>
                                <option value="IN">IN</option>
                            </select>
                        */}
                        </div>


                        <div className="divNumericOptions" name="">
                            <label htmlFor="unitProductWeight">UNIDAD PARA PESO</label>
                            <select name="productWeightUnit" onChange={this.props.updateWeight} value={this.props.state.productWeightUnit} className="selectUnits" id="PRODUCT_WEIGHT_UNIT">
                                <option value="">- Seleccionar opcion -</option>
                                <option value="gr">gr</option>
                                <option value="kg">kg</option>
                                <option value="oz">oz</option>
                                <option value="lb">lb</option>
                            </select>
                        </div>

                        <label htmlFor="">PRODUCT WEIGHT</label>
                        <div className="divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="productWeight" onChange={this.props.handelInputChange} value={this.props.state.productWeight} type="number" id="PRODUCT_WEIGHT" min="1" max="50000" step="0.001" className="agrupados" placeholder="PRODUCT WEIGHT/PRODUCT WEIGHT UNIT"></input>
                        {/*    
                            <select name="productWeightUnit" onChange={this.props.handelInputChange} defaultValue={this.props.state.productWeightUnit} className="selectUnits" id="PRODUCT_WEIGHT_UNIT">
                                <option value="CM">CM</option>
                                <option value="M">M</option>
                                <option value="IN">IN</option>
                            </select>
                        */}
                        </div>

                    </div>

                    <div className="filtroData1">

                        <div className="divNumericOptions" name="">
                            <label htmlFor="packageDimensionsUnit">UNIDAD PARA DIMENSIONES</label>
                            <select name="packageDimensionsUnit" onChange={this.props.updateDimensions} value={this.props.state.packageDimensionsUnit} className="selectUnits" id="packageDimentionsUnit">
                                <option value="">- Seleccionar opcion -</option>
                                <option value="mm">mm</option>
                                <option value="cm">cm</option>
                                <option value="m">m</option>
                                <option value="in">in</option>
                                <option value="ft">ft</option>
                            </select>
                        </div>

                        <label htmlFor="">PACKAGE LENGTH</label>
                        <div className="divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="packageLenght" onChange={this.props.handelInputChange} value={this.props.state.packageLenght} type="number" id="PACKAGE_LENGTH" min="1" max="50000" className="agrupados" step="0.01" placeholder="PACKAGE LENGTH/PACKAGE LENGTH UNIT"></input>
                        {/*
                            
                                <option value="CM">CM</option>
                                <option value="M">M</option>
                                <option value="IN">IN</option>
                            </select>
                        */}
                        </div>

                        <label htmlFor="">PACKAGE WIDTH</label>
                        <div className="divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="packageWidht" onChange={this.props.handelInputChange} value={this.props.state.packageWidht} type="number" id="PACKAGE_WIDTH" min="1" max="50000" step="0.01" className="agrupados" placeholder="PACKAGE WIDTH/PACKAGE WIDTH UNIT"></input>
                        {/*
                            <select name="packageWidhtUnit" onChange={this.props.handelInputChange} defaultValue={this.props.state.packageWidhtUnit} onChange={this.props.handelInputChange} className="selectUnits" id="PACKAGE_WIDTH_UNIT">
                                <option value="CM">CM</option>
                                <option value="M">M</option>
                                <option value="IN">IN</option>
                            </select>
                        */}
                        </div>

                        <label htmlFor="">PACKAGE HEIGHT</label>
                        <div className="divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="packageHeight" onChange={this.props.handelInputChange} value={this.props.state.packageHeight} type="number" id="PACKAGE_HEIGHT" min="1" max="50000" step="0.01" className="agrupados" placeholder="PACKAGE HEIGHT/PACKAGE HEIGHT UNIT"></input>
                        {/*
                            <select name="packageHeightUnit" onChange={this.props.handelInputChange} defaultValue={this.props.state.packageHeightUnit} className="selectUnits" id="PACKAGE_HEIGHT_UNIT">
                                <option value="CM">CM</option>
                                <option value="M">M</option>
                                <option value="IN">IN</option>
                            </select>
                        */}
                        </div>

                        <div className="divNumericOptions" name="">
                            <label htmlFor="packageWeightUnit">UNIDAD PARA PESO</label>
                            <select name="packageWeightUnit" onChange={this.props.updateWeight} value={this.props.state.packageWeightUnit} className="selectUnits" id="packageWeightUnit">
                                <option value="">- Seleccionar opcion -</option>
                                <option value="gr">gr</option>
                                <option value="kg">kg</option>
                                <option value="oz">oz</option>
                                <option value="lb">lb</option>
                            </select>
                        </div>

                        {/*<label htmlFor="">PACKAGE WEIGHT (EN DESUSO)</label>
                        <div className="selectUnits" className="divNumericOptions">
                        <input name="packageWeight" onChange={this.props.handelInputChange} defaultValue={this.props.state.packageWeight} type="number" id="PACKAGE_WEIGHT" min="1" max="500" step="0.01" className="agrupados" placeholder="PACKAGE WEIGHT/PACKAGE WEIGHT UNIT"></input>
                        </div>*/}

                        <label htmlFor="">PACKAGE AND PRODUCT WEIGHT</label>
                        <div className="selectUnits divNumericOptions">
                            <input onKeyPress={this.props.handelInputChangeNumber} name="packageWeight" onChange={this.props.handelInputChange} value={this.props.state.packageWeight} type="number" id="PACKAGE_PRODUCT_WEIGHT" min="1" max="50000" step="0.01" className="agrupados" placeholder="PACKAGE AND PRODUCT WEIGHT/PACKAGE AND PRODUCT WEIGHT UNIT"></input>
                        {/*
                        <select name="packageProductWeightUnit" onChange={this.props.handelInputChange} defaultValue={this.props.state.packageProductWeightUnit} className="selectUnits" id="PACKAGE_PRODUCT_WEIGHT_UNIT">
                            <option value="CM">CM</option>
                            <option value="M">M</option>
                            <option value="IN">IN</option>
                        </select>
                        */}
                        </div>

                    </div>
                    <div className ="divButtonForm">
                        <button className="btnForm btnAfter" onClick={this.props.handlePageChangeBefore}> Anterior Pestaña</button>
                        <button className="btnForm btnNext" onClick={this.props.handlePageChange}> Siguente Pestaña</button>
                    </div>
                </div>
            </div>
        )
    };
}
export default ExtendInfoStep5;