import React, { Component } from "react";
import Select from 'react-select';


const options = [];
class InputTag extends Component {
    constructor(props) {
      super(props);
      
      this.state = {
        UPCList: [],
        selectedOption: [],
        color : true,

        variationTypeColor:'',
        variationTypeSize:'',
        variationTypeCapacity:'',
        variationTypeConnectivity:'',
        variationTypeHeight:'',
        variationTypePower:'',

        keywords: [],

        colorUPCs:[]

      };
    }
    


  componentDidMount(){
    try{    
      if(this.props.state.keywords !== ""){
      let arreg = this.props.state.keywords.split(';')
      console.log("arreg ", arreg.length);
      this.setState({ keywords: arreg });
    }
    else{
      this.setState({keyWords:[]});
    }
    /*
    let arreg = this.props.state.keywords.split(';')
    console.log("arreg ", arreg.length);
    this.setState({ keywords: arreg });
    */
  	}
    catch{
      console.log()
    }
    if (options.length === 0){
      this.fillOptions();
    }


  }

  fillData = () =>{
    console.log( "Entre Aqui" );
    this.props.buttonFillData()
  }
    
  fillOptions= ()=>{
    options.push({
      /*value: "variationTypeColor",
      value2: "variationTypeColorBrand",*/
      value: "colorUPCVariacion",
      value2: "variationTypeColor",
      value3: "variationTypeColorBrandEsp",
      label: "Color",
      label_2:"Color Brand",
      label_3:"Color Brand Español ",
    },
    {
      value: "variationTypeSize",
      label: "Tamaño"
    },
    {
      value: "variationTypeCapacity",
      label: "Capacidad"
    },
    {
      value: "variationTypeConnectivity",
      label: "Conectividad"
    },
    {
      value: "variationTypeHeight",
      label: "Talla"
    },
    {
      value: "variationTypePower",
      label: "Potencia"
    }
    )
  }

  crearInputs = () => {
    try{
      return (
        <React.Fragment>
          { this.props.state.selectedOption.map( todo => { 
            if (todo.label === "Color") 
              return  <React.Fragment key={todo.value + "Fragment"}>
                {/* cambio */}
                    <p>{todo.label}</p>
                    <input disabled className="todo" placeholder="Color" type="text" name={todo.value} onChange={this.props.handelInputChange} value= {this.props.state.colorUPCVariacion}/>
                    
                    <p>{todo.label_2}</p>
                    <input className="todo" placeholder="ColorBrand" type="text" name={todo.value2} onChange={this.props.handelInputChange} value= {this.props.state.variationTypeColor}/>
                    <p>{todo.label_3}</p>
                    <input className="todo" placeholder="ColorBrandEspañol " type="text" name={todo.value3} onChange={this.props.handelInputChange} value= {this.props.state.variationTypeColorBrandEsp}/>

                    {/* 
                    <input className="todo" placeholder="Color" type="text" name={todo.value} onChange={this.props.handelInputChange} value= {this.props.state.variationTypeColor}/>
                    <select className="todo" name={todo.value} value= {this.props.state.variationTypeColor} onChange={this.props.handelInputChange}>
                        <option value="">- Seleccionar opcion -</option>
                        <option value="Red">Red</option>
                        <option value="Gray">Gray</option>
                        <option value="Black">Black</option>
                    </select> 
                    */}

                  </React.Fragment>
            else if (todo.label === "Tamaño")
              return  <React.Fragment key={todo.value + "Fragment"}>
                      <p>{todo.label}</p>
                      <input className="todo" placeholder= {todo.label} value = {this.props.state.variationTypeSize} name={todo.value} onChange={this.props.handelInputChange}/>
                    </React.Fragment>
            else if (todo.label === "Capacidad")
              return  <React.Fragment key={todo.value + "Fragment"}>
                      <p>{todo.label}</p>
                      <input className="todo" placeholder= {todo.label} value = {this.props.state.variationTypeCapacity} name={todo.value} onChange={this.props.handelInputChange}/>
                    </React.Fragment>    
            else if (todo.label === "Conectividad")
              return  <React.Fragment key={todo.value + "Fragment"}>
                      <p>{todo.label}</p>
                      <input className="todo" placeholder= {todo.label} value = {this.props.state.variationTypeConnectivity} name={todo.value} onChange={this.props.handelInputChange}/>
                    </React.Fragment>
            else if (todo.label === "Talla")
              return  <React.Fragment key={todo.value + "Fragment"}>
                <p>{todo.label}</p>
                <input className="todo" placeholder= {todo.label} value = {this.props.state.variationTypeHeight} name={todo.value} onChange={this.props.handelInputChange}/>
                </React.Fragment>    
            else if (todo.label === "Potencia")
              return  <React.Fragment key={todo.value + "Fragment"}>
                <p>{todo.label}</p>
                <input className="todo" placeholder= {todo.label} value = {this.props.state.variationTypePower} name={todo.value} onChange={this.props.handelInputChange}/>
              </React.Fragment>   
          }) 
          }
        </React.Fragment>
      )
    }
    catch{}
  }

  handelInputChangeVariants = (event)=>{
    console.log("TestingSelect - Value " + event.target.value + " Name " + event.target.name);
  }

  handleChange = selectedOption => {

    this.setState(
      { selectedOption }
    );

    this.props.saveSelectedOptionType(selectedOption)
  }


  removeTag = (i) => {
    const newkeywords = [ ...this.state.keywords ];
    newkeywords.splice(i, 1);
    this.setState({ keywords: newkeywords });
  }

  inputKeyDown = async (e) => {
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      if (this.state.keywords.find(tag => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      let splitval = val.split(",");
      for ( let i = 0; i < splitval.length; i++ ) {
        console.log("splitval[i] ", splitval[i]);
        if (this.state.keywords.find(tag => tag.toLowerCase() === splitval[i].toLowerCase())) {
          console.log("Existe");
        }
        else{
          await this.setState({ keywords: [...this.state.keywords, splitval[i] ] });
        }        
      }
      
      this.tagInput.value = null;
    } else if (e.key === 'Backspace' && !val) {
      this.removeTag(this.state.keywords.length - 1);
    }
    let Newstring = this.state.keywords.join(';') 
    console.log("newString ", Newstring);

    this.props.updateKeyWords(Newstring);
  }

    
  render(){
      const { selectedOption } = this.state;
      const { keywords } = this.state;

      return(
          <div className="">
              <h1>Features y Variaciones</h1>
 
              <div className="filtroData4">
                <label htmlFor="">FEATURES</label>                        
                {this.props.addFeaures()}                                   

                <label htmlFor="">KEYWORDS (Separate each keyword with semicolon)</label>
                <input name="keyWords" onChange={this.props.handelInputChange} value={this.props.state.keyWords} className="" placeholder="KEYWORDS"  type="text" hidden></input>
              
                <div className ="" style={{marginTop: "10px", marginBottom:"10px"} } >
                    <div className="input-tag">
                    <ul className="input-tag__tags">
                        { keywords.map((tag, i) => (
                        <li key={tag}>
                            {tag}
                            <button type="button" onClick={() => { this.removeTag(i); }}>+</button>
                        </li>
                        ))}
                        <li className="input-tag__tags__input"><input type="text" onKeyDown={this.inputKeyDown} ref={c => { this.tagInput = c; }} /></li>
                    </ul>
                    </div>
                </div>
              
              </div>

              <div className="filtroData1" style={{marginTop:"10px"}}>
                  <label htmlFor="">PRODUCTS VARIATIONS</label>
                  <div className='UPCVariation'><label htmlFor="">UPC VARIATIONS</label> { this.props.comboboxFill() }</div>


                  <button onClick={() => this.fillData()}>Llenar con datos anteriores</button>

                  <label htmlFor="">VARIATION TYPE</label>
                  
                  <Select isMulti={true} value={ this.props.state.selectedOption } onChange={ this.handleChange } options={ options } />
                  {this.crearInputs()}

                  <label htmlFor="">COMPATIBILITY</label>
                  <input name="compatibility" id="compatibility" onChange={this.props.handelInputChange} value={this.props.state.compatibility} className="" placeholder="COMPATIBILITY" type="text"></input>
                  
                  <label htmlFor="">COUNTRY OF ORIGIN</label>
                  <input name="country_origin" id="country_origin" onChange={this.props.handelInputChange} value={this.props.state.country_origin} className="" placeholder="Country of Origin" type="text"></input>
                  {/* <select name="country_origin" id="country_origin" onChange={this.props.handelInputChange} value={this.props.state.country_origin} >
                      <option value="">- Seleccionar opcion -</option>
                      <option value="MEXICO">MEXICO</option>
                      <option value="CHINA">CHINA</option>
                      <option value="ESTADOS UNIDOS">ESTADOS UNIDOS</option>
                  </select> */}

                  <div>
                      <button className="agrupadosBotones" onClick={this.props.saveVariation}>Guardar variación</button>
                  </div>
                  
              </div>

              <div className="formExt">

                  <div className ="divButtonForm">
                      <button className="btnForm btnAfter" onClick={this.props.handlePageChangeBefore}> Anterior Pestaña</button>
                      <button className="btnForm btnNext" onClick={this.props.handlePageChange}> Siguente Pestaña</button>
                  </div>
              </div>
          </div>
      )
  };


}
export default InputTag;