import React, { Component } from "react";
import axios from "axios";
import NavUL from "./navbarUL";
import Header from "./Header";
// import { LazyLoadImage } from "react-lazy-load-image-component"; // Dex says: Unused component
// import "react-lazy-load-image-component/src/effects/blur.css"; // Dex says: Unused component

const domain = "https://cato.skleo.com";
//const proposaldata = "/api/v1/getProposal?prid=" // is assigned a value but never used

class dataProposals extends Component{

    constructor( props ) {
        super( props );
        this.state = {
            userName : '', 
            phone: '',
            email : '',
            mobile : '',
            skype : '',
            shoppingcart_List: [],
            products: [],
            brand: "",
            marcas: [],
            clientes: [],
            cliente: "",
            clienteV: "",
            proposals: [],
            todosProposals: [],
            todoTextProposals: "",
            messageProposals: false,
        };
        this.updatetodoTextProposals = this.updatetodoTextProposals.bind( this );
        this.createTodoProposals = this.createTodoProposals.bind( this );
        this.filterTodoProposals = this.filterTodoProposals.bind( this );
    }

    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
    };
    componentDidMount() {
        this._isMounted = true; 
        this.cookieCheck(); 
        this.renderPostProposals();
    };
    logout(){
        var cookies = document.cookie.split( ";" );
        for ( let i = 0; i < cookies.length; i++ ) {
            var cookie = cookies[ i ];
            var eqPos = cookie.indexOf( "=" );
            var name = eqPos > -1 ? cookie.substr( 0, eqPos ) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    };
    cookieCheck = async () => {
        var token = this.getCookie( 'token' );
        //console.log( token )
        const  userBool = localStorage.getItem('user')
        const  phoneBool = localStorage.getItem('phone')
        const  emailBool = localStorage.getItem('email')
        const  mobileBool = localStorage.getItem('mobile')
        const  skypeBool = localStorage.getItem('skype')
        const myarrayBool = localStorage.getItem('myArray')
        if(emailBool === null || userBool === null || phoneBool === null ||mobileBool === null || skypeBool === null || myarrayBool === null){
            this.logout();
        }     
        if ( token == null ) { this.props.history.push( '/' ) }
        await this.setState( state => {
          return { userName: ( state.userName = localStorage.getItem( 'user' ) ),
            phone: ( state.phone = localStorage.getItem( 'phone' ) ),
            email: ( state.email = localStorage.getItem( 'email' ) ),
            mobile: ( state.mobile = localStorage.getItem( 'mobile' ) ),
            skype: ( state.skype = localStorage.getItem( 'skype' ) )
            };
        } );
    };
    updatetodoTextProposals( e ) {
        this.setState( { todoTextProposals: e.target.value } );
    };
    createTodoProposals( e ) {
        e.preventDefault();
        this.setState( {
            todosProposals: [ this.state.todosProposals, this.state.todoTextProposals ],
            todoTextProposals: ""
        } );
    };
    filterTodoProposals( e ) {
        var updateList = this.state.proposals;
        updateList = updateList.filter( item => {
            return item.client.toLowerCase().search(e.target.value.toLowerCase()) !== -1;        
        } );
        this.setState( { todosProposals: updateList } );
        if ( updateList === 0 ) { this.setState( { messageProposals: true } ); }
        else { this.setState( { messageProposals: true } ); }
    };
    renderPostProposals = async () =>{
        var token = this.getCookie( 'token' );
        //console.log("Token Proposals ", token );
        if ( token === null ) { this.props.history.push( '/' ) }        
        let headerProposal ={
            headers: {
                'X-Viastara-Token': token,
            }
        }
        //console.log("Header Token", headerProposal);
        try {
            await axios
              .get(domain + "/api/v1/listProposals", headerProposal)
              .then(res => {
                if(res.status === 200){
                    if (this._isMounted) {
                        this.setState(state => {
                            //console.log("Respuesta proposals -- ",res.data);
                            return { proposals: (state.proposals = res.data), todosProposals: res.data };            
                        });
                    }
                }
                else{
                    this.validateHTTPResponse(res.status);
                }
            });
          } catch (err) {
            console.log(err);
        }
    };
    getProposalnumer = async (prid, client) => {
        localStorage.setItem("currentProposal", prid);
        localStorage.setItem("currentClient", client);
        localStorage.setItem('myArray' , "")
        this.props.history.push( '/Products' )
    }
    JsonInfoProposals = event =>{
        return(
            <ul className="contentdataProposals" id="ulProposal">
                {
                    this.state.todosProposals.map( ( proposals, i ) => (
                        <li key={proposals.prid} data-proposal= {proposals.prid}>
                            <div className="card2" >
                                <div className="additional">
                                    
                                    <img src={ domain + '/img/clients/'+ proposals.logo } alt=""/>
                                    
                                </div>
                                <div className="general">
                                    <p className="titleProposal" title={proposals.client}>Propuesta para {proposals.client}</p>
                                    <p>{proposals.created}</p>
                                    <p>Creada {/*proposals.status*/}</p>
                                    <div className="divBtnsProposal">    
                                        <button type="button" className="btnProposalsO">Orden Compra</button>
                                        <button type="button" className="btnProposalsV" onClick={() => this.getProposalnumer(proposals.prid , proposals.client)}>Ver Propuesta</button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                }
            </ul>
        );
    };
    validateHTTPResponse = (statusResponse) => {
        //console.log("validateHTTPResponse ", statusResponse);
        switch (statusResponse) {
            case 200:
              return "Ok";
            case 401:
              // mandar a login y borrar cookies pertinentes / local storage
              if ( localStorage.getItem( 'rememberMe' ) === "true" ) {
                // Mantener Correo y Password
                localStorage.removeItem( 'uid' );
                localStorage.removeItem( 'user' );
                localStorage.removeItem( 'phone' );
                localStorage.removeItem( 'mobile' );
                localStorage.removeItem( 'skype' );
                localStorage.removeItem( 'level' );
                localStorage.removeItem( "currentProposal" );
                localStorage.removeItem( "currentClient" );
              }
              else{
                // Eliminar todos
                localStorage.clear();
              }
              this.logout();
              break;
            case 403:
              // Forbidden - El usuario no tiene permiso a usar cierto API y aún así tuvo la audacia de intentarlo
              console.log("Error 403 - El usario no tiene permiso para consumir la API");
              this.props.history.push( '/404' );
              break;
            case 422:
              // Unprocessable Entity - El usuario mandó datos improcesables
              console.log("Error 422 - El usuario mando datos improcesables");
              this.props.history.push( '/404' );
              break;
            case 500:
              console.log("Error 500 - Error de servidor");
              this.props.history.push( '/404' );
              break;  
            default:
              break;
        }        
    }
    render(){
        return( 
            <React.Fragment>
                <Header currentPage=""/>
                <NavUL powers=""/>     
                    <div className="divContentProposal">
                        <div className="divTitleProposals">
                            <h1 className="h1TitleProposals">Historial Propuestas</h1>
                        </div>

                        <div className="divSearchProposal">
                            <input type="text" placeholder="Buscar" className="filtro productsf" id="filterProposals" onChange={this.filterTodoProposals}></input>                                
                        </div>

                        { this.JsonInfoProposals() }
                    </div>
            </React.Fragment>
        )
    };
}

export default dataProposals;

