import React, { Component } from "react";
import axios from "axios";

const domain = "https://cato.skleo.com";
const options = [];
class Yuju extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            errors: [],
            options : []
        }
    }

    testSubmit(){
        let sserrors = this.props.testValidarDatosYuju();
        this.setState({
            errors : sserrors
        });
        //console.log(sserrors);
    }
    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
      };
      cookieCheck = async () => {
        var token = this.getCookie( 'token' );
        if ( token == null ) { this.props.history.push( '/' ) }
      };
    getCategories= async () => {
        let getCat = domain + "/api/v1/yuju/getCategories"
        var token = this.getCookie( 'token' );
        //console.log("Token Proposals ", token );
        if ( token === null ) { this.props.history.push( '/' ) }   
        let headerProposal ={
          headers: {
            'X-Viastara-Token': token,
          }
        }
    
        try {
          await axios
            .get(getCat, headerProposal)
            .then(res => { 
                //console.log("Classes: " ,  res["data"]["product"])
                for( let i = 0; i < res["data"]["product"].length ; i++ ) {
                    this.state.options.push({
                       pk: res["data"]["product"][i].pk,
                       name: res["data"]["product"][i].name
                    })
                    
                }
            })
        }
        catch{

        }
        console.log(this.state.options)
    }
    Options(){
        return (
            <React.Fragment>
              { this.state.options.map( todo =>  
                <option value={todo.pk}>{todo.name}</option>
              )}
              </React.Fragment>
        )
    }


    render(){
        // const errors = this.props.testValidarDatosYuju();
        // console.log("Errors ",errors);

        return(
            <div className="">

                <h1 style={{textAlign:"center"}}>Yuju</h1><div><br/></div>

                <div className="formExt">
                
                    <div className="filtroData4">

                        <h1 style={{textAlign:"center"}}>REQUERIDO</h1>

                        <label htmlFor="">UPC</label>
                        {
                            //onChange={this.props.handelInputChange} 
                        }
                        <input disabled className="" name="upc" value={this.props.state.upc} placeholder="" type="text" ></input>
            
                        <label htmlFor="">CATEGORĺA</label>
                        <select className={this.state.errors.yujuCategory ? "errorCampos" : ""}  name="yujuCategory" value={this.props.state.yujuCategory} id="" onChange={this.props.handelInputChange} >
                            <option value={"512"}>Electrónica</option>
                            <option value={"533"}>Accesorios</option>
                            <option value={"534"}>TV y Audio</option>
                            <option value={"535"}>Cámaras y accesorios</option>
                            <option value={"536"}>Consolas y Videojuegos</option>
                            <option value={"537"}>Celulares y Tablets</option>
                            <option value={"538"}>Computación</option>
                        </select>

                        <label htmlFor="">SKU SIMPLE</label>
                        <input disabled className={this.state.errors.sku_simple ? "errorCampos" : ""} name="sku_simple" value={this.props.state.sku_simple} placeholder="SKU SIMPLE" type="text" onChange={this.props.handelInputChange} required></input>

                        <label htmlFor="">SKU</label>
                        <input disabled className={this.state.errors.sku ? "errorCampos" : ""} name="sku" value={this.props.state.sku} placeholder="SKU" type="text" onChange={this.props.handelInputChange} ></input>
                        
                        <label htmlFor="">NOMBRE</label>
                        <input disabled className={this.state.errors.name ? "errorCampos" : ""} name="name" value={this.props.state.name} placeholder="NOMBRE" type="text" onChange={this.props.handelInputChange} ></input>

                        <label htmlFor="">DESCRIPCIÓN</label>
                        <textarea disabled className={this.state.errors.extendedDescriptionSpanish ? "errorCampos" : ""} name="extendedDescriptionSpanish" value={this.props.state.extendedDescriptionSpanish}placeholder="DESCRIPCION" type="text" onChange={this.props.handelInputChange} ></textarea>

                        <label htmlFor="">IMÁGENES</label>
                        {/* aqui van a ser dinamico */}
                        <input disabled className={this.state.errors.imagesText ? "errorCampos" : ""} name="imagesText" value={this.props.state.imagesText} placeholder="IMAGEN" type="text" onChange={this.props.handelInputChange} ></input>

                        <label htmlFor="">MARCA</label>
                        <input disabled className={this.state.errors.brand ? "errorCampos" : ""} name="brand" value={this.props.state.brand} placeholder="MARCA"  type="text" onChange={this.props.handelInputChange} ></input>

                        <label htmlFor="">MODELO</label>
                        <input className="" name="model" value={this.props.state.model} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
            
                        <label htmlFor="">STOCK SALABLE MX</label>
                        <input disabled className={this.state.errors.stock_salable_mx ? "errorCampos" : ""} name="stock_salable_mx" value={this.props.state.stock_salable_mx} placeholder="STOCK MX" type="number" min="0" max="50000" step="1" onChange={this.props.handelInputChange}  onKeyPress={this.props.handelInputChangeNumber} ></input>
                    
                        <label htmlFor="">PRECIO</label>
                        <input disabled className={this.state.errors.pvp ? "errorCampos" : ""} name="pvp" value={this.props.state.pvp}  placeholder="PRECIO" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange}  onKeyPress={this.props.handelInputChangeNumber} ></input>
                        {/*<input disabled className={this.state.errors.price ? "errorCampos" : ""} name="price" value={this.props.state.price}  placeholder="PRECIO" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange}  onKeyPress={this.props.handelInputChangeNumber} ></input>*/}
                                                
                        <label htmlFor="">SHIPPING COST</label>
                        <input className="" name="precio_envio" value={this.props.state.precio_envio} placeholder=""  type="number" min="0" max="50000" onChange={this.props.handelInputChange} onKeyPress={this.props.handelInputChangeNumber} ></input>

                        <label htmlFor="">CONDICIÓN</label>
                        <select className="" name="condicion" value={this.props.state.condicion} id="" onChange={this.props.handelInputChange} >
                            <option value="">- Seleccionar opcion -</option>
                            <option value="new">NUEVO</option>
                            <option value="used">USADO</option>
                            <option value="refurbished">REACONDICIONADO</option>
                        </select>

                        <label htmlFor="">GARANTĺA DEL PRODUCTO</label>
                        <input className={this.state.errors.garantia_producto ? "errorCampos" : ""} name="garantia_producto" value={this.props.state.garantia_producto} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
                    
                        <label htmlFor="">GARANTĺA DEL PRODUCTO EN MESES</label>
                        <input className={this.state.errors.warrantyLenth ? "errorCampos" : ""} name="warrantyLenth" value={this.props.state.warrantyLenth} placeholder="" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange} onKeyPress={this.props.handelInputChangeNumber} ></input>
                                            
                        <label htmlFor="">MÉTODO DE ENVĺO</label>
                        <select className={this.state.errors.metodo_envio ? "errorCampos" : ""} name="metodo_envio" value={this.props.state.metodo_envio} id="" onChange={this.props.handelInputChange} >
                            <option value="">- Seleccionar opcion -</option>
                            <option value="Gratis">GRATIS</option>
                            <option value="El marketplace lo calcula">EL MARKETPLACE LO CALCULA</option>
                            <option value="Definido por mi">DEFINIDO POR MI</option>
                            <option value="Acordar con el vendedor">ACORDAR CON EL VENDEDOR</option>
                        </select>

                        <label htmlFor="">UNIDADES DE DIMENSIÓN</label>
                        <select disabled className={this.state.errors.packageDimensionsUnit ? "errorCampos" : ""} name="packageDimensionsUnit" value={this.props.state.packageDimensionsUnit} id="" onChange={this.props.handelInputChange} >
                            <option value="">- Seleccionar opcion -</option>
                            <option value="cm">CM</option>
                            <option value="in">IN</option>
                        </select>

                        <label htmlFor="">ANCHURA DEL PAQUETE DE ENVĺO</label>
                        <input disabled className={this.state.errors.packageWidht ? "errorCampos" : ""} name="packageWidht" value={this.props.state.packageWidht} placeholder="ANCHURA" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange}  onKeyPress={this.props.handelInputChangeNumber} ></input>
                    
                        <label htmlFor="">ALTURA DEL PAQUETE DE ENVĺO</label>
                        <input disabled className={this.state.errors.packageHeight ? "errorCampos" : ""} name="packageHeight" value={this.props.state.packageHeight} placeholder="ALTURA" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange}  onKeyPress={this.props.handelInputChangeNumber} ></input>
                    
                        <label htmlFor="">LARGO DEL PAQUETE DE ENVĺO</label>
                        <input disabled className={this.state.errors.packageLenght ? "errorCampos" : ""} name="packageLenght" value={this.props.state.packageLenght} placeholder="LARGO" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange}  onKeyPress={this.props.handelInputChangeNumber} ></input>

                        <label htmlFor="">UNIDADES DE PESO</label>
                        <select disabled className={this.state.errors.packageWeightUnit ? "errorCampos" : ""} name="packageWeightUnit" value={this.props.state.packageWeightUnit} id="" onChange={this.props.handelInputChange} >
                            <option value="">- Seleccionar opcion -</option>
                            <option value="kg">KG</option>
                            <option value="oz">OZ</option>
                        </select>

                        <label htmlFor="">PESO DEL PAQUETE</label>
                        <input disabled className={this.state.errors.packageWeight ? "errorCampos" : ""} name="packageWeight" value={this.props.state.packageWeight} placeholder="PESO DEL PAQUETE" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange}  onKeyPress={this.props.handelInputChangeNumber} ></input>

                    </div>

                    <div className="filtroData1"> 
                        <h1 style={{textAlign:"center"}}>RECOMENDADO</h1>
                        
                        <label htmlFor="">URL DE LA DESCRIPCIÓN</label>
                        <input className="" name="webLink" value={this.props.state.webLink} placeholder="URL DE LA DESCRIPCION" type="text" onChange={this.props.handelInputChange} ></input>
                    
                        <label htmlFor="">BANNER</label>
                        <input className="" name="banner" value={this.props.state.banner} placeholder="BANNER" type="text" onChange={this.props.handelInputChange}></input>
                    
                        <label htmlFor="">PLANTILLA MERCADO LIBRE</label>
                        <select className="" name="plantilla_mercado_libre" value={this.props.state.plantilla_mercado_libre} id="" onChange={this.props.handelInputChange} >
                            
                            <option value="predeterminado">PREDETERMINADO</option>
                            <option value="Predeterminado nuevo">PREDETERMINDADO NUEVO</option>
                            <option value="No usar plantilla">NO USAR PLANTILLA</option>
                        </select>

                        <label htmlFor="">TIPO DE PUBLICACIÓN (MERCADO LIBRE)</label>
                        <select className="" name="tipo_publicacion_mercado_libre" value={this.props.state.tipo_publicacion_mercado_libre} id="" onChange={this.props.handelInputChange} >
                            <option value="">- Seleccionar opcion -</option>
                            <option value="Clásica">CLASICA</option>
                            <option value="Gratuita">GRATUITA</option>
                            <option value="Oro premium (Meses sin intereses)">ORO PREMIUM</option>
                        </select>

                        <label htmlFor="">GÉNERO</label>
                        <select className="" name="genero" value={this.props.state.genero} id="" onChange={this.props.handelInputChange} >
                            <option value="">- Seleccionar opcion -</option>
                            <option value="Bebés">BEBÉS</option>
                            <option value="Niña">NIÑA</option>
                            <option value="Niño">NIÑO</option>
                            <option value="Masculino">MASCULINO</option>
                            <option value="Femenino">FEMENINO</option>
                            <option value="Unisex">UNISEX</option>
                        </select>

                        <label htmlFor="">PESO DEL PRODUCTO</label>
                        <input className="" name="productWeight" value={this.props.state.productWeight} placeholder="" type="number" min="0" max="50000" step="0.01" onChange={this.props.handelInputChange} onKeyPress={this.props.handelInputChangeNumber} ></input>
                             
                        <label htmlFor="">MPN</label>
                        <input className="" name="mpn" value={this.props.state.mpn} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
            
                        <label htmlFor="">PRODUCTO VIRTUAL</label>
                        <select className="" name="producto_virtual" value={this.props.state.producto_virtual} id="" onChange={this.props.handelInputChange} >
                            <option value="">- Seleccionar opcion -</option>
                            <option value={1}>SI</option>
                            <option value={0}>NO</option>
                        </select>

                        <label htmlFor="">CONTENIDO DEL PAQUETE</label>
                        <input className="" name="packageContent" value={this.props.state.packageContent} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
            
                        <label htmlFor="">EAN</label>
                        <input disabled className="" name="ean" value={this.props.state.ean} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>

                        <label htmlFor="">ASIN</label>
                        <input className="" name="asin" value={this.props.state.asin} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
                                         
                        <label htmlFor="">GTIN</label>
                        <input className="" name="gtin" value={this.props.state.gtin} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
            
                        <label htmlFor="">JAN</label>
                        <input className="" name="jan" value={this.props.state.jan} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
                                            
                        <label htmlFor="">TIENDA OFICIAL MERCADOLIBRE</label>
                        <input className="" name="tienda_oficial_mercado_libre" value={this.props.state.tienda_oficial_mercado_libre} placeholder="" type="number" min="0" max="50000" step="1" onChange={this.props.handelInputChange} onKeyPress={this.props.handelInputChangeNumber} ></input>
            
                        <label htmlFor="">GARANTE</label>
                        <input className="" name="garante" value={this.props.state.garante} placeholder="" type="text" onChange={this.props.handelInputChange} ></input>
            
                        <label htmlFor="">CONTENIDO NETO</label>
                        <input className="" name="contenido_neto" value={this.props.state.contenido_neto} placeholder="" type="number" min="0" max="50000" step="1" onChange={this.props.handelInputChange} onKeyPress={this.props.handelInputChangeNumber} ></input>
            
                    </div>
                    
                </div>

                <div><br/></div>
                <button className="btnForm" onClick={ () => this.testSubmit()}>Enviar Información</button>
                <div><br/></div>
            
                <div className ="divButtonForm">
                    <button className="btnForm btnAfter" onClick={this.props.handlePageChangeBefore}> Anterior Pestaña</button> 
                    {/* <button className="btnForm btnNext" onClick={this.props.handlePageChange}> Siguente Pestaña</button>*/}
                </div>

            </div>
        )
    };
}

export default Yuju;