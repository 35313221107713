import React, { Component } from "react";
import { Route } from 'react-router-dom';
import axios from "axios";
import NavUL from "../navbarUL";
import Header from "../Header";
import UPCSearch from './Components/UPCBuscador'
import { Checkbox } from "material-ui";
import {Redirect} from 'react-router-dom';
import { CommunicationMessage } from "material-ui/svg-icons";
import list from "material-ui/svg-icons/action/list";
import wrapText from "material-ui/svg-icons/editor/wrap-text";

import inactiveStatus from '../../img/Status_Gris.svg';
import activeStatus from '../../img/Status_Verde.svg';
import notListedStatus from '../../img/Status_Amarillo.svg';

const domain = "https://cato.skleo.com";

class viewProductsYuju extends Component {

    constructor(props) {
        super(props);
        this.state = {      
            upc: "", 
            productsYuju : [],
            allcheck : false,
            UPCList: [],
            currentStorePK : 0 ,
            currentMarketPK : 0 ,
            stores : [],
            marketplaces : [],
            upcToMarket:[],
            product: [],
            ExtendedInfo : [],
            stockChange : [],
            ListaMarketPlace : [],
            serverResponce : '',
            checkStatus : [],

            marketplacesList : [],
            yujuProducts: []
        }
    }

    getStore= async() =>{
       let GetStores = "https://cato.skleo.com/api/v1/yuju/getStore"       
       await fetch(GetStores )
				.then(response => response.text())
				.then(result => {
                    let res = JSON.parse(result)
                    this.setState({
                        stores : res
                    })
					//console.log(res)
				})
				.catch(error => console.log('error', error));	
    }

    getInfoProducto =async () =>{
        let getInfoProductoAPI = "https://cato.skleo.com/api/v1/yuju/getInfoProduct"
        await fetch(getInfoProductoAPI )
        .then(response => response.text())
        .then(result => {
            let res = JSON.parse(result)
            this.setState({
                productsYuju : res
            })
            console.log(res)
        })
        .catch(error => console.log('error', error));	
    }

    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
    };

    fillData= async (info , infoExtenden) =>{        
        let ProductExist = false;
        for ( let i = 0; i < this.state.ExtendedInfo.length; i++ ){
            //console.log("Products UPC: ", this.state.ExtendedInfo[i].upc , infoExtenden.upc)
            if (this.state.ExtendedInfo[i].upc === infoExtenden.upc){
                ProductExist = true;
            }
        }
        if (ProductExist === false){
                await this.setState({
                    product : [...this.state.product, info] ,
                    ExtendedInfo : [...this.state.ExtendedInfo, infoExtenden] 
                })
               // console.log(infoExtenden.upc)
        }
        

    }

    handelInputChange = async (event) =>{
        event.persist();        
        let namevar = event.target.name;
        let valuevar = event.target.value;
        await this.setState( {
          [namevar] : (namevar = valuevar)
        });
    };

    producto= () =>{
        //console.log(this.state.product)
        try{
        	return(
                <React.Fragment>
                    { this.state.product[0].map((producto, index) =>{
                        return(
                            <p key={index}>{producto.description}</p>
                            )
                    })}
                </React.Fragment>
            )
        }
        catch{
            return(<div>Error</div>)
        }
    }

    handleChangeAllCheck = async (e) =>{
        if (e.target.checked){
            //console.log("add")
            await this.setState({
                allcheck : true
            })
        }
        else if (e.target.checked === false)
        {
				await this.setState({
					
					allcheck : false					
				});            
        }
        //console.log(this.state.upcToMarket)
    }

    handleChange = async (e,upc) =>{
        //console.log(e.target.checked, upc)
        if (e.target.checked){
            //console.log("add")
            await this.setState({
                upcToMarket : [upc , ...this.state.upcToMarket]
            })
        }
        else if (e.target.checked === false)
        {
            const copiedItems = [...this.state.upcToMarket];
            const arr = copiedItems.filter(e => e !== upc);
            //console.log("Delete")
				await this.setState({					
					upcToMarket: arr					
				});            
        }
        //console.log(this.state.upcToMarket)
    }

    // --------------------------------------------------------------------------------------------------
    clearMarketplaces(json){
        let lista = this.state.marketplacesList;
        for (let index = 0; index < json.length; index++) {
            const store = json[index];
            for (let i = 0; i < store.marketplaces.length; i++) {
                const marketplace = store.marketplaces[i];
                let bool = lista.filter(data => (data.name === marketplace.name));
                if (bool.length === 0){
                    lista.push(marketplace);
                }
            }
        }
        this.setState({
            marketplacesList: lista
        });
    }

    getMarketPlaces = async() => {
        let GetStores = "https://cato.skleo.com/api/v1/yuju/getStore"
        await axios(GetStores )
            .then(response => {
                this.clearMarketplaces(response.data)
            })
            .catch(error => console.log('error', error));
    }

    getInfoYuju = async() => {        
        // let GetStores = "https://cato.skleo.com/api/v1/yuju/getInfoProduct"  
        let GetStores = "https://cato.skleo.com/api/v1/yuju/getInfoProduct_v002" 
        await fetch(GetStores )
            .then(response => response.text())
            .then(result => {
                if(!result.includes('"detail": "Not found"')){
                    let res = JSON.parse(result);
                    this.setState({
                        yujuProducts: res
                    });
                    console.log(this.state.yujuProducts);
                }  
                else{
                    this.setState({
                        yujuProducts: []
                    });
                }              
            })
            .catch(error => console.log('error', error));	
        return null;
    }

    tableHeaderRender(){
        let headers = ["ID","UPC","IMAGE","DESCRIPTION","STOCK MX","STOCK YUJU"]
        let marketplaces = this.state.marketplacesList;
        for (let index = 0; index < marketplaces.length; index++) {
            headers.push(marketplaces[index].name);
        }
        return headers.map((header, index) => {
            return <th key={index}>{header.toUpperCase()}</th>
        })
    }

    componentDidMount(){
        this.getMarketPlaces();
        this.getInfoYuju();
        let intervalID = setInterval(() => {this.getInfoYuju()}, 5000);
        // clearInterval(intervalID);
    }

    descriptionLess= (description) => {
        return description.length > 80 ? `${description.substr( 0, 80 )}...` : description;
    }
    // cambio
    renderTableData= () => {
        return <tbody>
                <tr>{this.tableHeaderRender()}</tr>
                {this.state.yujuProducts.map((product, index) => {
                    let imgProduct;
                    if(product.images.length >1){
imgProduct = product.images[0].url;
                    }
else{
    imgProduct=""
}

                    return (
                        <tr key={index + 1}>
                            <td >{index + 1 }</td>
                            <td>{product.upc}</td>
                            <td><div><img src={imgProduct} height='45px' className='imageTable' ></img></div></td>
                            <td>{this.descriptionLess(product.name)}</td>
                            <td>{product.mx_salable}</td>
                            <td>{product.yj_stock}</td>
                            {this.state.marketplacesList.map((marketplace, i) =>{
                                for (let j = 0; j < product.marketplaces.length; j++) {
                                    if( product.marketplaces[j].pk === marketplace.pk){
                                        switch (product.marketplaces[j].status) {
                                            case "active":
                                                return (<td key={i + 1}><div><img src={activeStatus} height='20px' className='imageTable' ></img></div></td>)
                                            case "inactive":
                                                return (<td key={i + 1}><div><img src={inactiveStatus} height='20px' className='imageTable' ></img></div></td>)
                                            case "not_listed":
                                                return (<td key={i + 1}><div><img src={notListedStatus} height='20px' className='imageTable' ></img></div></td>)
                                            default:
                                                return (<td key={i + 1}><div><img src={inactiveStatus} height='20px' className='imageTable' ></img></div></td>)
                                        }
                                    }
                                }
                                return (<td key={i + 1}><div><img src={inactiveStatus} height='20px' className='imageTable' ></img></div></td>)
                            })}
                        </tr>
                    )
                })}
        </tbody>     
    }

    render(){
        return(<React.Fragment>
            <Header renderProposal = {() => this.renderShoppinCartList()}/>
            <NavUL powers={ localStorage.getItem('level') }  newProposal = {() => this.newProposal()} site = "shooping" state = { this.state } />
            <div className="containerFormulario">
                {/* <UPCSearch getCookie={this.getCookie} domain={domain} handelInputChange={this.handelInputChange} currentUPC={this.state.upc} fillData={this.fillData} /> */}
                <div style={{display: "flex", alignItems: "center"}}><img src={inactiveStatus} height='20px'/><label style={{marginRight:"10px"}}>Inactivo</label><img src={activeStatus} height='20px'/><label style={{marginRight:"10px"}}>Active</label><img src={notListedStatus} height='20px'/><label style={{marginRight:"10px"}}>Not Listed</label ></div>
                <div className="DivH">
                    <div>
                    {/* <div><img src={notListedStatus} height='20px'/><label >Not Listed</label><img src={activeStatus} height='20px'/><label>Active</label><img src={WarningStatus} height='20px'/><label>???</label><img src={ErrorStatus} height='20px'/><label>???</label></div> */}
                        <h1 id='title'>Products Yuju</h1>
                        <table id='students'>
                            {this.renderTableData()}
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>);
    } 
}
export default viewProductsYuju;		