import React, { Component } from "react";
import axios from "axios";

const domain = "https://cato.skleo.com"
class DeleteImage extends Component {

    constructor(props) {
		super(props);
		
		this.state = {
            currentImagesUPC : [],
            imagesToDelete: [],
            currentUPC : ''
        }
    }
    
    
    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
      };
      
    deleteFunct= async (image) =>{
        const r = window.confirm('Are you sure you wish to delete this item?');
        if (r === true){
            
            let Borrar = image.split('/').pop()
            console.log("Imagen Eliminada Name - " + Borrar)
            let deleteImagesAPI = domain + "/api/v1/product/deleteImage?upc=" + this.state.currentUPC
            var token = this.getCookie( 'token' );
            let headerProposal ={
                headers: {
                    'X-Viastara-Token': token,
                }
            }
            await this.setState({
                imagesToDelete : { file: Borrar }
            })
            // console.log(this.state.imagesToDelete)
            var JsonDeleteImage =  JSON.stringify(this.state.imagesToDelete)
            console.log (JsonDeleteImage, deleteImagesAPI)
            try {
                await axios
                            .post(deleteImagesAPI, JsonDeleteImage, headerProposal)
                            .then(res => {            
                                console.log("result ", res);
                            });
              } catch (errorPost) {
                console.log(errorPost);
              }
              
        }   
        this.ChangeUPC(this.state.currentUPC)     
    }

    ChangeUPC= async(val) =>{
        
        var token = this.getCookie( 'token' );
            let headerProposal ={
                headers: {
                    'X-Viastara-Token': token,
                }
            }
        let UrlGetExtendedInfo = domain + "/api/v1/product/getImages?upc=" + val;
        try{
            await axios
              .get(UrlGetExtendedInfo, headerProposal)
              .then(res => {
                console.log(res);
                this.setState({
                    
                currentImagesUPC:  res["data"]["images"],
                currentUPC: val
                })
            })
        }
        catch{}
        console.log(this.state.currentUPC)
        this.props.updateImagesByUPC(this.state.currentImagesUPC)
    }
    comparar(a,b){
        console.log(a.ranking, b.ranking)
        return a.ranking - b.ranking
    }
    imagesMap(){
        console.log(this.props.state.imagesByUPC.sort(this.comparar))
        if (this.state.currentUPC !== ''){
            return(
                <React.Fragment>
                {this.props.state.imagesByUPC.map( (imageName, index) =>
                    <div key={'div'+index} className='deleteImageCard'>
                        
                        <img src={imageName.img} key = {index}  height="100" width="100"></img>
                        <a href={imageName.img} key= {'image'+index} target="_blank">{imageName.img.split('/').pop()}</a>
                        <p>{"Ranking: " + imageName.ranking}</p>
                        <button className="btnForm" onClick={() => this.deleteFunct(imageName.img)}>Delete</button>
                    </div>    
                    )}
                </React.Fragment>
            )
        }

    }
    handleComboBoxXhange = (event) =>{
        event.persist();
        const val = event.target.value;
        this.ChangeUPC(val)
      }


    render(){
        return(
            <div className='SelectUPCIMG'> 
            <h1 style={{marginBottom:"10px", textAlign:"center"}}>VISUALIZAR IMAGENES</h1>
            <label htmlFor="" style={{marginRight:"10px"}}>UPC VARIATIONS</label> 
            <select id="UPCVariation" name="UPCVariation" onChange={this.handleComboBoxXhange} >
                <option value="">-Seleccionar UPC-</option>
                {this.props.state.UPCList.map(x => <option key = {x} value = {x}>{x}</option>) }
            </select>
            <div className="contDeleteImage">
               
                {this.imagesMap()}
            </div>
        </div>
            
        )
    }
}
export default DeleteImage;