import React, { Component } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import axios from "axios";
import logo from "./Viastara.svg";
import user from '../svgs/solid/user.svg'
import lock from '../svgs/solid/lock.svg'


class login extends Component {
    
    state = {
        Usuario : 'andres@boxelinc.com', 
        Password: 'viastara2020',
        Usuario_tem: '',
        Password_temp: '',
        check : false,
    };
    componentDidMount() { this.checkRemembermeAuto() };
    checkRememberme= async () =>{
        if ( localStorage.getItem( 'rememberMe' ) === "true" ) {
            localStorage.setItem( 'rememberMe', false );
        }
        else { localStorage.setItem( 'rememberMe', true ); }

        let b
        if ( localStorage.getItem( 'rememberMe') === "true" ) { b = true; }
        else { b = false }
        await this.setState( state => {
            return { check: ( state.check = b ) };
        } );
    };
    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
      };
    cookieCheck = async () => {
        var token = this.getCookie( 'token' );
        //console.log( token )
        if ( token !== null ) { this.props.history.push( '/Products' ) }
      };
    checkRemembermeAuto = async () =>{
        this.cookieCheck();
        
        let b 
        if ( localStorage.getItem('rememberMe' ) === "true" ) {
            b = true;
            await this.setState(state => {
                return {
                 check: ( state.check = b ),
                 Usuario_tem: ( state.Usuario_tem = localStorage.getItem( 'email' ) ),
                 Password_temp: (state.Password_temp = localStorage.getItem( 'password' ) ) 
                };
            } );
        }
        else {
            b = false
            await this.setState( state => {
                return { check: ( state.check = b ) };
            } );
        }
    };
    _handleKeyDown = ( e ) => {
        if ( e.key === 'Enter' ) { this.tryLogin(); }
    };
    tryLogin = async () => {

        this.state.Usuario_tem=this.state.Usuario_tem.toLowerCase()
        let res= null;
        //console.log( this.state.Usuario_tem );
        try {
            res = await axios.post( "https://cato.skleo.com/api/v1/auth", '{"user": "' + this.state.Usuario_tem + '","pass": "' + this.state.Password_temp + '"}');
        } catch (error) {
            alert("Password or User Incorrect");
            return;
        }
        
        //console.log( res )
        
        if ( res.data.success === true ) {           
            localStorage.setItem( 'email', this.state.Usuario_tem )
            localStorage.setItem( 'uid', res.data.uid)
            localStorage.setItem( 'password', this.state.Password_temp )
            localStorage.setItem( 'user', res.data.name + " " + res.data.lastname )
            localStorage.setItem( 'phone', res.data.phone )
            localStorage.setItem( 'mobile', res.data.mobile )
            localStorage.setItem( 'skype', res.data.skype )
            localStorage.setItem( 'level', res.data.level )
            localStorage.setItem( 'myArray', "")
            localStorage.setItem( "currentProposal", 0 );
            localStorage.setItem( "currentClient", "" );
            localStorage.setItem('myArrayExtendedInfo', ""); 

            //console.log(res.data)
            document.cookie = 'token=' + res.data.token + '; Max-Age=86400; path=/';
            this.props.history.push( '/Products' )
        }
        else{
            console.log("Password or User Incorrect");
            alert("Password or User Incorrect");
        }
    };
    updateUser = async event => { await this.setState( { Usuario_tem : event.target.value } ) };
    updatePassword = async event => { await this.setState( { Password_temp : event.target.value } ) };

    render() {
      return (
        <div className= "login">
            <div className = "divLogin">
                <img alt="" className="imgFormLogin" src={logo}/>
                <p className="LoginH1">VLC / Sistema General</p>
                {/* <p className="LoginH2">VENTAS</p> */}
                <form className="formLogin">
                    {/* <p className='userInfo' >Email</p> */}
                    <div className="divContentInputs" >
                        <img src={user} height="20" alt=""/>
                        <input onKeyDown={ this._handleKeyDown } type="text" name="" autoComplete="username email" onChange={ this.updateUser } value= {this.state.Usuario_tem} placeholder="Enter Email"/>
                    </div>
                    {/* <p className='userInfo' >Password</p> */}
                    <div className="divContentInputs">
                        <img src={lock} height="20" alt=""/>
                        <input onKeyDown={ this._handleKeyDown } type="password" name="" onChange={ this.updatePassword } value={ this.state.Password_temp } placeholder="Enter Password"/>
                    </div>
                    <Checkbox style ={ { color: "#E71332", } } checked={ this.state.check } onClick={ () => this.checkRememberme() }  value="primary" inputProps={{ 'aria-label': 'primary checkbox' }}/> <label className= "LoginH1" htmlFor="rememberMe">Remember me</label>
                    {/* <br/> */}
                </form>
                {/* <br/> */}
                <button className="btnFormLogin" onKeyDown={ this._handleKeyDown } onClick={ event => this.tryLogin() }>Submit</button>
            </div>
        </div>
      );
    }
  }
 
export default login;