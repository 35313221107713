import React, { Component } from "react";
import axios from "axios";
import Csv_Reader from "../../CsvReader.jsx"


class UPC_Search_SecondChannel extends Component {

    constructor(props) {
        super(props);
        this.state = {
          UPCList: [],
          product: [],
          CSV:[]
          
        }
    }

    getInfoUPC= async (e) =>{
        const val = e;
        if (val) {
            this.getExtended(val)
      }
    }

    getInfo= async (val, headerProposal) =>{
        
    }
    // SecondChannel
    getExtended = async (val, headerProposal) =>{
        let info = [];
        let infoData = false;
        let urlGetInfo = "https://cato.skleo.com/api/v1/product/secondChannel?upc=" + val;
        try {
            await axios.get(urlGetInfo, headerProposal).then(res => {
                console.log("Consulta:",res)
                if(res["data"] && Object.keys(res["data"]).length > 0 ){
                    info = res["data"];
                }
                else{
                    infoData = true;
                    this.props.fillUpcError("No existe: " + val)
                }
            });
        } catch (errorPost) {
            console.log(errorPost);
            infoData = true;
        }
        if (infoData === false){
            this.props.fillData( info )           
        } 
    }

    getExtendedTest= async (val, headerProposal) =>{
      let info = []
      let infoExtended = []
      let StockData = [];
      let infoData = false;
      let urlGetInfo = this.props.domain + "/api/v1/product/get?upc=" + val;
        try {
            await axios
              .get(urlGetInfo, headerProposal)
              .then( res => {  
                
                if (res['data']['product'] ){
                info = res["data"]["product"] } 
                else{infoData = true;
                this.props.fillUpcError("No existe: " + val)
                }
              });
              
          } catch (errorPost) {
            console.log(errorPost);
            infoData = true;
          }
          if (infoData === false){
            this.props.fillData( info , infoExtended, StockData ) 
          } 

        let UrlGetExtendedInfo = this.props.domain + "/api/v1/product/getExtended?upc=" + val;
        try {
            await axios
              .get(UrlGetExtendedInfo, headerProposal)
              .then(res => { 
                
                if (res['data']['product']){
                infoExtended = res["data"]["product"] }
                else{ infoData = true; } 
              });            
                
          } catch (errorPost) {
            console.log(errorPost);
            infoData = true;
          }
          
          let productsFinal= ({
            products : [{"upc": val}]
            })
            let json = JSON.stringify(productsFinal)
          let UrlGetFulFillment = "https://cato.skleo.com/api/v1/yuju/getFulfillment_stock_V002";
          try {
            await axios
              .post(UrlGetFulFillment, json)
              .then(res => {
                  StockData = res["data"]["result"]
              });            
                
          } catch (errorPost) {
            console.log(errorPost);
          }
          if (infoData === false){
            this.props.fillData( info , infoExtended, StockData ) 
          } 
    }

    getYUJU= async (e) =>{

    }

    updateTextarea = async event => { await this.setState( { TextareaUPC : event.target.value } ); localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));};

    removeTag = (i) => {
        const newUPCList = [ ...this.state.UPCList ];
        newUPCList.splice(i, 1);
        this.setState({ UPCList: newUPCList });
    }

    inputKeyDown = async (e) => {
      const val = e.target.value;
      if (e.key === 'Enter' && val) {

        if (this.state.UPCList.find(tag => tag.toLowerCase() === val.toLowerCase())) {
          return;
        }
        let cleenSpaces = val.replace(/\n|\r/g, "");
        
        console.log(cleenSpaces)
        let splitval = cleenSpaces.split(",");
        
        for ( let i = 0; i < splitval.length; i++ ) {
          if (this.state.UPCList.find(tag => tag.toLowerCase() === splitval[i].toLowerCase())) {
            console.log("Existe")
          }
          else{
            await this.setState({ UPCList: [...this.state.UPCList, splitval[i] ] });
          }
          
        }
        
        this.tagInput.value = null;
        for (let i = 0; i < this.state.UPCList.length; i++){
          console.log(this.state.UPCList[i])
          await this.getInfoUPC(this.state.UPCList[i])
        }
      } 
      
      
      else if (e.key === 'Backspace' && !val) {
        this.removeTag(this.state.UPCList.length - 1);
      }

    }

    CSV_Info = async(data) =>{
      await this.setState({
        CSV : data,
        UPCList:data
      })
      console.log(data);
      for (let i = 0; i < this.state.UPCList.length; i++){
        await this.getInfoUPC(this.state.UPCList[i])
      }
    }

    render(){
      const { UPCList } = this.state;
      return(
        <React.Fragment>
              <div >
                <label htmlFor="">Search UPC</label>
                <div className = "">
                  <div className ="" style={{marginTop: "10px", marginBottom:"10px"} } onChange ={this.updateTextarea} >
                  <div className="input-tag">
                    <ul className="input-tag__tags">
                      { UPCList.map((tag, i) => (
                        <li >
                          {tag}
                          <button type="button" onClick={() => { this.removeTag(i); }}>+</button>
                        </li>
                      ))}
                      <li className="input-tag__tags__input"><input type="text" onKeyDown={this.inputKeyDown} ref={c => { this.tagInput = c; }} /></li>
                    </ul>
                  </div>
                </div>
                </div>
                <Csv_Reader CSV_Info ={ this.CSV_Info } fillUpcError={this.props.fillUpcError}/>
                <div className="ButtonDiv" style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }} ><button style={{width:"200px", margin:"10px", textAlign: 'center', alignItems: "center", backgroundColor: "#E71332", color:"#FFFFFF", padding:"8px", borderRadius:"10px", border:"0px"}} onClick={() => window.location.reload()}>Eliminar todo</button>
        </div>
                </div> 
        </React.Fragment> );
    }
 
}
export default UPC_Search_SecondChannel;
		