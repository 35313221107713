import React, { Component } from "react";

class vistaPrevia extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render(){
        return(
            <div className="">
                <h1 style={{textAlign:"center"}}>Product Preview</h1><div><br/></div>

                <div id="divGenealInfoP">
                    <h2 id="tituloGeneralInfo" style={{textAlign:"center"}}>Genenal Info</h2>
                    <div id="divImgPreview">

                    </div>
                    <div id="divInfoPreview" className="divVistaPrevia">
                        <label htmlFor="">Description (Spanish):</label>
                        <input type="text" name="previewDescSpanish" id="" placeholder="previewDescSpanish" />

                        <label htmlFor="">Extended Description:</label>
                        <textarea name="previewExtDescription" id="" cols="30" rows="10" placeholder="previewExtDescription" ></textarea>

                        <label htmlFor="">Extended Description (Spanish):</label>
                        <textarea name="previewExtDescSpanish" id="" cols="30" rows="10" placeholder="previewExtDescSpanish" ></textarea>

                        <label htmlFor="">Features:</label>        
                        <textarea name="previewFeatures" id="" cols="30" rows="10" placeholder="wFeat" ></textarea>

                        <label htmlFor="">Keywords:</label>
                        <input type="text" name="previewKeywords" id="" placeholder="previewKeywords" />

                        <label htmlFor="">Item Number:</label>
                        <input type="text" name="previewItemNumber" id="" placeholder="previewItemNumber" />

                        <label htmlFor="">UPC:</label>
                        <input type="text" name="previewUPC" id="" placeholder="previewUPC" />

                        <label htmlFor="">Brand:</label>
                        <input type="text" name="previewBrand" id="" placeholder="previewBrand" />

                        <label htmlFor="">Color:</label>
                        <input type="text" name="previewColor" id="" placeholder="previewColor" />

                        <label htmlFor="">Color (Brand):</label>
                        <input type="text" name="previewColorBrand" id="" placeholder="previewColorBrand" />

                        <label htmlFor="">Web Link:</label>
                        <input type="text" name="previewWebLink" id="" placeholder="previewWebLink" />

                        <label htmlFor="">Video Link:</label>
                        <input type="text" name="previewVideoLink" id="" placeholder="previewVideoLink" />

                        <label htmlFor="">Compatibility:</label>
                        <input type="text" name="previewCompatibility" id="" placeholder="previewCompatibility" />

                        <label htmlFor="">Variation Type:</label>
                        <input type="text" name="previewVariationType" id="" placeholder="previewVariationType" />

                        <label htmlFor="">Company</label>
                        <textarea name="previewCompany" id="" cols="30" rows="10" placeholder="previewCompany"></textarea>

                        <label htmlFor="">Replenishment Type:</label>
                        <input type="text" name="previewReplenishmentType" id="" placeholder="previewReplenishmentType" />

                        <label htmlFor="">Country of Origin:</label>
                        <input type="text" name="previewContryOrigin" id="" placeholder="previewContryOrigin" />

                        <label htmlFor="">Shipping Origin:</label>
                        <input type="text" name="previewShippingOrigin" id="" placeholder="previewShippingOrigin" />

                        <label htmlFor="">Harmonized Code:</label>
                        <input type="text" name="previewHarmonizedCode" id="" placeholder="previewHarminizedCode" />

                        <label htmlFor="">Schedule B Code:</label>
                        <input type="text" name="previewScheduleBCode" id="" placeholder="previewScheduleBCode" />
                    </div>
                </div>

                <div id="divPricingP">
                    <h2 style={{textAlign:"center"}}>Pricing</h2>
                    <div id="divCost">
                        <label htmlFor="">Viastara Cost (MEX):</label>
                        <input type="text" name="previewViastaraCostMex" id="" placeholder="previewViastaraCostMex"/>

                        <label htmlFor="">Viastara Cost (LATAM):</label>
                        <input type="text" name="previewViastaraCostLATAM" id="" placeholder="previewViastaraCostLATAM"/>

                        <label htmlFor="">Viastara Cost (San Diego):</label>
                        <input type="text" name="previewViastaraCostSD" id="" placeholder="previewViastaraCostSD"/>

                        <label htmlFor="">Viastara Landed Cost (Mexico):</label>
                        <input type="text" name="previewViastaraLandedCostMex" id="" placeholder="previewViastaraLandedCostMx"/>

                        <label htmlFor="">PVP:</label>
                        <input type="text" name="previewPVP" id="" placeholder="previewPVP"/>

                        <label htmlFor="">MSRP:</label>
                        <input type="text" name="previewMSRP" id="" placeholder="previewMSRP"/>

                        <label htmlFor="">FOB LATAM:</label>
                        <input type="text" name="previewSFOBLATAM" id="" placeholder="previewSFOBLATAM"/>
                    </div>
                    <div id="divMarginCost">
                        <label htmlFor="">Maximum Client Margin (LATAM):</label>
                        <input type="text" name="previewMaxClientMarginLATAM" id="" placeholder="previewMarginLATAM"/>

                        <label htmlFor="">Maximum Client Margin (Mexico):</label>
                        <input type="text" name="previewMaxClientMarginMex" id="" placeholder="previewMaxClientMarginMex"/>

                        <label htmlFor="">Viastara Margin (LATAM):</label>
                        <input type="text" name="previewViastaraMarginLATAM" id="" placeholder="previewViastaraMarginLATAM"/>

                        <label htmlFor="">Viastara Margin (Mexico):</label>
                        <input type="text" name="previewViastaraMarginMex" id="" placeholder="previewViastaraMarginMex"/>                     
                    </div>
                </div>

                <div id="divSpecificationsP">
                    <h2 style={{textAlign:"center"}}>Specifications</h2>
                    <div id="divQuantitys">
                        <label htmlFor="">Master Pack Quantity:</label>
                        <input type="text"  name="previewMasterPackQuantity" id="" placeholder="previewMasterPackQuantity"/>

                        <label htmlFor="">Inner Pack Quantity:</label>
                        <input type="text"  name="previewInnerPackQuantity" id="" placeholder="previewInnerPackQuantity"/>

                        <label htmlFor="">Minimum Order Quantity:</label>
                        <input type="text"  name="previewMinOrderQuantity" id="" placeholder="previewMinOrderQuantity"/>
                    </div>
                    <div id="divProductSpecifications">
                        <label htmlFor="">Product Length:</label>
                        <input type="text"  name="previewProductLength" id="" placeholder="previewProductLength"/>

                        <label htmlFor="">Product Width:</label>
                        <input type="text"  name="previewProductWidth" id="" placeholder="previewProductWidth"/>

                        <label htmlFor="">Product Height:</label>
                        <input type="text"  name="previewProductHeight" id="" placeholder="previewProductHeight"/>

                        <label htmlFor="">Product Weight:</label>
                        <input type="text"  name="previewProductWeight" id="" placeholder="previewProductWeight"/>
                    </div>
                    <div id="divPackageSpecifications">
                        <label htmlFor="">Package Length:</label>
                        <input type="text"  name="previewPackageLength" id="" placeholder="previewPackageLength"/>

                        <label htmlFor="">Package Width:</label>
                        <input type="text"  name="previewPackageWidth" id="" placeholder="previewPackageWidth"/>

                        <label htmlFor="">Package Height:</label>
                        <input type="text"  name="previewPackageHeight" id="" placeholder="previewPackageHeight"/>

                        <label htmlFor="">Package ProductWeight:</label>
                        <input type="text"  name="previewPackageProductWeight" id="" placeholder="previewPackageProductWeight"/>
                    </div>
                    <div id='divOtherSpecifications'>
                        <label htmlFor="">Package Content:</label>
                        <input type="text"  name="previewPackageContent" id="" placeholder="previewPackageContent"/>

                        <label htmlFor="">Material Composition:</label>
                        <input type="text"  name="previewMaterialComposition" id="" placeholder="previewMaterialComposition"/>

                        <label htmlFor="">Power Specs:</label>
                        <input type="text"  name="previewPowerSpecs" id="" placeholder="previewPowerSpecs"/>

                        <label htmlFor="">Lithium Batery:</label>
                        <input type="text"  name="previewLithiumBatery" id="" placeholder="previewLithiumBatery"/>

                        <label htmlFor="">Wireless Product:</label>
                        <input type="text"  name="previewWirelessProduct" id="" placeholder="previewWirelessProduct"/>

                        <label htmlFor="">Life Time Warranty:</label>
                        <input type="text"  name="previewLifeTimeWarranty" id="" placeholder="previewLifeTimeWarranty"/>
                    </div>
                </div>
            </div>
        )
    };
}

export default vistaPrevia;