import React, { Component } from "react";
import axios from "axios";
import NavUL from "./navbarUL";
import Header from "./Header";
// import PDFmakeFile from "./PDFmake"; // Dexter says: Unused Component
// import logo from "./Viastara.svg"; // Dexter says: Unused Component

const domain = "https://cato.skleo.com";

class Items extends Component{
    constructor(props) {
        super(props);
        this.state = {
            Usuario : '', 
            Telefono: '',
            email : '',
            celular : '',
            skype : '',
            shoppingcart_List: [],
            products: [],
            brand: "Fitbit",
            marcas: [],
            clientes: [],
            cliente: "",
            clienteV: "",
        };
    }  
    componentDidMount() {
        this.cookieCheck();
        this.renderPostsbrands();
    };
    getCookie = function( name ) {
      var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
      return r ? r[ 1 ] : null;
    };
    cookieCheck = async () => {
      var token = this.getCookie( 'token' );
      //console.log( token )        
      const  userBool = localStorage.getItem('user')
      const  phoneBool = localStorage.getItem('phone')
      const  emailBool = localStorage.getItem('email')
      const  mobileBool = localStorage.getItem('mobile')
      const  skypeBool = localStorage.getItem('skype')
      const myarrayBool = localStorage.getItem('myArray')
      if(emailBool === null || userBool === null || phoneBool === null ||mobileBool === null || skypeBool === null || myarrayBool === null){
        this.logout();
      }     
      if ( token == null ) { this.props.history.push( '/' ) }
      await this.setState( state => {
        return { userName: ( state.userName = localStorage.getItem( 'user' ) ),
          phone: ( state.phone = localStorage.getItem( 'phone' ) ),
          email: ( state.email = localStorage.getItem( 'email' ) ),
          mobile: ( state.mobile = localStorage.getItem( 'mobile' ) ),
          skype: ( state.skype = localStorage.getItem( 'skype' ) )
          };
      } );
    };
    logout(){
      var cookies = document.cookie.split( ";" );
      for ( let i = 0; i < cookies.length; i++ ) {
        var cookie = cookies[ i ];
        var eqPos = cookie.indexOf( "=" );
        var name = eqPos > -1 ? cookie.substr( 0, eqPos ) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    };
    validateHTTPResponse = (statusResponse) => {
      //console.log("validateHTTPResponse ", statusResponse);
      switch (statusResponse) {
        case 200:
          return "Ok";
        case 401:
          // mandar a login y borrar cookies pertinentes / local storage
          if ( localStorage.getItem( 'rememberMe' ) === "true" ) {
            // Mantener Correo y Password
            localStorage.removeItem( 'uid' );
            localStorage.removeItem( 'user' );
            localStorage.removeItem( 'phone' );
            localStorage.removeItem( 'mobile' );
            localStorage.removeItem( 'skype' );
            localStorage.removeItem( 'level' );
            localStorage.removeItem( "currentProposal" );
            localStorage.removeItem( "currentClient" );
          }
          else{
            // Eliminar todos
            localStorage.clear();
          }
          this.logout();
          break;
        case 403:
          // Forbidden - El usuario no tiene permiso a usar cierto API y aún así tuvo la audacia de intentarlo
          console.log("Error 403 - El usario no tiene permiso para consumir la API");
          this.props.history.push( '/404' );
          break;
        case 422:
          // Unprocessable Entity - El usuario mandó datos improcesables
          console.log("Error 422 - El usuario mando datos improcesables");
          this.props.history.push( '/404' );
          break;
        case 500:
          console.log("Error 500 - Error de servidor");
          this.props.history.push( '/404' );
          break;  
        default:
          break;
      }         
    }
    renderPostsbrands = async () => {
      var token = this.getCookie( 'token' );
      //console.log("Token Proposals ", token );
      if ( token === null ) { this.props.history.push( '/' ) }        
      
      /*
      // Comentado por Warning
      let headerProposal ={
        headers: {
          'X-Viastara-Token': token,
        }
      }*/
      try {
        await axios.get(domain + "/api/v1/getBrands").then(res => {
          //console.log(res.data.length);
          if(res.status === 200){
            let newBrands = [];
            for (let a = 0; a < res.data.length; a++) {
              newBrands.push([res.data[a].name, res.data[a].logo]);
              //console.log(res.data[a].name);
            }
            this.setState(state => {
              return { marcas: (state.marcas = newBrands) };
            });
          }
          else{
            this.validateHTTPResponse(res.status);
          }
        });
      } catch (err) {
        console.log(err);
      }
    };
    JsoninfoBrands = event => {
      return (
        <ul className="list-group" id="myListBrands" >
          { this.state.marcas.map( todo => (
            <li className="brandList" key={ todo }>
              {/*
              // Comentado por Warning
              <a href="#" onClick={ () => this.changeBrand( { todo } ) } >
                <img  alt="Brands" height="100" src={ domain + "/img/brands/" + todo[ 1 ] } />
          </a>*/}

              <label onClick={ () => this.changeBrand( { todo } ) } >
                <img  alt="Brands" height="100" src={ domain + "/img/brands/" + todo[ 1 ] } />
              </label>

            </li>
          ) ) }
        </ul>
      );
    };
    render(){
      return( 
        <React.Fragment>
            <Header currentPage=""/>
            <NavUL
            powers="Admin"
            />            
            <div className="container">
              {/* <aside className="sidebar" id = "marcas">
                  {this.JsoninfoBrands()}
              </aside> */}
            </div>
        </React.Fragment>
      )
    };
  }
  //ReactDOM.render(<Items />, document.getElementById('root'));
export default Items;