import React, { Component } from "react";
import { Route } from 'react-router-dom';
import axios from "axios";
import NavUL from "../navbarUL";
import Header from "../Header";
import UPCSearch from '../SendToMarketplace/Components/UPCBuscador'
import { Checkbox } from "material-ui";
import {Redirect} from 'react-router-dom';


const domain = "https://cato.skleo.com"

class specialDiscounts extends Component {

    constructor(props) {
        super(props);
        this.state = {      
            upc: "999999999999", 
            UPCList: [],
            product: [],
            ExtendedInfo : [],
        }
    }

    componentDidMount(){
    }

    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
    };

    cookieCheck = async () => {
        var token = this.getCookie( 'token' );
        if ( token == null ) { return  <Redirect  to="/" /> }
    };

    fillData = async (info , infoExtenden) =>{
        
        let ProductExist = false;
        for ( let i = 0; i < this.state.ExtendedInfo.length; i++ ){
            if (this.state.ExtendedInfo[i].upc == infoExtenden.upc){
                ProductExist = true;
            }
        }
        if (ProductExist === false){
            await this.setState({
                product : [...this.state.product, info] ,
                ExtendedInfo : [...this.state.ExtendedInfo, infoExtenden] 
            })
            // console.log(infoExtenden.upc)
        }
    }

    handelInputChange = async (event) =>{
        event.persist();        
        let namevar = event.target.name;
        let valuevar = event.target.value;
        await this.setState( {
        [namevar] : (namevar = valuevar)
        });
    };

    producto = () =>{
        //console.log(this.state.product)
        try{
        	return(
                <React.Fragment>
                    { this.state.product[0].map((producto, index) =>{
                        return(<p key={index}>{producto.description}</p>);
                    })}
                </React.Fragment>
            );
        }
        catch{
            return(<div>Error</div>)
        }
    }
    
  render(){
    
    return(
      <React.Fragment>
        <Header renderProposal = {() => this.renderShoppinCartList()} />
            <NavUL powers={ localStorage.getItem('level') }  newProposal = {() => this.newProposal()} site = "shooping" state = { this.state } />
            
            <div className="containerFormulario">
                <UPCSearch getCookie={this.getCookie} domain={domain} handelInputChange={this.handelInputChange} currentUPC={this.state.upc} fillData={this.fillData} />
                
                <div className = "" >
                    <h1 style={{marginBottom:'20px'}} id='title'>Descuentos especiales</h1>

                    <div className="filtroData4">
                        <label htmlFor="">Fecha Inicio</label>
                        <input placeholder="" type="text" id="" name="" className="" />

                        <label htmlFor="">Fecha Termino</label>
                        <input placeholder="" type="text" id="" name="" className="" />

                        <label htmlFor="">Descuento en porcentaje</label>
                        <input placeholder="" type="number" min="1" max="50000" step="0.01" id="" name="" className="" />

                        <button type="button">Aplicar descuento</button>
                    </div>
                </div>
 
              
            </div>              
      </React.Fragment> );
  }
 
}
export default specialDiscounts;
		