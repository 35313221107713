import React, { Component } from "react";
import axios from "axios";
import NavUL from "./navbarUL";
import Header from "./Header";
import Select from 'react-select';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const options = [
];
const options2 = [
];

const domain = "https://cato.skleo.com";
const proposaldata = "/api/v1/getProposal?prid=";

class itemsProducts extends Component{
  _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
          userName : '', 
          phone: '',
          email : '',
          mobile : '',
          skype : '',
          shoppingcart_List: [],
          products: [],
          brand: "",
          marcas: [],
          clientes: [],
          cliente: "",
          clienteV: "",
          selectedOption: null,
          selectedBrand: null,
          currentBrand: "",

          todosProducts: [],
          todoTextProducts:"",
          messageProducts: false,
        };

        this.updatetodoTextProducts = this.updatetodoTextProducts.bind(this);
        this.createTodoProducts = this.createTodoProducts.bind(this);
        this.filterTodoProducts = this.filterTodoProducts.bind(this);
    }

    componentDidMount() {
      this._isMounted = true;
      this.cookieCheck();         
      this.renderProposal();
      this.renderPostsbrands();
      this.getClientes();
    };
    // filtrador Products
    updatetodoTextProducts(e) {
      this.setState({
        todoTextProducts: e.target.value
      });
    };
    createTodoProducts(e) {
      e.preventDefault();
      this.setState({
        todosProducts: [this.state.todosProducts, this.state.todoTextProducts],
        todoTextProducts: ""
      });
    };
    filterTodoProducts=(e)=> {
      var updatedList = this.state.products;
      
      updatedList = updatedList.filter(item => {
        if(isNaN(e.target.value.toLowerCase())){
          return item.productName.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        }
        else{
          return item.upc.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        }        
      });
      this.setState({
        todosProducts: updatedList
      });
      if (updatedList === 0) {
        this.setState({
          messageProducts: true
        });
      } else {
        this.setState({
          messageProducts: false
        });
      }
    };
    changeOptions = selected => {
      this.setState(
        { selectedOption : options[selected]},
        () => this.selectCliente( this.state.selectedOption.value , this.state.selectedOption.label.props.children[1].props.children[1])
      );
    }
    handleChange = selectedOption => {
      //console.log(selectedOption)
      this.setState(
        { selectedOption },
        () => this.selectCliente( this.state.selectedOption.value , this.state.selectedOption.label.props.children[1].props.children[1])
      );
    };
    handelChangeBrand = selectedBrand => {
      this.setState(
        { selectedBrand },
        () => this.changeBrand2( this.state.selectedBrand.value , this.state.selectedBrand.label.props.children[0].props.src)
      );
    };
    selectCliente = async (e, v) =>{
      await this.setState({ cliente: v });
      await this.setState({ clienteV: e });
    };
    changeBrand2 = async (b, image) => {
      
      await this.setState(state => {
        
        return { brand: (state.brand = b) , currentBrand: (state.currentBrand = image)};
      });
      this.renderPosts();
    };
    renderProposal = async() => {    
      var array = localStorage.getItem('myArray');
      if (localStorage.getItem('myArray') !== "" ){
        array = JSON.parse(array);  
        this.setState( { shoppingcart_List: array });
        this.renderShoppinCartList()
      }
      else{
        var token = this.getCookie( 'token' );
        let currentProposal;
        let headerProposal ={
        headers: {
          'X-Viastara-Token': token,
        }
        }
        currentProposal = domain + proposaldata + localStorage.getItem("currentProposal")
        //let joined // is defined but never used 
        try {
          await axios
            .get(currentProposal, headerProposal)
            .then(res => {
              //console.log("Respuesta productos ", res.data, headerProposal);
              if(res.status === 200){
                //console.log("Status 200 renderProposal");
                if (this._isMounted) {
                  this.setState(state => {
                    return { products: (state.products = res.data), todosProducts: res.data  };
                  });
                }
              }
              else{
                this.validateHTTPResponse(res.status);
              }
            });
          } catch (err) {
          console.log(err);
        }
        this.state.products.forEach(element => {
        this.buttonShoppingProposal(element.upc)
        });
      }     
    }
    renderShoppinCartList = async() =>{
      var array = localStorage.getItem('myArray');
      array = JSON.parse(array);
      let UPCProducts=[];
      for(var i = 0 ; i < array.length-1; i++){
        UPCProducts.push( array[i] );
      }
      UPCProducts.push( array[array.length -1] );         
      let payload;
      payload = "{" +
      "\"upcs\": " +
        localStorage.getItem('myArray') +      
      "}";
      //console.log("renderShoppinCartList payload ", payload);
      var token = this.getCookie( 'token' );
      //console.log("Token Proposals ", token );
      //let currentProposal; // is defined but never used 
      let headerProposal ={
        headers: {
          'X-Viastara-Token': token,
        }
      }
      //console.log("Peticion data ", domain + "/api/v1/getProducts", payload, headerProposal );
      try {
        await axios
          .post(domain + "/api/v1/getProducts", payload, headerProposal)
          .then(res => {    
            if(res.status === 200){
              //console.log("Status 200 getProducts");
              if (this._isMounted) {
                this.setState(state => {
                  //console.log("res.data getProducts ",res.data);
                  return { products: (state.products = res.data.products), todosProducts: res.data.products };
                });
              }
            }
            else{
              this.validateHTTPResponse(res.status);
            }
          });
      } catch (err) {
        console.log(err);
      } 
    }
    renderPosts = async () => {
      var token = this.getCookie( 'token' );
        //console.log("Token Proposals ", token );
      let currentProposal;
      let headerProposal ={
        headers: {
          'X-Viastara-Token': token,
        }
      }      
        currentProposal = domain + "/api/v1/productList?brand=" + this.state.brand
        try {
          await axios
            .get(currentProposal, headerProposal)
            .then(res => {
              if(res.status === 200){
                //console.log("Status 200 productListBrand");
                if (this._isMounted) {
                  this.setState(state => {
                    //console.log(res.data)
                    return { products: (state.products = res.data.products), todosProducts: res.data.products };                
                  });
                }
              }
              else{
                this.validateHTTPResponse(res.status);
              }
            });
        } catch (err) {
          console.log(err);
        }
    };
    getClientes = async () =>{
        try {         
          await axios
            .get(domain + "/api/v1/getClients")
            .then(res => {
              if(res.status === 200){
                if (this._isMounted) {
                  this.setState(state => {               
                    //console.log(res)
                    console.log("Status 200 getClients");
                    return { clientes: (state.clientes = res.data) };                              
                  });
                }   
              }
              else{
                this.validateHTTPResponse(res.status);
              }
            });
        } catch (err) {
          console.log(err);
        }
        //console.log(this.state.clientes);
        this.clientesUp();
    };
    clientesUp = () =>{
      let array = this.state.clientes
      array.forEach( element => {
        options.push( {
         value: element.cid,
         label: <div className="filter-Image-Text"><img alt="" src={ domain + '/img/clients/'+ element.logo } height="50px" /><p className="filter-name" id= {element.name}> { element.sName } </p></div> },)
      });
      this.SelectClientProposal()
    };
    SelectClientProposal= () =>{
      var contador = 0
      options.forEach(element => {
        
        if (element.label.props.children[1].props.id === localStorage.getItem("currentClient")){
          this.changeOptions(contador)
          return;
        }
        contador = contador+1;
      });
    }
    brandsUp = () => {
      let array = this.state.marcas
      array.forEach( element => {
        options2.push( {
         value: element[ 0 ],
         label: <div className="filter-Image-Text"><img alt="" src={ domain + '/img/brands/'+ element[ 1 ] } height="50px" /><p className="filter-name"> { element[ 0 ] } </p></div> },)
      });
    };
    renderPostsbrands = async () => {
        try {
          await axios.get( domain + "/api/v1/getBrands" ).then( res => {
            if(res.status === 200){
              console.log("Status 200 renderProposal");
              if ( this._isMounted ) {
                let newBrands = [];
                for ( let a = 0; a < res.data.length; a++ ) {
                  newBrands.push([res.data[a].name, res.data[ a ].logo ] );
                }
                this.setState(state => {
                  return { marcas: ( state.marcas = newBrands ) };
                } );
              }
            }
            else{
              this.validateHTTPResponse(res.status);
            }  
          });
        } catch ( err ) {
          console.log( err );
        }
        this.brandsUp();
    };
    changeBrand = async ( e, image ) => {
      let b = e.todo[ 0 ];
      await this.setState( state => {
        return { brand: ( state.brand = b ) , currentBrand: ( state.currentBrand = image ) };
      } );
      this.renderPosts();
    };
    JsoninfoBrands = event => {
        return (
          <ul className="brand-ul" id="myListBrands" >
            { this.state.marcas.map( todo => (
              <li className="brandList" key={ todo }>

                {/*
                // Comentado por Warning
                <a href= "#" onClick={ () => this.changeBrand( { todo } , domain + "/img/brands/" + todo[ 1 ] ) }>
                  <img  alt="Brands" height="100" src={ domain + "/img/brands/" + todo[ 1 ] } />
            </a>*/}

<label onClick={ () => this.changeBrand( { todo } , domain + "/img/brands/" + todo[ 1 ] ) }>
                  <img  alt="Brands" height="100" src={ domain + "/img/brands/" + todo[ 1 ] } />
                </label>

              </li>
            ) ) }
          </ul>
        );
    };
    Jsoninfo = event => {

        return (
          <ul className="cartas" id="myUL" >
            { this.state.todosProducts.map( ( Products, i ) => (
              <li   key={ Products.upc } data-upc={ Products.upc }>
                <div className="card" >
                  <a href="/#"> <LazyLoadImage alt="" effect="blur" src={ domain + "/img/products/" + Products.image }/></a>
                  <div className="card-body" >
                    <h4 className="card-title">
                      <a className="product-name" data-toggle="tooltip" data-placement="top" title={ Products.productName } href="/#" >{this.truncText(Products.productName, 20)}</a>
                    </h4>
                    
                    <p className="card-text">
                      { this.truncText( Products.descriptionEx, 150 ) }
                    </p>
                    <h5 className="card-title" >${ Products.pvp }</h5>
                  </div>
                  <div>
                    <hr className="style-two"></hr>
                    <p className="card-text">
                      Stock: { Products.stock }
                    </p>
                    
                  </div>
                  
                  <div className="card-button" onClick={ ( event ) => this.buttonShopping( event, Products.upc ) } >
                  { this.addRemoveBtn( Products.upc ) }
                  </div>
                </div>
              </li>
            ))}
          </ul>
        );
    };
    addRemoveBtn = ( upc ) => {
        if ( this.state.shoppingcart_List.includes( upc ) ) { return "Remove"; }
        return "Add";
    };
    buttonShoppingProposal = async (  upc ) => {
      if (this.state.shoppingcart_List.indexOf( upc ) > -1 ) {
        return;
      }
      let joined = this.state.shoppingcart_List.concat( upc );
      await this.setState( { shoppingcart_List: joined } );

      localStorage.setItem('myArray', JSON.stringify(this.state.shoppingcart_List));        
      //var array = localStorage.getItem('myArray'); // is defined but never used 
      //array = JSON.parse(array);    
    };
    buttonShopping = async ( event, upc ) => {
        if (this.state.shoppingcart_List.indexOf( upc ) > -1 ) {
          event.target.innerHTML = "Add";
          event.target.classList.remove( "-selected-item" );
          var array = this.state.shoppingcart_List;
          var index = array.indexOf( upc );
          if ( index > -1 ) {
            array.splice( index, 1 );
            await this.setState( { shoppingcart_List: array } );
          }

          //console.log("shoppincartList ", this.state.shoppingcart_List);
          localStorage.setItem('myArray', JSON.stringify(this.state.shoppingcart_List));        
          //var array = localStorage.getItem('myArray'); //is defined but never used 
          //array = JSON.parse(array);
          //console.log("myArray ", array);
          return;
        }
        event.target.innerHTML = "Remove";
        event.target.classList.add( "-selected-item" );
        let joined = this.state.shoppingcart_List.concat( upc );
        await this.setState( { shoppingcart_List: joined } );
        //console.log("shoppincartList ", this.state.shoppingcart_List);
        localStorage.setItem('myArray', JSON.stringify(this.state.shoppingcart_List));        
        //var array = localStorage.getItem('myArray'); //is defined but never used 
        //array = JSON.parse(array);
        //console.log("myArray ", array);
    };
    newProposal(){
      localStorage.setItem("currentProposal", 0);
      localStorage.setItem("currentClient", "");
      localStorage.setItem('myArray', "");
         
    }
    truncText( text, num ) {
      try{
        return text.length > 20 ? `${text.substr( 0, num )}...` : text;
      }
      catch (err) {
        console.log(err);
      }
    };
    getCookie = function( name ) {
      var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
      return r ? r[ 1 ] : null;
    };
    cookieCheck = async () => {
      var token = this.getCookie( 'token' );
      //console.log( token )
      const  userBool = localStorage.getItem('user')
      const  phoneBool = localStorage.getItem('phone')
      const  emailBool = localStorage.getItem('email')
      const  mobileBool = localStorage.getItem('mobile')
      const  skypeBool = localStorage.getItem('skype')
      const myarrayBool = localStorage.getItem('myArray')
      if(emailBool === null || userBool === null || phoneBool === null ||mobileBool === null || skypeBool === null || myarrayBool === null){
        this.logout();
      }
      if ( token == null ) { this.props.history.push( '/' ) }
      await this.setState( state => {
        return { userName: ( state.userName = localStorage.getItem( 'user' ) ),
          phone: ( state.phone = localStorage.getItem( 'phone' ) ),
          email: ( state.email = localStorage.getItem( 'email' ) ),
          mobile: ( state.mobile = localStorage.getItem( 'mobile' ) ),
          skype: ( state.skype = localStorage.getItem( 'skype' ) )
          };
      } );
    };
    logout(){
      var cookies = document.cookie.split( ";" );
      for ( let i = 0; i < cookies.length; i++ ) {
          var cookie = cookies[ i ];
          var eqPos = cookie.indexOf( "=" );
          var name = eqPos > -1 ? cookie.substr( 0, eqPos ) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";          
      }
    };
    componentWillUnmount() {
      localStorage.setItem("currentProposal", 0);
      localStorage.setItem("currentClient", "");
      this._isMounted = false;
    }
    validateHTTPResponse = (statusResponse) => {
      //console.log("validateHTTPResponse ", statusResponse);
      switch (statusResponse) {
        case 200:
          return "Ok";
        case 401:
          // mandar a login y borrar cookies pertinentes / local storage
          if ( localStorage.getItem( 'rememberMe' ) === "true" ) {
            // Mantener Correo y Password
            localStorage.removeItem( 'uid' );
            localStorage.removeItem( 'user' );
            localStorage.removeItem( 'phone' );
            localStorage.removeItem( 'mobile' );
            localStorage.removeItem( 'skype' );
            localStorage.removeItem( 'level' );
            localStorage.removeItem( "currentProposal" );
            localStorage.removeItem( "currentClient" );
          }
          else{
            // Eliminar todos
            localStorage.clear();
          }
          this.logout();
          break;
        case 403:
          // Forbidden - El usuario no tiene permiso a usar cierto API y aún así tuvo la audacia de intentarlo
          console.log("Error 403 - El usario no tiene permiso para consumir la API");
          this.props.history.push( '/404' );
          break;
        case 422:
          // Unprocessable Entity - El usuario mandó datos improcesables
          console.log("Error 422 - El usuario mando datos improcesables");
          this.props.history.push( '/404' );
          break;
        case 500:
          console.log("Error 500 - Error de servidor");
          this.props.history.push( '/404' );
          break;  
        default:
          break;
      }      
    }
    render(){
      const { selectedOption } = this.state;
      const { selectedBrand } = this.state;
        return( 
            <React.Fragment>
                <Header currentPage="Shooping" renderProposal = {() => this.renderShoppinCartList()} countSHP={ this.state.shoppingcart_List.length }/>
                <NavUL powers={ localStorage.getItem('level') }  newProposal = {() => this.newProposal()} site = "shooping" state = { this.state } />
                <div className="container">
                  <div className="brand"><img alt="" src={ this.state.currentBrand } /></div>
                  <div className="client" >
                    <h3>Cliente:</h3>
                    <Select value={ selectedOption } onChange={ this.handleChange } options={ options } />
                    <h3>Marca:</h3>
                    <Select value={ selectedBrand } onChange={ this.handelChangeBrand } options={ options2 } />
                    <h3>Producto:</h3>
                  </div>
                    <div className="filter-div2">
                      <input className="filtro productsf" id="filterProducts" type="text" placeholder="Filter here…" onChange={ this.filterTodoProducts }></input>
                    </div>
                    <main className="contenido" >
                        { this.Jsoninfo() }
                    </main>
                </div>
            </React.Fragment>
        )
    };
}
export default itemsProducts;