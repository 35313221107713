import React, { Component } from "react";

const { v4: uuidv4 } = require('uuid');

class DropZoneImages extends Component {

    constructor(props) {
      super(props);
      this.state = {
      files : [],
      updateFiles:[]

    }
  }
  
  componentDidMount = async () => {
    await this.setState({files:this.props.files})
  }
  
 
	handleDrags(e) {
		e.preventDefault();
		e.stopPropagation();
	}
	
	handleDragEnter(e) {
		this.handleDrags(e);
	}
	
	handleDragOver(e) {
		this.handleDrags(e);
	}
	
	handleDragLeave(e) {
		e.preventDefault();
		e.stopPropagation();
	}
	
	onChange(e) {
		e.preventDefault()
		const files = e.target.files;
		[].forEach.call(files, this.handleFiles.bind(this));
	}
	
	handleDrop (e){
		e.stopPropagation();
		e.preventDefault();		
		const data = e.dataTransfer;
		const files = data.files;	
		for( let i = 0; i < files.length ; i++ ) {
			this.handleFiles( files[i], files.length, i+1 );
    }

   
  }
	
	handleFiles = async (file, length, currentL) => {
    
    let extencions = ["pdf"]
    var re = /(?:\.([^.]+))?$/;
    let ext = re.exec(file["name"])[1].toLowerCase();
    if(extencions.includes(ext)){
      var reader = new FileReader();
      reader.onloadend = async() => {
        
        this.setState({files:this.props.files})
        await this.setState({
          files:[...this.state.files, {id:uuidv4(), file:file, 
            status:"Wait" , 
            lastname:"",
            name:"" ,
            orderNumber: "",
            state: "",
            zip: "",
            address1: "",
            address2: "",
            address3: "",
            total : "",
            erpOrder : "",
            items:[]
          }]
        });
        console.log(this.state.files);
        this.props.update(this.state.files);
      }
      reader.readAsDataURL(file); 
    }		
  }

  
  removeFile = (file) => () => {
    let index = this.props.Pictures.findIndex(x => x.id === file.id);
    let deletefile = this.props.Pictures.splice(index, 1)
    this.props.updatePictures(this.props.Pictures);
    this.props.updatedelete(deletefile)
  }

  handleOnDragEnd = async (result) =>{
    if(!result.destination) return;
    const Items = Array.from(this.props.Pictures);
    const [reorderedItem] = Items.splice(result.source.index, 1);
    Items.splice(result.destination.index, 0, reorderedItem)
    await this.setState({updateFiles:Items})
    this.props.updatePictures(this.state.updateFiles);
  }
  mapFiles = async () => {
    return(
      
        <div>Hola</div>
    )
  }
  
  render(){    
    
    return(
      <React.Fragment> 
          <input type="file" name="pdf" style={{display:"none"}} multiple/>
          <div name="IM" className="dropzone dropzoneBlock files_top backgroundUpload" onDrop={this.handleDrop.bind(this)} onDragOver={this.handleDragOver.bind(this)} onDragEnter={this.handleDragEnter.bind(this)} onDragLeave={this.handleDragLeave.bind(this)} />             

      </React.Fragment>
    )
  }
}
export default DropZoneImages;