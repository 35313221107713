import React, { Component } from "react";
import axios from "axios";
import TestMultiImages from '../testUdateMultiImages';
import DeleteImages from './imageDelete';
import DragAndDrop from './DragAndDrop'

let valor = 0;
class ImageChoice extends Component {

    constructor(props) {
		super(props);
		
		this.state = {
            renderPage : 1
        }
    }

    button1= async()=>{
        
        await this.setState({
            renderPage : 1
        })
         
    }
    button2= async()=>{
        await this.setState({
            renderPage : 2
        })
    }

    changeRender(){
        switch(this.state.renderPage){
        case 1:
                return(
                < DragAndDrop changeVariationUPC={this.props.changeVariationUPC} updateImgVariationsLS={this.props.updateImgVariationsLS}  updateImgVariations={this.props.updateImgVariations} handelInputChangeNumber={this.props.handelInputChangeNumber} handelInputChange={this.props.handelInputChange} buttonFillData={this.props.buttonFillData} comboboxFill= { this.props.comboboxFill } addFeaures={this.props.addFeaures} handlePageChange={this.props.handlePageChange} handlePageChangeBefore ={this.props.handlePageChangeBefore} state ={this.props.state} saveVariation={this.props.saveVariation} comboboxUPCVariations ={this.props.comboboxUPCVariations}/>
                )
        case 2:
                return(
                    <DeleteImages state ={this.props.state} updateImagesByUPC={this.props.updateImagesByUPC}/>
                )
        default:
            return <div>Seleccionar una opcion</div>
        }
    }

    render(){
        return(
            <div className="BotonesImagenesDiv">
                {this.changeRender()}
            </div>
        )
    }
}

export default ImageChoice;