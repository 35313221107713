import React, { Component } from "react";
import axios from "axios";
import NavUL from "./navbarUL";
import Header from "./Header";

const domain = "https://cato.skleo.com";
const auxURlUpdate = "/api/v1/user/";

class dataUser extends Component{

    constructor( props ) {
        super( props );
        this.state = {
            userName : '', 
            phone: '',
            email : '',
            mobile : '',
            skype : '',
            shoppingcart_List: [],
            products: [],
            brand: "Fitbit",
            marcas: [],
            clientes: [],
            cliente: "",
            clienteV: "",
        };
    }
    componentDidMount() {
        this.cookieCheck();
        this.render();
    };
    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
    };
    cookieCheck = async () => {
        var token = this.getCookie( 'token' );
        //console.log( token )
        const  userBool = localStorage.getItem('user')
        const  phoneBool = localStorage.getItem('phone')
        const  emailBool = localStorage.getItem('email')
        const  mobileBool = localStorage.getItem('mobile')
        const  skypeBool = localStorage.getItem('skype')
        const myarrayBool = localStorage.getItem('myArray')
        if(emailBool === null || userBool === null || phoneBool === null ||mobileBool === null || skypeBool === null || myarrayBool === null){
            this.logout();
        }       
        if ( token == null ) { this.props.history.push( '/' ) }
        await this.setState( state => {
          return { userName: ( state.userName = localStorage.getItem( 'user' ) ),
            phone: ( state.phone = localStorage.getItem( 'phone' ) ),
            email: ( state.email = localStorage.getItem( 'email' ) ),
            mobile: ( state.mobile = localStorage.getItem( 'mobile' ) ),
            skype: ( state.skype = localStorage.getItem( 'skype' ) )
            };
        } );
    };
    logout(){
    var cookies = document.cookie.split( ";" );
    for ( let i = 0; i < cookies.length; i++ ) {
        var cookie = cookies[ i ];
        var eqPos = cookie.indexOf( "=" );
        var name = eqPos > -1 ? cookie.substr( 0, eqPos ) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
    };
    updateValues = async ()=>{
        var token = this.getCookie( 'token' );
        let headerUpdate ={
        headers: {
            'X-Viastara-Token': token,
            }
        }
        let dataBody;      
        let urlUpdate;
        var newSkype = document.getElementById("inputSkype").value;
        var oldSkype = localStorage.getItem("skype");       
        var newPhone = document.getElementById("inputPhone").value;
        var oldPhone = localStorage.getItem('phone');
        var newMobile = document.getElementById("inputMobile").value;
        var oldMobile = localStorage.getItem('mobile');
        //var regExValor = /[0-9\+]/;     
        var regExValor = /[0-9]/;      
        /*console.log("New skype " + newSkype + " Old skype " + oldSkype  + " New phone " + newPhone + " Old phone " + oldPhone + " New mobile " + newMobile + " Old mmobile " + oldMobile);*/
        if(newSkype !== oldSkype && newSkype.length <= 32){
            urlUpdate = domain + auxURlUpdate + "updateSkype";          
            dataBody =  "{\"skype\": \"" + newSkype + "\"}"
            console.log("SKYPE " , urlUpdate , " " , dataBody , " " , headerUpdate);
            try {await axios
                .post(urlUpdate, dataBody, headerUpdate)
                .then(res => {            
                    console.log("Skype result ", res);
                    if(res.status === 200){
                        localStorage.setItem( 'skype', newSkype );
                    }
                    else{
                        this.validateHTTPResponse(res.status);
                    } 
                });
            } 
            catch (err) {
                console.log(err);
            }
        }
        if(newPhone !== oldPhone && newPhone.length <= 14 && regExValor.test(newPhone)){
            urlUpdate = domain + auxURlUpdate + "updatePhone";
            dataBody =  "{\"phone\": \"" + newPhone + "\"}"
            console.log("PHONE " , urlUpdate + " " , dataBody , " " , headerUpdate);
            try {
                await axios
                .post(urlUpdate, dataBody, headerUpdate)
                .then(res => {            
                    console.log("Phone result ", res);
                    if(res.status === 200){
                        localStorage.setItem( 'phone', newPhone );
                    }
                    else{
                        this.validateHTTPResponse(res.status);
                    } 
                });
            } 
            catch (err) {
                console.log(err);
            }
        }
        if(newMobile !== oldMobile && newMobile.length <=14 && regExValor.test(newMobile)){
            urlUpdate = domain + auxURlUpdate + "updateMobile";          
            dataBody =  "{\"mobile\": \"" + newMobile + "\"}"
            console.log("MOBILE " , urlUpdate + " " , dataBody , " " , headerUpdate);
            try {
                await axios
                .post(urlUpdate, dataBody, headerUpdate)
                .then(res => {            
                    console.log("Mobile result " + res);
                    if(res.status === 200){
                        localStorage.setItem( 'mobile', newMobile );
                    }
                    else{
                        this.validateHTTPResponse(res.status);
                    } 
                });
            } 
            catch (err) {
                console.log(err);
            }
        }        
    }
    restarValues = async () =>{
        document.getElementById("inputSkype").value = localStorage.getItem("skype");
        document.getElementById("inputPhone").value = localStorage.getItem('phone');
        document.getElementById("inputMobile").value = localStorage.getItem('mobile');
    }
    validateHTTPResponse = (statusResponse) => {
        //console.log("validateHTTPResponse ", statusResponse);
        switch (statusResponse) {
            case 200:
              return "Ok";
            case 401:
              // mandar a login y borrar cookies pertinentes / local storage
              if ( localStorage.getItem( 'rememberMe' ) === "true" ) {
                // Mantener Correo y Password
                localStorage.removeItem( 'uid' );
                localStorage.removeItem( 'user' );
                localStorage.removeItem( 'phone' );
                localStorage.removeItem( 'mobile' );
                localStorage.removeItem( 'skype' );
                localStorage.removeItem( 'level' );
                localStorage.removeItem( "currentProposal" );
                localStorage.removeItem( "currentClient" );
              }
              else{
                // Eliminar todos
                localStorage.clear();
              }
              this.logout();
              break;
            case 403:
              // Forbidden - El usuario no tiene permiso a usar cierto API y aún así tuvo la audacia de intentarlo
              console.log("Error 403 - El usario no tiene permiso para consumir la API");
              this.props.history.push( '/404' );
              break;
            case 422:
              // Unprocessable Entity - El usuario mandó datos improcesables
              console.log("Error 422 - El usuario mando datos improcesables");
              this.props.history.push( '/404' );
              break;
            case 500:
              console.log("Error 500 - Error de servidor");
              this.props.history.push( '/404' );
              break;  
            default:
              break;
        }       
    }
    render(){
        return( 
            <React.Fragment>
                <Header currentPage=""/>
                <NavUL powers=""/>                      
                <div className="containerU">
                    <div className="contenidoU">
                        <div className="divContentUser">
                            <div className="divLogoForm">
                                <img className="imgFormEditUser" src="https://2.bp.blogspot.com/-fVF23P85emk/WZolKkmywHI/AAAAAAAATvo/FM-AAWDZgSQqBQjphLlyT_L5bJfGlSGoACLcBGAs/s1600/Logo.Viastara.png" alt=""/>
                            </div>
                            <div className="contentFormUser">
                                <h1 className="h1FormUser">Datos usuario</h1>
                                <form action="/action_page.php" className="formEdit">
                                    {/* Nombre */}
                                    <div className="">
                                        <label>Nombre Usuario</label>
                                    </div>
                                    <div className="">
                                        <input type="text" placeholder="Nombre usuario" className="" defaultValue={this.state.userName} readOnly=""/>
                                    </div>
                                    {/* Telefono */}
                                    <div className="">
                                        <label>Telefono Usuario</label>
                                    </div>
                                    <div className="">
                                        <input id="inputPhone" type="text" placeholder="Telefono usuario" className="" defaultValue={this.state.phone}/>
                                    </div>
                                    {/* Celular */}
                                    <div className="">
                                        <label>Celular Usuario</label>
                                    </div>
                                    <div className="">
                                        <input id="inputMobile" type="text" placeholder="Celular usuario" className="" defaultValue={this.state.mobile}/>
                                    </div>
                                    {/* Email */}
                                    <div className="">
                                        <label>Email Usuario</label>
                                    </div>
                                    <div className="">
                                        <input type="text" placeholder="Email usuario" className="" defaultValue={this.state.email} readOnly=""/>
                                    </div>
                                    {/* Skype */}
                                    <div className="">
                                        <label>Skype Usuario</label>
                                    </div>
                                    <div className="">
                                        <input id="inputSkype" type="text" placeholder="Skype usuario" className="" defaultValue={this.state.skype}/>
                                    </div>
                                    {/* Botton Guardar*/}
                                    <div className="divBtnEditUser">
                                        <button type="button" className="btnFormUser" defaultValue="Submit" onClick={() => this.updateValues()}>Guardar</button>
                                        <button type="button" className="btnFormUser" defaultValue="Submit" onClick={() => this.restarValues()}>Cancelar</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    };
}

export default dataUser;
