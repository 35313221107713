import React, { Component } from "react";
import logo from "./Viastara.svg";
import usersvg from "../svgs/solid/user.svg"
import shoppicon from "../svgs/solid/shopping-cart.svg"

// const domain = "https://cato.skleo.com"; // Dex Says: Unused variable

class NavbarList extends Component {
    logout() {
        var cookies = document.cookie.split( ";" );
  
        for ( let i = 0; i < cookies.length; i++ ) {
            var cookie = cookies[ i ];
            var eqPos = cookie.indexOf( "=" );
            var name = eqPos > -1 ? cookie.substr( 0, eqPos ) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      };

    render() {
        if ( this.props.currentPage === "Shooping" ){
            return( 
                <header className="header">
                    <div className="viastaraLogo"><a href="/Products"><img src={ logo } alt="Viastara"></img></a></div>
                    <div className="Shoppingicon" onClick= {() => this.props.renderProposal()}><img src={ shoppicon } alt="Shop"></img><p>{ this.props.countSHP }</p></div>
                    <div className="usericon " onClick = { () => this.logout() }><a href="/Products"><img src={ usersvg } alt="Products"></img></a> 
                    
                        {/* <div className="dropdown-content dropdown-content2">
                            <h2 className="userInfoDd1">{ localStorage.getItem( 'User' )}</h2>
                            <h2 className="userInfoDd2">Proposals</h2>
                            <button className="userInfoDd3">Logout</button>
                        </div>
                    </div>
                    <div className="dropdown" >
                        */}
                    </div>
                </header>
            )
        }
        else{
            return(
                <header className="header">
                    <div className="viastaraLogo"><a href="/Products"><img src={logo} alt="logo"></img></a></div>
                    <div className="usericon" onClick = { () => this.logout() } ><a href="/Products" ><img src={ usersvg } alt="user"></img></a></div>
                </header>
            )
        }
    };
}

export default NavbarList;