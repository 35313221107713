import React, { Component } from "react";

class ExtendInfoStep1 extends Component {
    render(){
        return(
            <div className="">
                <h1>Descripciones Extendidas</h1>
                <div className="formExt">
                    <div className="filtroData4">

                        <label htmlFor="">NOMBRE PRODUCTO:</label>
                        <input className="description" placeholder="Nombre"  type="text" name="name" onChange={this.props.handelInputChange} value = {this.props.state.name} ></input>
                        

                        <label htmlFor="">LAUNCH DATE</label>
                        <input className="" name="launch_date" value={this.props.state.launch_date} placeholder="yyyy-mm-dd" type="text" onChange={this.props.handelInputChange} ></input>
            
                        <label htmlFor="">COLOR (BRAND)</label>
                        <input name="colorBrand" onChange={this.props.handelInputChange} className="" placeholder="COLOR (BRAND)" type="text" value={this.props.state.colorBrand}></input>
                        
                        <label htmlFor="">COLOR BRAND (ESPAÑOL)</label>
                        <input name="colorBrandEspanol" onChange={this.props.handelInputChange} className="" placeholder="COLOR BRAND(ESPAÑOL)" type="text" value={this.props.state.colorBrandEspanol}></input>
                        
                        <label htmlFor="">ITEM DESCRIPTION (ESPAÑOL)</label>
                        <textarea name="itemDescriptionSpanish" onChange={this.props.handelInputChange} className="" placeholder="ITEM DESCRIPTION (ESPAÑOL)" type="text" value={this.props.state.itemDescriptionSpanish}></textarea>
                             
                        <label htmlFor="">EXTENDED DESCRIPTION</label>
                        <textarea name="extendedDescription" onChange={this.props.handelInputChange} className="" placeholder="EXTENDED DESCRIPTION" type="text" value={this.props.state.extendedDescription}></textarea>
                        
                        <label htmlFor="">EXTENDED DESCRIPTION (ESPAÑOL)</label>
                        <textarea name="extendedDescriptionSpanish" onChange={this.props.handelInputChange} className="" placeholder="EXTENDED DESCRIPTION (ESPAÑOL)" type="text" value={this.props.state.extendedDescriptionSpanish}></textarea>
                                            
                        
                    </div>

                    <div className="filtroData1">

                        <label htmlFor="">SKU SIMPLE</label>
                        <input  className= "" name="sku_simple" value={this.props.state.sku_simple} placeholder="SKU SIMPLE" type="text" onChange={this.props.handelInputChange} required></input>

                        <label htmlFor="">SKU</label>
                        <input disabled className= "" name="sku" value={this.props.state.sku} placeholder="SKU" type="text" onChange={this.props.handelInputChange} ></input>
                        
                        <label htmlFor="">MODEL</label>
                        <input className= "" name="model" value={this.props.state.model} placeholder="MODEL" type="text" onChange={this.props.handelInputChange} ></input>

                        <label htmlFor="">EAN</label>
                        <input name="ean" onChange={this.props.handelInputChange} className="" placeholder="EAN" type="text" value={this.props.state.ean}></input>
                        
                        <label htmlFor="">WEBLINK</label>
                        <input name="webLink" onChange={this.props.handelInputChange} className="" placeholder="WEBLINK" type="text" value={this.props.state.webLink}></input>
                        
                        <label htmlFor="">VIDEO LINK</label>
                        <input name="videoLink" onChange={this.props.handelInputChange} className="" placeholder="VIDEO LINK" type="text" value={this.props.state.videoLink}></input>
                        
                        <label htmlFor="">MATERIAL COMPOSITION</label>
                        <input name="materialComposition" onChange={this.props.handelInputChange} className="" type="text" value={this.props.state.materialComposition}></input>

                        <label htmlFor="">POWER SPECS</label>
                        <input name="powerSpecs" onChange={this.props.handelInputChange} className="" placeholder="POWER SPECS" type="text" value={this.props.state.powerSpecs}></input>

                        <label htmlFor="">LITHIUM BATTERY</label>
                        {/*
                        <input name="lithiumBattery" onChange={this.props.handelInputChange} className="" placeholder="LITHIUM BATTERY" type="text" defaultValue={this.props.state.lithiumBattery}></input>
                        
                        <select name="lithiumBattery" onChange={this.props.handelInputChange} defaultValue={this.props.state.lithiumBattery} className="" id="">
                            <option value="">- Seleccionar opcion -</option>
                            <option value="VERDADERO">SI</option>
                            <option value="FALSO">NO</option>
                        </select>*/}
                        <div className="divRadioButtons" onChange={this.props.handelInputChange.bind(this)}>
                            <label htmlFor="lithiumBattery_VERDADERO"><input type="radio" id="lithiumBattery_VERDADERO" value={1} name="lithiumBattery" defaultChecked ={this.props.state.lithiumBattery === 1} />SI</label>
                            <label htmlFor="lithiumBattery_FALSO"><input type="radio" id="lithiumBattery_FALSO" value={0} name="lithiumBattery" defaultChecked={this.props.state.lithiumBattery === 0} />NO</label>
                        </div>

                        <label htmlFor="">WIRELESS PRODUCT</label>
                        {/*
                        <input name="wirelessProduct" onChange={this.props.handelInputChange} className="" placeholder="WIRELESS PRODUCT" type="text" defaultValue={this.props.state.wirelessProduct}></input>
                        
                        <select name="wirelessProduct" onChange={this.props.handelInputChange} defaultValue={this.props.state.wirelessProduct} className="" id="">
                            <option value="">- Seleccionar opcion -</option>
                            <option value="VERDADERO">SI</option>
                            <option value="FALSO">NO</option>
                        </select>*/}
                        <div className="divRadioButtons" onChange={this.props.handelInputChange.bind(this)}>
                            <label htmlFor="wirelessProduct_VERDADERO"><input type="radio" id="wirelessProduct_VERDADERO" value={1} name="wirelessProduct" defaultChecked={this.props.state.wirelessProduct === 1}/>SI</label>
                            <label htmlFor="wirelessProduct_FALSO"><input type="radio" id="wirelessProduct_FALSO" value={0} name="wirelessProduct" defaultChecked={this.props.state.wirelessProduct === 0} />NO</label>
                        </div>

                        <label htmlFor="">LIFETIME WARRANTY</label>
                        {/*
                        <input name="lifeTimeWarranty" onChange={this.props.handelInputChange} className="" placeholder="LIFETIME WARRANTY" type="text" defaultValue={this.props.state.lifeTimeWarranty}></input>
                        
                        <select name="lifeTimeWarranty" onChange={this.props.handelInputChange} defaultValue={this.props.state.lifeTimeWarranty} className="" id="">
                            <option value="">- Seleccionar opcion -</option>
                            <option value="VERDADERO">SI</option>
                            <option value="FALSO">NO</option>
                        </select>*/}
                        <div className="divRadioButtons" onChange={this.props.handelInputChange.bind(this)}>
                            <label htmlFor="lifeTimeWarranty_VERDADERO"><input type="radio" id="lifeTimeWarranty_VERDADERO" value={1} name="lifeTimeWarranty" defaultChecked ={this.props.state.lifeTimeWarranty === 1} />SI</label>
                            <label htmlFor="lifeTimeWarranty_FALSO"><input type="radio" id="lifeTimeWarranty_FALSO" value={0} name="lifeTimeWarranty" defaultChecked = {this.props.state.lifeTimeWarranty  === 0} />NO</label>
                        </div>

                        <label htmlFor="">WARRANTY LENGTH</label>
                        <input onKeyPress={this.props.handelInputChangeNumber} name="warrantyLenth" onChange={this.props.handelInputChange} className="" placeholder="WARRANTY LENGTH" value={this.props.state.warrantyLenth} type="number" min="1" max="500"></input>

                        <label htmlFor="">WARRANTY UNIT</label>
                        {/*
                        <input name="warrantyUnit" onChange={this.props.handelInputChange} className="" placeholder="WARRANTY UNIT" type="text" defaultValue={this.props.state.warrantyUnit}></input>
                        */}
                        <select name="warrantyUnit" onChange={this.props.handelInputChange} value={this.props.state.warrantyUnit} id="">
                            <option value="">- Seleccionar opcion -</option>
                            <option value="MESES">MESES</option>
                            <option value="AÑOS">AÑOS</option>
                        </select>
                    </div>

                    <div className ="divButtonForm">
                        <button className="btnForm btnAfter" onClick={this.props.handlePageChangeBefore}> Anterior Pestaña</button>
                        <button className="btnForm btnNext" onClick={this.props.handlePageChange}> Siguente Pestaña</button>
                    </div>
                </div>
            </div>
        )
    };
}
export default ExtendInfoStep1;