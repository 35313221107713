import React, { Component } from "react";
import uuid from "uuid/v4";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";



let typeImg = "";

class testMultiImages extends Component {

    constructor(props) {
		super(props);
		
		this.state = {
			files: [],
			urls: [],
			isDragging: false,
			responseImages :[],

			filesLS : [],
			urlsLS: [],
			isDraggingLS : false,
			responseImagesLS :[],

			itemsFromBackend:[],

			columnsFromBackend : [{
				ObjectGroup: {
				  name: "Images",
				  items: []
				}
			  }]
			
		}
	}
	onDragEnd = async(result, columns) => {
		if (!result.destination) return;
		const { source, destination } = result;
	  
		if (source.droppableId !== destination.droppableId) {
		  const sourceColumn = columns[source.droppableId];
		  const destColumn = columns[destination.droppableId];
		  const sourceItems = [...sourceColumn.items];
		  const destItems = [...destColumn.items];
		  const [removed] = sourceItems.splice(source.index, 1);
		  destItems.splice(destination.index, 0, removed);
		  console.log(destItems)
	
		} else {
		  const column = columns[source.droppableId];
		  const copiedItems = [...column.items];
		  const [removed] = copiedItems.splice(source.index, 1);
		  copiedItems.splice(destination.index, 0, removed);
		  await this.setState({
			columnsFromBackend :[{
				ObjectGroup: {
					name: "Images",
					items: copiedItems
				}
			}]
		  })

		}
		console.log(this.state.columnsFromBackend)
	  };
	onRemove= async (index) =>{
		//console.log(index)
		//console.log(this.state.columnsFromBackend[0])
		for (let i = 0; i < this.state.files.length; i++) {
			if(this.state.columnsFromBackend[0].ObjectGroup.items[index].file.name === this.state.files[i].name){
				//console.log("file ", this.state.files[i].name, i, " Column ", this.state.columnsFromBackend[0].ObjectGroup.items[index].name, index)
				
				var {files, urls , columnsFromBackend } = this.state;
				let newcolumnsFromBackend = columnsFromBackend[0].ObjectGroup.items.filter((colum , x) => x !== index)
				let newFiles = files.filter((file, x) => x !== i);
				let newUrls = urls.filter((url, x) => x !== i);		
				await this.setState({
					...this.state,
					files: newFiles,
					urls: newUrls,
					columnsFromBackend :[{
						ObjectGroup: {
						name: "Images",
						items: newcolumnsFromBackend
						}
					}]
				});
			}
		}
		console.log(this.state.files);
		console.log(this.state.columnsFromBackend[0].ObjectGroup.items)
		/*
		var {files, urls , columnsFromBackend } = this.state;
		let newcolumnsFromBackend = columnsFromBackend[0].ObjectGroup.items.filter((colum , i) => i !== index)
		let newFiles = files.filter((file, i) => i !== index);
		let newUrls = urls.filter((url, i) => i !== index);		
		await this.setState({
			...this.state,
			files: newFiles,
			urls: newUrls,
			columnsFromBackend :[{
				ObjectGroup: {
				  name: "Images",
				  items: newcolumnsFromBackend
				}
			  }]
		});*/
	}
	
	handleDrags(e) {
		console.log("handleDrags ", e.target.getAttribute('name'))
		e.preventDefault();
		e.stopPropagation();		

		if( e.target.getAttribute('name') === "LS"){
			this.setState({
				...this.state,
				isDraggingLS: true
			});
			typeImg="LS";
		}
		else if (e.target.getAttribute('name') === "IM"){
			this.setState({
				...this.state,
				isDragging: true
			});
			typeImg="IM";
		}
	}
	
	handleDragEnter(e) {
		this.handleDrags(e);
	}
	
	handleDragOver(e) {
		this.handleDrags(e);
	}
	
	handleDragLeave(e) {
		console.log("handleDragLeave" ,e.target.getAttribute('name'))

		e.preventDefault();
		e.stopPropagation();		
		
		if( e.target.getAttribute('name') === "LS"){
			this.setState({
				...this.state,
				isDraggingLS: false
			});
			typeImg="LS";
		}
		else if( e.target.getAttribute('name') === "IM"){
			this.setState({
				...this.state,
				isDragging: false
			});
			typeImg="IM";
		}
	}
	
	onChange(e) {
		e.preventDefault()
		const files = e.target.files;
		[].forEach.call(files, this.handleFiles.bind(this));
	}
	
	handleDrop(e) {		
		console.log("handleDrop",e.target.getAttribute('name'));

		e.stopPropagation();
		e.preventDefault();		
		const data = e.dataTransfer;
		const files = data.files;
		console.log("Oops...you dropped this: ", files);		
		[].forEach.call(files, this.handleFiles.bind(this));		

		if( e.target.getAttribute('name') === "LS"){
			this.setState({
				...this.state,
				isDraggingLS: false
			});
			typeImg="LS";
		}
		else if( e.target.getAttribute('name') === "IM"){
			this.setState({
				...this.state,
				isDragging: false
			});
			typeImg="IM";
		}
	}
	VerificarFiles =()=>{
			console.log(this.state.files)
			console.log(this.state.urls)
	}
	updateVal= async (file) =>{
		this.state.itemsFromBackend.length = 0;
		console.log(this.state.files)
		for( let i = 0; i < this.state.urls.length ; i++ ) {
			
			this.state.itemsFromBackend.push({
				id: uuid(),
				ranking: i,
				indentificador: typeImg,
				content: this.state.urls[i],
				file: this.state.files[i]
			});	
		}

			
		console.log(this.state.itemsFromBackend)
		await this.setState({
			columnsFromBackend :[{
			  ObjectGroup: {
				name: "Images",
				items: this.state.itemsFromBackend
			  }
			}]
		  })
	}
	handleFiles = async (file) => {		
		// this could be refactored to not use the file reader		
		var reader = new FileReader();		
		reader.onloadend = async() => {
			var imageUrl = window.URL.createObjectURL(file);	
			
			console.log("imageUrl ",imageUrl, "file.Lenght", this.state.files.length, "typeImg ",typeImg);

			await this.setState({
				files: [file, ...this.state.files],
				urls: [imageUrl, ...this.state.urls]
			});
			this.updateVal();
					
		}
			
		reader.readAsDataURL(file);
	}

	uploadImages = async () =>{
		console.log(this.props.state.UPCVariation)
		
		for( let i = 0; i < this.state.columnsFromBackend[0].ObjectGroup.items.length; i++ ) {
			let normalImages = '';
			if (this.state.columnsFromBackend[0].ObjectGroup.items[i].ranking === "IM"){
				normalImages ="https://cato.skleo.com/api/v1/product/addImage2?upc=" + this.props.state.UPCVariation;
			}
			else{
				normalImages ="https://cato.skleo.com/api/v1/product/addImage2?upc=" + this.props.state.UPCVariation;
			}
			
			console.log(this.state.columnsFromBackend[0].ObjectGroup.items[i].file.name)
			var formdata = new FormData();
			formdata.append("image", this.state.columnsFromBackend[0].ObjectGroup.items[i].file);
			formdata.append('ranking', i + 1)
			var requestOptions = {
				method: 'POST',
				body: formdata,
				redirect: 'follow'
			};
			console.log("image", this.state.columnsFromBackend[0].ObjectGroup.items[i].file, 'ranking', i + 1)
			await fetch(normalImages , requestOptions)
			.then(response => response.text())
			.then(result => {
				// this.setState( {  responseImages: [...this.state.responseImages, result ]  } ) 
				console.log(result)
				var result1 = JSON.parse(result);
				console.log(result1['url'])
				this.setState( {  responseImages: [...this.state.responseImages, result1['url'] ]  } );
				
				this.setState({
					file:[],
					urls:[]
				});
			})
			.catch(error => console.log('error', error));		
		}
	}

	testUploadImg = async () => {
		//console.log(this.props.state.UPCList)
		console.log(this.props.state.UPCVariation)
		let normalImages ="https://cato.skleo.com/api/v1/product/addImage2?upc=" + this.props.state.UPCVariation;
		console.log(normalImages);

		for( let i = 0; i < this.state.files.length; i++ ) 
		{
			console.log(this.state.files[i], this.state.urls[i]);

			var formdata = new FormData();
			formdata.append("image", this.state.files[i]);
			formdata.append('ranking', i + 1)
			var requestOptions = {
				method: 'POST',
				body: formdata,
				redirect: 'follow'
			};
			
			await fetch(normalImages , requestOptions)
			.then(response => response.text())
			.then(result => {
				// this.setState( {  responseImages: [...this.state.responseImages, result ]  } ) 
				console.log(result)
				var result1 = JSON.parse(result);
				console.log(result1['url'])
				this.setState( {  responseImages: [...this.state.responseImages, result1['url'] ]  } );
				
				this.setState({
					file:[],
					urls:[]
				});
			})
			.catch(error => console.log('error', error));			
		}

		this.props.updateImgVariations(this.state.responseImages);
		console.log(this.state.responseImages);

		console.log(this.props.state);
	}

	testUploadImgLS = async () => {
		//console.log(this.props.state.UPCList)
		console.log(this.props.state.UPCVariation)
		
		let lifeStyleImage="https://cato.skleo.com/api/v1/product/addLifeStyleImage?upc=" + this.props.state.UPCVariation;
		console.log("lifeStyleImage ", lifeStyleImage);
		for( let i = 0; i < this.state.filesLS.length; i++ ) 
		{
			console.log("LS Images");
			console.log(this.state.filesLS[i], this.state.urlsLS[i]);

			var formdata = new FormData();
			formdata.append("image", this.state.filesLS[i], this.state.urlsLS[i]);
			var requestOptions = {
				method: 'POST',
				body: formdata,
				redirect: 'follow'
			};
			await fetch(lifeStyleImage , requestOptions)
			.then(response => response.text())
			.then(result => {
				// this.setState( {  responseImages: [...this.state.responseImages, result ]  } ) 
				console.log(result)
				var result1 = JSON.parse(result);
				console.log(result1['url'])
				this.setState( {  responseImagesLS: [...this.state.responseImagesLS, result1['url'] ]  } );
			})
			.catch(error => console.log('errorLS', error));			
		}
		this.props.updateImgVariationsLS(this.state.responseImagesLS);

		console.log(this.props.state);
	}

	imagesDrag(){
		const [columns] = this.state.columnsFromBackend;
		return (
		
		<div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
			<DragDropContext
			onDragEnd={result => this.onDragEnd(result, columns)}
			>
			{Object.entries(columns).map(([columnId, column], index) => {
				return (
				<div
					style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
					}}
					key={columnId}
				>
					<h2>{column.name}</h2>
					
					<div style={{ margin: 8 }}>
					<Droppable droppableId={columnId} key={columnId}>
						{(provided, snapshot) => {
						return (
							<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={{								
								padding: 4,
								minHeight: 500
							}}
							>
							{column.items.map((item, index) => {
								return (
								<Draggable
									key={item.id}
									draggableId={item.id}
									index={index}
								>
									{(provided, snapshot) => {
									return (
										<div ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={{
											userSelect: "none",
											width: 500,
											padding: 16,
											margin: "0 0 8px 0",
											minHeight: "50px",
											backgroundColor: "rgb(217, 217, 217)",
											color: "black",
											...provided.draggableProps.style
										}}
										className="previewItem" 
										>
										<img src={item.content} height="100px" width="100px" className="imagePreview"></img>
											<div className="details">
												<p>{item.file.name}</p>
												<button onClick={() => this.onRemove(index)} style={{backgroundColor: "#778294",
																		color: "white",
																		width: "100px",
																		height: "30px",
																		borderRadius: "5px"}}>
												Delete</button>
											</div>
										</div>
									);
									}}
								</Draggable>
								);
							})}
							{provided.placeholder}
							</div>
						);
						}}
					</Droppable>
					</div>
				</div>
				);
			})}
			</DragDropContext>
		</div>
		);
	}

	imageUploaded= () =>{
		
		return(
		<React.Fragment>		
			{ this.props.state.responseImages.map((image, index) => {
				if(index == 0){
				return(
					<React.Fragment key={image}>
						<h1>Imagen primaria</h1>
						<a href={image}  target="_blank">{image}</a>
					</React.Fragment>
				)
			}
			else{
				return(
					<React.Fragment key={image}>
						<h1>Imagenes Secundarias</h1>
						<a href={image}  target="_blank">{image}</a>
					</React.Fragment>
				)
			}
			})}		
		</React.Fragment>
		)
	}

	imageUploadedLS= () =>{
		return(
		<React.Fragment>		
			{ this.props.state.responseImagesLS.map((image, index) => {
				console.log("Index LS ", index);
				return(
					<React.Fragment key={image}>
						<h1>Imagen LS {  index + 1}</h1>
						<a href={image}  target="_blank">{image}</a>
					</React.Fragment>
				)
			})}		
		</React.Fragment>
		)
	}
	
    render(){

        const {urls, files, isDragging, filesLS, urlsLS, isDraggingLS} = this.state;
		const dropClass = isDragging ? "dragDrop dragging" : "dragDrop";
		const dropClassLS = isDraggingLS ? "dragDrop dragging" : "dragDrop";

        return(
            <div className="">
                <h1 style={{marginBottom:"10px", textAlign:"center"}}>IMAGENES</h1>
				<div className='SelectUPCIMG'><label htmlFor="" style={{marginRight:"10px"}}>UPC VARIATIONS</label> { this.props.comboboxFill() }</div>
								
				<div className="formImgsExt">									
					<div className="dropImgExt">
					
						<div id="app">										
							<div className="uploadInput" >
								<h2>Imagenes Primaria y Secundarias</h2>
								
								<input type="file"
									onChange={this.onChange.bind(this)}
									accept="image/*"
									multiple
								/>
								<div   name="IM" className={dropClass} 
									onDrop={this.handleDrop.bind(this)}
									onDragOver={this.handleDragOver.bind(this)}
									onDragEnter={this.handleDragEnter.bind(this)}
									onDragLeave={this.handleDragLeave.bind(this)} >
								</div >
								<button className="btnUpdateImg" onClick={() => this.uploadImages()}>Upload Images</button>		
								{/* <button className="btnUpdateImg" onClick={this.testUploadImg}>Upload Images</button>		 */}

							</div>
							
						</div>

						{/*<div id="appLS">
														
							 <div className="uploadInput" >
								<h2>Imagenes Estilo de Vida</h2>
								<input type="file"
									onChange={this.onChange.bind(this)}
									accept="image/*"
									multiple
								/>
								<div  className={dropClassLS} 
									 name="LS" onDrop={this.handleDrop.bind(this)}
									 onDragOver={this.handleDragOver.bind(this)}
									 onDragEnter={this.handleDragEnter.bind(this)}
									 onDragLeave={this.handleDragLeave.bind(this)}
									 >
								</div>	

								<button className="btnUpdateImg" onClick={this.testUploadImgLS}>Upload Images</button>		
								
							</div> 
							
						</div> */}
					</div>

					<div className="urlImgExt" style={{overflowY: "scroll", maxHeight:"500px"}}>
						<div className="imagePreviewContainer">
							{this.imagesDrag()}
							{/* {
								urls && (urls.map((url, i) => (
									<div key={files[i].name} className="previewItem" style={{height:"100px"}}>
										<img alt="" className="imagePreview" src={url} />
										<div className="details">
											<h6>{files[i].name.substr(0,10)}</h6>
											<i className="material-icons" 
											onClick={() => this.onRemove(i)}>delete</i>
											<input key={i+1} value={i+1}></input>
										</div>
									</div>
								)))
							} */}
						</div>
					</div>
				</div>

                <div className="">
                    
                    <div className ="divButtonForm">
                        <button className="btnForm btnAfter" onClick={this.props.handlePageChangeBefore}> Anterior Pestaña</button>
						
                        <button className="btnForm btnNext" onClick={this.props.handlePageChange}> Siguente Pestaña</button>
                    </div>
                </div>
            </div>
        )
    };
}
export default testMultiImages;