import React, { Component } from "react";

class enviarInformacion extends Component {

    render() {
    return(
        <div className="divDetailsExtendedInfo">
            <div className='Botones-Pagina'>
                {this.props.faltantesLista.map((faltante, index) => { return <h3 key={index}>{"Faltan datos en: " + faltante}</h3> } )}      
                {/* <h1 className ="titleExtendedInfo">Enviar Informacion Extendida</h1> */}
                <br/>
                <br/>
                <br/>
                <button className="btnUpdateImg" onClick={this.props.updateExtended}>
                    ENVIAR INFORMACION
                </button>
            </div>
        </div>
    )
    };
}
export default enviarInformacion;