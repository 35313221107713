import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"

import login from "./jsx/login"
import index from "./jsx/index"
import itemsProducts from "./jsx/Products"
import adminSettings from "./jsx/Admin"
import dataUser from "./jsx/EditUser"
import dataProposals from "./jsx/proposals"
import main_marketplace from './jsx/SendToMarketplace/main_marketplace'

import specialDiscounts from './jsx/ExtendedInfo/frmSpecialDiscount'

import extendedInformation from "./jsx/Formulario"
import viewProductsYuju from "./jsx/SendToMarketplace/viewProductsYuju"
import extended_info from "./jsx/ExtendedInfoV2/extended_info"
import main_semaforo from "./jsx/SendToMarketplace/main_Semaforo"
import pdf_reader from "./jsx/SendToMarketplace/PDF_Reader"
import SecondChannel from "./jsx/SendToMarketplace/SecondChannel"
import router from 'material-ui/svg-icons/hardware/router';

const NoMatchPage = () => {
  return (
    <div className= "login"> <div className = "divLogin"><h3 className ="ColorWhite">404 - Not found</h3> </div></div>
  );
};

function App() {
  return (
   <Router >
     <Switch>
      <Route exact path="/" component={login}/>
      <Route exact path="/Home" component={index}/>
      <Route exact path= "/Products" component={itemsProducts}/>
      <Route exact path="/AdminSettings" component ={adminSettings}/>
      <Route exact path= "/User" component ={dataUser}/>
      <Route exact path= "/Proposals" component ={dataProposals}/>

      <Route exact path= "/InfoExtendida" component = {extendedInformation}/>
      <Route exact path= '/send_marketplace' component = {main_marketplace}/>
      
      <Route exact path= '/main_semaforo' component = {main_semaforo}/>


      <Route exact path= '/specialDiscounts' component = {specialDiscounts}/>
      <Route exact path= "/viewYuju" component = {viewProductsYuju}/>
      <Route exact path= "/test_info" component = { extended_info }/>
      <Route exact path= "/SecondChannel" component = { SecondChannel }/>
      <Route exact path= "/pdf_reader" component ={pdf_reader}/>
      <Route component={NoMatchPage} />
     </Switch>
   </Router>
  );
}

export default App;