import React, { Component } from "react";

class DatosGenerales extends Component {

    handelInputChangeNumber = (evt) => {
        let valuevar = evt.target.value;
        //console.log("evt.charCode" , evt.charCode);
        if((evt.charCode>= 48 && evt.charCode <= 57) || evt.charCode === 46 ||evt.charCode === 0){
            if(valuevar.indexOf('.') > -1){
                if(evt.charCode === 46)
                    evt.preventDefault();
            }
        }else
        evt.preventDefault();
    };

    render() {
    return(
        <div className="divDetailsExtendedInfo">
            
            <h1 className ="titleExtendedInfo">Datos de Producto:</h1>
            <div className = "form" >
                <div className="filtroData1">
                    
                    <div className="filtroData3">       
                        <label htmlFor="">Item Description:</label>
                        <input disabled placeholder="Descripcion" type="text" name="description" onChange={this.props.handelInputChange} value = {this.props.state.description} ></input>
                    </div>
                    <div className="filtroData3">
                        <label htmlFor="">Item Number:</label>
                        <input disabled onKeyPress={this.props.handelInputChangeNumber} placeholder="Item Number" type="text" name="itemNumber" onChange={this.props.handelInputChange} value={this.props.state.itemNumber}/>
                     </div>
                    <div className="filtroData3">
                        <label htmlFor="">Color:</label>                        
                        <input disabled placeholder="Color" type="text" name="color" onChange={this.props.handelInputChange} value={this.props.state.color}/>
                    </div>
                    <div className="filtroData3">
                        <label htmlFor="">PVP:</label>
                        <input disabled onKeyPress={this.props.handelInputChangeNumber} placeholder="PVP" type="number" name="pvp" onChange={this.props.handelInputChange} value = {this.props.state.pvp} min="0" max="500000" step="0.001"></input>
                    </div>
                    <div className="filtroData3">
                        <label htmlFor="">Costo:</label>
                        <input disabled onKeyPress={this.props.handelInputChangeNumber} placeholder="Costo" type="number" name="cost" onChange={this.props.handelInputChange} value = {this.props.state.cost} min="0" max="500000" step="0.001"></input>
                    </div>  
                </div> 
                
                <div className="filtroData">
                    <label htmlFor="">Stock:</label>
                    <input disabled onKeyPress={this.props.handelInputChangeNumber} placeholder="Stock" type="number" name="stock" onChange={this.props.handelInputChange} value = {this.props.state.stock} min="0" max="500000" step="0.001"></input>
                
                    <label htmlFor="">Stock Salable Mx:</label>
                    <input disabled onKeyPress={this.props.handelInputChangeNumber} placeholder="Stock Mx" type="number" name="stock_salable_mx" onChange={this.props.handelInputChange} value = {this.props.state.stock_salable_mx} min="0" max="500000" step="0.001"></input>
                                
                    <label htmlFor="">Marca:</label>
                    <input disabled placeholder="Marca" type="text" name="brand" onChange={this.props.handelInputChange} value = {this.props.state.brand} ></input>
                
                    <label htmlFor="">Departamento:</label>
                    <input disabled placeholder="Departamento" type="text" name="dept" onChange={this.props.handelInputChange}  value = {this.props.state.dept} ></input>                
                
                    <label htmlFor="">Class:</label>
                    <input disabled placeholder="Class" type="text" name="class" onChange={this.props.handelInputChange} value = {this.props.state.class} ></input>                
                
                    <label htmlFor="">Subclass:</label>
                    <input disabled placeholder="Subclass" type="text" name="subclass" onChange={this.props.handelInputChange} value = {this.props.state.subclass} ></input>                
                 
                {/*
                    <label htmlFor="">Color:</label>
                    <input placeholder="Color" type="text" name="COLOR" onChange={this.props.handelInputChange} value = {this.props.state.category} ></input>                
                */}
                
                    <label htmlFor="">Status:</label>
                    {/*
                    <input placeholder="Status" type="text" name="status" onChange={this.props.handelInputChange} value = {this.props.state.status} ></input>                
                    
                    <select name="status" onChange={this.props.handelInputChange} value = {this.props.state.status} id="">
                        <option value="">- Seleccionar opcion -</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                    </select>*/}
                    <div className="divRadioButtons" onChange={this.props.handelInputChange.bind(this)} >
                        <label htmlFor="ACTIVE"><input type="radio" id="ACTIVE" value="ACTIVE" name="status" checked = {this.props.state.status === "ACTIVE"}/>ACTIVE</label>
                        <label htmlFor="INACTIVE"><input type="radio" id="INACTIVE" value="INACTIVE" name="status" checked = {this.props.state.status === "INACTIVE"}/>INACTIVE</label>
                        <label htmlFor="NEW"><input type="radio" id="NEW" value="NEW" name="status" checked = {this.props.state.status === "NEW"}/>NEW</label>
                        <label htmlFor="DISCONTINUED"><input type="radio" id="DISCONTINUED" value="DISCONTINUED" name="status" checked = {this.props.state.status === "DISCONTINUED"}/>DISCONTINUED</label>
                    </div>
                </div>

{
    //<button className="CreateProduct" onClick={this.props.createProductJson} height="50px">Crear Nuevo Producto</button>
}
                

                <div className ="divButtonForm">
                    <button className="btnForm btnNext" onClick={this.props.handlePageChange}> Siguente Pestaña</button>
                </div>
            </div>
            {/*<button className="btnForm" onClick={this.props.handlePageChange}> Siguente Pestaña</button>*/}
        </div>
    )
    };
}
export default DatosGenerales;