import React, { Component }  from 'react';


import BasicInfo from './ExtendedInfo/basicInfo';
import axios from "axios";
import ExtendInfoStep1 from './ExtendedInfo/extendedInfoStep1';
import Yuju from './ExtendedInfo/Yuju';
import ExtendInfoStep5 from './ExtendedInfo/extendedInfoStep5';
import Testingselect from './ExtendedInfo/TestingSelect';


import NavUL from "./navbarUL";
import Header from "./Header";
import EnviarInformacion from './ExtendedInfo/frmSendInfo';
import ImageChoice from './ExtendedInfo/ImageChoice';
import CurrentPage from './ExtendedInfo/CurrentPage';
import Csv_Reader from "./CsvReader"

import VistaPrevia from './ExtendedInfo/productPreview';

const domain = "https://cato.skleo.com";
const convert = require('convert-units');

class login extends Component {
  constructor( props ) {
    super( props );

    this.state = {
      currentState: 1,
      TextareaUPC: '',
      UPCList: [],
      name: '',
      pvp: 0,

      upc: "",      
      stock: 0,
      brand: '',
      dept: '',
      class: '',
      subclass: '',
      description: '',
      status: '',
      color: '',
      itemNumber:'',
      cost:'',
      
      colorBrand:'',
      ean:'',
      asin:null,
      webLink:'',
      videoLink:'',
      category:'',
      company: '',

      extendedDescription:'',
      extendedDescriptionSpanish:'',
      itemDescriptionSpanish:'', 
      itemDescription:'',

      primaryImage:'',
      secondaryImage:'',
      lifeStyleImages:'',

      productsVariation:[],
      upcsVariation:[],
      variationType:'',
      //variationType:[],
      compatibility:'',
      keywords:'',
      country_origin:'',

      materialComposition:'',
      powerSpecs:'',
      lithiumBattery:0,
      wirelessProduct:0,
      lifeTimeWarranty:0,
      warrantyLenth:'12',
      warrantyUnit:'MESES',
      
      packageContent:'',
      packagingImage:'',
      productLenght:'',
      productWidht:'',
      productHeight:'',
      productWeight:'',

      productDimensionsUnit:'cm',
      productWeightUnit:'kg',
      
      packageLenght:'',
      packageWidht:'',
      packageHeight:'',
      packageWeight:'',
      packageProductWeight:'',

      packageDimensionsUnit:'cm',
      packageWeightUnit:'kg',

      serie:'',

      features:[],
      numFeatures: 1,
      UPCVariation:'',
      
      productsArray:[],
    
      variationTypeColor:'',
      variationTypeSize:'',
      variationTypeCapacity:'',
      variationTypeConnectivity:'',
      variationTypeHeight:'',
      variationTypePower:'',

      mpn:'',
      model:'',
      sku_simple:'',
      sku:'',
      url_descripcion:'',
      banner:'',
      plantilla_mercado_libre:'predeterminado',
      tipo_publicacion_mercado_libre:'Clásica',
      /*condicion:'',*/
      condicion:'new',
      precio_envio:0,
      genero:'Unisex',
      garantia_producto:'1 año del fabricante',
      producto_virtual:0,
      gtin:'',
      jan:'',
      tienda_oficial_mercado_libre:0,
      garante:'',
      contenido_neto:'',
      images:[],
      imagesByUPC:[],
      imagesText : '',
      price:'',
      metodo_envio:'El marketplace lo calcula',
      //yujuCategory :'536',
      yujuCategory: '512',
      imagesVariation:[],

			responseImages :[],
      responseImagesLS :[],
      
      launch_date : "2020-05-26", 
      shipping_origin:"",
      replenishment_type:"",

      errores: [],

      selectedOption : [],

      color_Secundario:'',

      colorBrandEspanol:'',
      variationTypeColorBrand:'',
      variationTypeColorBrandEsp:'',

      colorUPCVariacion:'',
      stock_salable_mx:0,
      faltantesLista : [],
      CSV : []

    }    
  }

  handelInputChange = async (event) =>{
    event.persist();
    const { currentState } = this.state;
    let namevar = event.target.name;
    let valuevar = event.target.value;
    await this.setState( {
      [namevar] : (namevar = valuevar)
    });

    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 
  };

  handelInputChangeNumber = (evt) => {
    let valuevar = evt.target.value;
    console.log("evt.charCode FORMULARIO.JSX " , evt.charCode);
    if((evt.charCode>= 48 && evt.charCode <= 57) || evt.charCode === 46 ||evt.charCode === 0){
      if(valuevar.indexOf('.') > -1){
        if(evt.charCode === 46)
          evt.preventDefault();
      }
    }else
      evt.preventDefault();
  };

  removeTag = (i) => {
    const newUPCList = [ ...this.state.UPCList ];
    newUPCList.splice(i, 1);
    this.setState({ UPCList: newUPCList });
  }

  inputKeyDown = async (e) => {
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      if (this.state.UPCList.find(tag => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      let splitval = val.split(",");
      for ( let i = 0; i < splitval.length; i++ ) {
        console.log(splitval[i])
        if (this.state.UPCList.find(tag => tag.toLowerCase() === splitval[i].toLowerCase())) {
          console.log("Existe")
        }
        else{
          await this.setState({ UPCList: [...this.state.UPCList, splitval[i] ] });
        }
      }      
      this.tagInput.value = null;
    } else if (e.key === 'Backspace' && !val) {
      this.removeTag(this.state.UPCList.length - 1);
    }
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }

  handlePageChange = () =>{
    const { currentState } = this.state;
    this.setState( {
      currentState : this.state.currentState + 1
    });
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 
  }

  handleFeaturesChange(e, index){
    this.state.features[index] = e.target.value;
    this.setState({features: this.state.features})
  }

  addFeaures= () =>{
    return(
      <React.Fragment>     
        { this.state.features.map((feature, index) =>{
          return(
            <React.Fragment  key={index}> 
              <div key={"div"+index}>
                <input className="FeaturesCSS" onChange = {(e) => this.handleFeaturesChange(e,index)} value={feature} key={index}></input><button onClick={() => this.deleteFeature(index)} className="DeleteFeature" >X</button>
              </div>
            </React.Fragment>
          )
        })}
        <button onClick={(e) => this.ButtonAddFeature(e)}>add feature</button> 
      </React.Fragment>
    )
  }

  ButtonAddFeature () {
    this.setState({ features : [...this.state.features, '']});
  }

  deleteFeature = async(index) =>{
    try {
      var { features } = this.state;
      let newfeatures = features.filter((feature , x) => x !== index)

      await this.setState({
        features: newfeatures
      });
		}
		catch{
			//console.log('Error')
		}
  }

  buttonFillData= () =>{
    

    console.log (this.state.productsVariation[0].colorV)
    this.setState({ 
      
      /*color : this.state.color = this.state.productsVariation[0].colorV,
      compatibility : this.state.compatibility = this.state.productsVariation[0].compatibilityV,
      variationType : this.state.variationType = this.state.productsVariation[0].variationTypeV,
      country_origin : this.state.country_origin = this.state.productsVariation[0].country_originV,

      variationTypeSize:this.state.productsVariation[0].variationTypeSizeV,
      variationTypeCapacity:this.state.productsVariation[0].variationTypeCapacityV,
      variationTypeConnectivity:this.state.productsVariation[0].variationTypeConnectivityV,
      variationTypeHeight:this.state.productsVariation[0].variationTypeHeightV,
      variationTypePower:this.state.productsVariation[0].variationTypePowerV*/
        
      color : this.state.productsVariation[0].colorV,
      compatibility : this.state.productsVariation[0].compatibilityV,
      variationType : this.state.productsVariation[0].variationTypeV,
      country_origin : this.state.productsVariation[0].country_originV,

      variationTypeSize: this.state.productsVariation[0].variationTypeSizeV,
      variationTypeCapacity :this.state.productsVariation[0].variationTypeCapacityV,
      variationTypeConnectivity: this.state.productsVariation[0].variationTypeConnectivityV,
      variationTypeHeight: this.state.productsVariation[0].variationTypeHeightV,
      variationTypePower: this.state.productsVariation[0].variationTypePowerV
          
    });
  }

  getColorUPCVariacion = async (UPC) => {
    console.log("UPC getColor ",UPC);

    this.setState({
      colorUPCVariacion : "",
      variationTypeColor:"",
      variationTypeColorBrandEsp:""
    });

    let urlGetInfo = domain + "/api/v1/product/get?upc=" + UPC;
    try {
      await axios
      .get(urlGetInfo)
      .then(res => {
        if(res["data"]["product"] !== false){
          this.setState({
            colorUPCVariacion : res["data"]["product"].color,               
          });
        }
        else{
          console.log("Sin información");
          this.setState({
            colorUPCVariacion : ""
          });
        }
      });
    } catch (errorPost) {
      console.log(errorPost);
    }


    let UrlGetExtendedInfo = domain + "/api/v1/product/getExtended?upc=" + UPC;
    try{
      await axios
      .get(UrlGetExtendedInfo)
      .then(res => {  
        console.log("res-data-product ExtendedInfo ", res["data"]["product"]);

        if (res["data"]["product"].brand || res["data"]["product"].itemNumber) {                   
          this.setState({           
            variationTypeColor: res["data"]["product"].color_brand,
            variationTypeColorBrandEsp: res["data"]["product"].color_brand_es,
          })
        }
        else{             
          this.setState({
            variationTypeColor:"",
            variationTypeColorBrandEsp:""
          });
      
        }
      });
      console.log(this.state);
    }
    catch( errorP){
      console.log("Error ", errorP);
    }



    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }

  handleComboBoxXhange = (event) =>{
    event.persist();    
    this.handelInputChange(event)    

    this.getColorUPCVariacion(event.target.value);
    /*
    if(this.state.productsVariation.length === 0 || !this.state.upcsVariation.includes(event.target.value)){
      console.log("if Variacion")
      this.getColorUPCVariacion(event.target.value);
    }
    else{
      console.log("Else Variacion")
      const i = this.state.upcsVariation.indexOf(event.target.value);

      this.setState({ 
        variationTypeColor : this.state.productsVariation[i].variationTypeColorV,
        compatibility : this.state.productsVariation[i].compatibilityV,
        variationType : this.state.productsVariation[i].variationTypeV,
        country_origin : this.state.productsVariation[i].country_originV,

        variationTypeSize:this.state.productsVariation[i].variationTypeSizeV,
        variationTypeCapacity:this.state.productsVariation[i].variationTypeCapacityV,
        variationTypeConnectivity:this.state.productsVariation[i].variationTypeConnectivityV,
        variationTypeHeight:this.state.productsVariation[i].variationTypeHeightV,
        variationTypePower:this.state.productsVariation[i].variationTypePowerV,

        responseImages: this.state.productsVariation[i].responseImagesV,
        responseImagesLS:this.state.productsVariation[i].responseImagesLSV,

        variationTypeColorBrandEsp:this.state.productsVariation[i].variationTypeColorBrandEspV
      });
    }*/


    for ( let i = 0; i < this.state.productsVariation.length; i++ ) {
      if ( this.state.productsVariation[i].UPC  === event.target.value){        
        console.log("Entre a actualizar")
        this.setState({ 
          variationTypeColor : this.state.productsVariation[i].variationTypeColorV,
          compatibility : this.state.productsVariation[i].compatibilityV,
          variationType : this.state.productsVariation[i].variationTypeV,
          country_origin : this.state.productsVariation[i].country_originV,

          variationTypeSize:this.state.productsVariation[i].variationTypeSizeV,
          variationTypeCapacity:this.state.productsVariation[i].variationTypeCapacityV,
          variationTypeConnectivity:this.state.productsVariation[i].variationTypeConnectivityV,
          variationTypeHeight:this.state.productsVariation[i].variationTypeHeightV,
          variationTypePower:this.state.productsVariation[i].variationTypePowerV,

          responseImages: this.state.productsVariation[i].responseImagesV,
          responseImagesLS:this.state.productsVariation[i].responseImagesLSV,

          variationTypeColorBrandEsp:this.state.productsVariation[i].variationTypeColorBrandEspV,

          colorUPCVariacion:this.state.productsVariation[i].variationTypeColorBrandEspV
        })
        break;
      }
      else{
        this.setState({ 
          variationTypeColor : '',
          compatibility : '',
          variationType : '',
          country_origin : '',

          variationTypeSize: '',
          variationTypeCapacity: '',
          variationTypeConnectivity:  '',
          variationTypeHeight: '',
          variationTypePower: '',

          responseImages: [],
          responseImagesLS:[],

          variationTypeColorBrandEsp:'',
          colorUPCVariacion:''

        })      
      }
    }
    this.handelInputChange(event)   
  }

  changeVariationUPC= (val) =>{
    this.setState({
      UPCVariation : val
    })
  }

  comboboxFill= () =>{
    return(
      <select id="UPCVariation" name="UPCVariation" onChange={this.handleComboBoxXhange} defaultValue={this.state.UPCVariation}>
        <option value="">-Seleccionar opcion-</option>
        {this.state.UPCList.map(x => <option key = {x} value = {x}>{x}</option>) }
      </select>
    )
  }

  handlePageChangeBefore=()=>{
    const {currentState} = this.state;
    this.setState({
      currentState: this.state.currentState - 1
    });
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 
  }

  updateTextarea = async event => { await this.setState( { TextareaUPC : event.target.value } ); localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));};
  handleUPClist= async () =>{
    //var listUpc = this.state.TextareaUPC.split(",");
    var listUpc = this.state.TextareaUPC.replace(/\n/g, ",").replace(' ','').split(",");     
    await this.setState( { UPCList : listUpc } )
    console.log( this.state.UPCList );
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }

  componentDidMount() {    
    this.cookieCheck();
    //this.render(); 
    const  myArrayExtendedInfo = localStorage.getItem('myArrayExtendedInfo')
    if(myArrayExtendedInfo === null){
      localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 
    }

    var auxLG = localStorage.getItem('myArrayExtendedInfo'); 
    if(auxLG !== null && auxLG !== ""){
      //console.log("auxLG ", auxLG);
      auxLG = JSON.parse(auxLG);
      console.log("auxLG ", auxLG);
      console.log(auxLG.length);

      for (let i in auxLG) {
        if (i !== "currentState"){
          this.setState( state => {
            return {
              [i] : ( state[i] = auxLG[i] )
            };
          });
        }
      }
    }
  };

  getCookie = function( name ) {
    var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
    return r ? r[ 1 ] : null;
  };

  cookieCheck = async () => {
    var token = this.getCookie( 'token' );
    if ( token == null ) { this.props.history.push( '/' ) }
  };

  saveVariation=()=>{
    if(this.state.productsVariation.length === 0 || !this.state.upcsVariation.includes(this.state.UPCVariation)){
      // Incertar UPC para variacion
      console.log("Incertar UPC para variacion");    
      this.state.productsVariation.push({
        UPC:this.state.UPCVariation,
        //colorV:this.state.color,
        //variationTypeV:this.state.variationType,
        compatibilityV:this.state.compatibility,
        country_originV:this.state.country_origin,

        variationTypeColorV:this.state.variationTypeColor, //Brand
        variationTypeSizeV:this.state.variationTypeSize,
        variationTypeCapacityV:this.state.variationTypeCapacity,
        variationTypeConnectivityV:this.state.variationTypeConnectivity,
        variationTypeHeightV:this.state.variationTypeHeight,
        variationTypePowerV:this.state.variationTypePower,

        variationTypeColorBrandEspV: this.state.variationTypeColorBrandEsp, // Brand Espanol
        colorUPCVariacionV: this.state.colorUPCVariacion // Color ERP
      });
      this.state.upcsVariation.push(this.state.UPCVariation);

      this.state.imagesVariation.push({      
        imgNomal : [],
        imgLS:[]
      });
    }
    else{
      // Validar UPC para variacion
      //Actializar nueva información UPC
      console.log("JSON ACTUALIZAR -> ", this.state.productsVariation[this.state.upcsVariation.indexOf(this.state.UPCVariation)]);
      this.state.productsVariation[this.state.upcsVariation.indexOf(this.state.UPCVariation)] = {
        UPC:this.state.UPCVariation,
        //colorV:this.state.color,
        //variationTypeV:this.state.variationType,
        compatibilityV:this.state.compatibility,
        country_originV:this.state.country_origin,

        variationTypeColorV:this.state.variationTypeColor, // Brand
        variationTypeSizeV:this.state.variationTypeSize,
        variationTypeCapacityV:this.state.variationTypeCapacity,
        variationTypeConnectivityV:this.state.variationTypeConnectivity,
        variationTypeHeightV:this.state.variationTypeHeight,
        variationTypePowerV:this.state.variationTypePower,

        variationTypeColorBrandEspV: this.state.variationTypeColorBrandEsp, // Brand Espanol
        colorUPCVariacionV: this.state.colorUPCVariacion // Color ERP
      };
    }

    console.log("Form Save Variation NEW  ", this.state.productsVariation);
    console.log("Form upcsVariation", this.state.upcsVariation);

    this.setState( { productsVariation : this.state.productsVariation } )
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 
  }

  createProductJson = async () =>{
    for( let i = 0; i < this.state.UPCList.length ; i++ ) {
      
      this.state.productsArray.push({ 
        itemNumber : this.state.itemNumber,
        upc : this.state.UPCList[i],
        description: this.state.description,
        stock: this.state.stock,
        dept: this.state.dept,
        class: this.state.class,
        subclass: this.state.subclass,
        brand: this.state.brand,
        color: this.state.color,
        status: this.state.status,
        pvp: this.state.pvp,
        cost: this.state.cost
      })
    }

    const jsonNewProduct = {
      token:"bleh",
      products:this.state.productsArray
    }

    console.log(jsonNewProduct);

    let urlUpdate = domain + "/api/v1/product/create";
    console.log(urlUpdate);
    //let headerUpdate = "";
    var token = this.getCookie( 'token' );
    //console.log("Token Proposals ", token );
    if ( token === null ) { this.props.history.push( '/' ) }   
    let headerProposal ={
      headers: {
        'X-Viastara-Token': token,
      }
    }
    try {
      await axios
      .post(urlUpdate, jsonNewProduct, headerProposal)
      .then(res => {            
        console.log("Skype result ", res);
      });
    } catch (errorPost) {
      console.log(errorPost);
    }
    //let res = await axios.post(domain + "/api/v1/product/create", jsonNewProduct);
  }

////////////////////

  agruparUPC=()=>{
    let newUPC = document.getElementById("filterProducts").value;
    console.log("UPCList ",  this.state.UPCList , " newUPC", newUPC );
    if(this.state.UPCList.length === 0 || !this.state.UPCList.includes(newUPC)){
      //Agregar UPC
      this.state.UPCList.push(newUPC);
    }
    document.getElementById("textAreaUPCs").value += newUPC + "\r" ;
    document.getElementById("filterProducts").value="";
  };

  comboboxUPCVariations = () =>{
    return(
      <React.Fragment>
        <select>
          {this.state.UPCList.map(x => <option key = {x}>{x}</option>) }
        </select>
      </React.Fragment>
    );
  }

  getInfoUPC = async (e) =>{
    let infoBasicaGet=false;
    let infoExtendGet=false;

    const val = e.target.value;
    //console.log("valor " + val);
    if (e.key === 'Enter' && val) {

      this.getBasicInfoExtended(val);
     
    }
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }
  getInfoUPC2 = async (e) =>{
    let infoBasicaGet=false;
    let infoExtendGet=false;

    const val = e.target.value;
    //console.log("valor " + val);
    if (val) {

      this.getBasicInfoExtended(val);
     
    }
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }
  testValidarDatosYuju = async () => {
    console.log("testValidarDatosYuju");
    //console.log(this.state.category === "" ? "Vacio" : this.state.category);
    let auxErrores = {
      yujuCategory: this.state.yujuCategory === "",
      sku_simple: this.state.sku_simple === "",
      sku: this.state.sku === "",
      name: this.state.name === "",
      extendedDescriptionSpanish: this.state.extendedDescriptionSpanish === "",
      // images: this.state.images === "",
      imagesText : this.state.imagesText ==="",
      brand: this.state.brand === "",
      stock_salable_mx: this.state.stock_salable_mx === "",
      pvp: this.state.pvp === "",
      metodo_envio : this.state.metodo_envio === "",
      packageDimensionsUnit : this.state.packageDimensionsUnit === "",
      packageWidht : this.state.packageWidht === "",
      packageHeight : this.state.packageHeight === "",
      packageLenght : this.state.packageLenght === "",
      packageWeightUnit : this.state.packageWeightUnit === "",
      packageWeight : this.state.packageWeight === "",
      /*url_descripcion : this.state.url_descripcion === "",*/
      banner : this.state.banner === "",
      plantilla_mercado_libre : this.state.plantilla_mercado_libre === "",
      tipo_publicacion_mercado_libre : this.state.tipo_publicacion_mercado_libre === "",
      condicion : this.state.condicion === "",
      precio_envio : this.state.precio_envio === "",
      genero : this.state.genero === "",
      productWeight : this.state.productWeight === "",
      garantia_producto : this.state.garantia_producto === "",
      warrantyLenth : this.state.warrantyLenth === "",
      model : this.state.model === "",
      mpn : this.state.mpn === "",
      producto_virtual : this.state.producto_virtual === "",
      packageContent : this.state.packageContent === "",
      asin : this.state.asin === "",
      ean : this.state.ean === "",
      upc : this.state.upc === "",
      gtin : this.state.gtin === "",
      jan : this.state.jan === "",
      tienda_oficial_mercado_libre : this.state.tienda_oficial_mercado_libre === "",
      garante : this.state.garante === "",
      contenido_neto : this.state.contenido_neto === ""
    };

    this.setState({
      errores : auxErrores
    });

    console.log("Aux Errores ", auxErrores);
    console.log(this.state.errores);
    console.log(this.state);

    if(auxErrores.category || auxErrores.sku_simple || auxErrores.sku || auxErrores.name || auxErrores.extendedDescriptionSpanish || auxErrores.images || auxErrores.brand ||
      auxErrores.stock_salable_mx || auxErrores.pvp || auxErrores.metodo_envio || auxErrores.packageDimensionsUnit || auxErrores.packageWidht || auxErrores.packageHeight || auxErrores.packageLenght || 
      auxErrores.packageWeightUnit || auxErrores.packageWeight || auxErrores.garantia_producto || auxErrores.warrantyLenth || auxErrores.upc){
      alert("Campos Requeridos Incompletos, Favor de Llenar toda la información");
    }
    else{
      /* RECORRER VARIACOINES*/
      if(this.state.productsVariation.length !== 0){
        for( let i = 0; i < this.state.productsVariation.length ; i++ ) {
          let urlUdateCrearUju = domain +  "/api/v1/product/createYujuRecommended?upc=" + this.state.productsVariation[i].UPC ;
          let valoresYuju=[];

          valoresYuju.push({
            category: this.state.yujuCategory,
            desc_url: this.state.webLink,
            banner: this.state.banner,
            ml_template: this.state.plantilla_mercado_libre,
            ml_post_type: this.state.tipo_publicacion_mercado_libre,
            condition: this.state.condicion,
            shipping_method: this.state.metodo_envio,
            shipping_cost: parseFloat(this.state.precio_envio),
            gender: this.state.genero,
            warranty: this.state.garantia_producto,
            warranty_months: parseInt(this.state.warrantyLenth),
            model: this.state.model,
            mpn: this.state.mpn,
            virtual_product: parseInt(this.state.producto_virtual),
            asin: this.state.asin,
            gtin: this.state.itemNumber,
            jan: this.state.jan,
            official_store: this.state.tienda_oficial_mercado_libre,
            net_content: this.state.contenido_neto,
            guarantor: this.state.garante
          });

          var token = this.getCookie( 'token' );
          if ( token === null ) { this.props.history.push( '/' ) }   
          let headerProposal ={
            headers: {
              'X-Viastara-Token': token,
            }
          }
          console.log("Array Variaciones YUJU",JSON.stringify(valoresYuju[0]));
          
          try {
            await axios
            .post(urlUdateCrearUju, JSON.stringify(valoresYuju[0]), headerProposal)
            .then(res => {            
              console.log("Result YUJU VARIACION ", res);
              if(i === this.state.productsVariation.length-1){
                alert("Resultado Envio de informacion : Correcto");
              }
            });
          } catch (errorPost) {
            console.log(errorPost);
            if(i === this.state.productsVariation.length-1){
              alert("Error envio de informacion : " + errorPost);
            }
          }

        }
      }
      else{      
        let urlUdateCrearUju = domain +  "/api/v1/product/createYujuRecommended?upc=" + this.state.UPCList[0];
        let valoresYuju=[];

        valoresYuju.push({
          category: this.state.yujuCategory,
          desc_url: this.state.webLink,
          banner: this.state.banner,
          ml_template: this.state.plantilla_mercado_libre,
          ml_post_type: this.state.tipo_publicacion_mercado_libre,
          condition: this.state.condicion,
          shipping_method: this.state.metodo_envio,
          shipping_cost: parseFloat(this.state.precio_envio),
          gender: this.state.genero,
          warranty: this.state.garantia_producto,
          warranty_months: parseInt(this.state.warrantyLenth),
          model: this.state.model,
          mpn: this.state.mpn,
          virtual_product: parseInt(this.state.producto_virtual),
          asin: this.state.asin,
          gtin: this.state.itemNumber,
          jan: this.state.jan,
          official_store: this.state.tienda_oficial_mercado_libre,
          net_content: this.state.contenido_neto,
          guarantor: this.state.garante
        });

        var token = this.getCookie( 'token' );
        if ( token === null ) { this.props.history.push( '/' ) }   
        let headerProposal ={
          headers: {
            'X-Viastara-Token': token,
          }
        }
        console.log(JSON.stringify(valoresYuju[0]));
        try {
          await axios
          .post(urlUdateCrearUju, JSON.stringify(valoresYuju[0]), headerProposal)
          .then(res => {            
            console.log("Result YUJU GENERAL ", res);
            alert("Resultado Envio de informacion : Correcto");
          });
        } catch (errorPost) {
          console.log("Error General YUJU ", errorPost);
          alert("Error enviar Informacin YUJU " + errorPost);
        }
      }

      /*
        {
        "category": "Bleh",
        "desc_url": "s://youtube.com",
        "banner": "I literally have no idea wtf goes here",
        "ml_template": "predeterminado",
        "ml_post_type": "Gratuita",
        "condition": "Nuevo",
        "shipping_method": "Gratis",
        "shipping_cost": 99.99,
        "gender": "Masculino",
        "warranty": "Meses",
        "warranty_months": 3,
        "model": "Weird product update",
        "mpn": 123456,
        "virtual_product": 0,
        "asin": 234567,
        "gtin": 345678,
        "jan": 456789,
        "official_store": "101",
        "net_content": "5pz",
        "guarantor": "Dex (Ya que...)"
        }
      */
    }

    return( auxErrores);
  };

  getYujuData = async ()=> { 
    let getYujuRecommended = domain+  "/api/v1/product/getYujuRecommended?upc=" + this.state.upc 

    var token = this.getCookie( 'token' );
    if ( token === null ) { this.props.history.push( '/' ) }   
    let headerProposal ={
      headers: {
        'X-Viastara-Token': token,
      }
    }
    try{
      await axios
      .get(getYujuRecommended, headerProposal)
      .then(res => {  
        console.log("res-data-product vgetYujuRecommended ", res);

        this.setState({
          yujuCategory: res["data"]["product"].category,
          webLink: res["data"]["product"].desc_url,
          banner: res["data"]["product"].banner,
          plantilla_mercado_libre:res["data"]["product"].ml_template,
          tipo_publicacion_mercado_libre: res["data"]["product"].ml_post_type,
          condicion: res["data"]["product"].condition.toLowerCase(),
          metodo_envio: res["data"]["product"].shipping_method,
          precio_envio: res["data"]["product"].shipping_cost,
          genero: res["data"]["product"].gender,
          garantia_producto: res["data"]["product"].warranty,
          warrantyLenth: res["data"]["product"].warranty_months,
          model: res["data"]["product"].model,
          mpn: res["data"]["product"].mpn,
          producto_virtual: res["data"]["product"].virtual_product,
          asin: res["data"]["product"].asin,
          gtin: res["data"]["product"].gtin,
          jan: res["data"]["product"].jan,
          tienda_oficial_mercado_libre: res["data"]["product"].official_store,
          contenido_neto: res["data"]["product"].net_content,
          garante: res["data"]["product"].guarantor
        })

        console.log(res["data"]["product"].condition.toLowerCase());
        let valorCat = res["data"]["product"].category
        if (512 === parseInt(valorCat) || 533 === parseInt(valorCat) || 534 === parseInt(valorCat) || 535 === parseInt(valorCat) || 536 === parseInt(valorCat) || 537 === parseInt(valorCat) || 538 === parseInt(valorCat) ){
          console.log("Valor Valido")
        }
        else{
          console.log("Valor invalido set to default: 512") 
          this.setState({
            yujuCategory: '512',
          });
        }
      })
    }
    catch{
      console.log('Error')
    }
  }

  updateImgVariations=(imgNormalN)=>{
    console.log("FORMULARIO ", imgNormalN);
    this.setState( { 
      responseImages :imgNormalN
    } );

    this.state.productsVariation[this.state.upcsVariation.indexOf(this.state.UPCVariation)] = {
      ...this.state.productsVariation[this.state.upcsVariation.indexOf(this.state.UPCVariation)],
      responseImagesV :imgNormalN
    };

    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }

  updateImgVariationsLS=(imgLSN)=>{
    console.log("FORMULARIO ", imgLSN);
    this.setState( { 
      responseImagesLS: imgLSN
    } );

    this.state.productsVariation[this.state.upcsVariation.indexOf(this.state.UPCVariation)] = {
      ...this.state.productsVariation[this.state.upcsVariation.indexOf(this.state.UPCVariation)],
      responseImagesLSV: imgLSN
    };

    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }

  updateKeyWords = (keywordsN)=>{
    this.setState({ keywords: keywordsN });
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }

  saveSelectedOptionType=(selectedOptionSender)=>{
    console.log(selectedOptionSender);
    this.setState({
      selectedOption : selectedOptionSender
    });
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }

  variablesVacias = async(valor , name )=>{
    if (valor === null || valor === ''){
      await this.setState({
        faltantesLista : [...this.state.faltantesLista,  name ]
      })
    }
  }

  deberiaDarListas=async()=>{
    await this.setState({
      faltantesLista : []
    })
    //OBLIGATORIOS
    this.variablesVacias( this.state.launch_date , "launch_date")
    this.variablesVacias( this.state.lithiumBattery , "lithiumBattery")
    //OPCIONALEs

    console.log(this.state.faltantesLista)
    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }
  emptyValue= async(value) =>{
    if (value === '') {return null}
  }
  updateExtended = async () => {
    const resp = window.confirm("Guardar Para despues...");
    if(resp){
      // Sin guardar
      this.GuardarParaDespues()
      this.setState({
        UPCList : []
      })
    }
    else{
      this.setState({
        UPCList : []
      })
    }

    return;
    this.deberiaDarListas()

    if ( this.state.launch_date === ''){ await this.setState({ launch_date : null })}
    if ( this.state.videoLink === null){ await this.setState({ videoLink : "" })}    
    if ( this.state.powerSpecs === null){ await this.setState({ powerSpecs : "" })}
    if (this.state.sku_simple === null){await this.setState({ sku_simple : "" })}
    let respuesta='';
    console.log("updateExtended");
    let dimenc = [];
    dimenc.push({   
      dimensions_unit: this.state.productDimensionsUnit ,
      weight_unit : this.state.productWeightUnit ,
      dimensions_unit : this.state.packageDimensionsUnit ,
      weight_unit : this.state.packageWeightUnit ,
      
      pkg_length :  this.convertUnits(this.state.packageDimensionsUnit, "mm", this.state.packageLenght),
      pkg_width: this.convertUnits(this.state.packageDimensionsUnit, "mm", this.state.packageWidht),
      pkg_height : this.convertUnits(this.state.packageDimensionsUnit, "mm", this.state.packageHeight),
      pkg_weight : this.convertUnits(this.state.packageWeightUnit, "gr", this.state.packageWeight),

      weight : this.convertUnits(this.state.productWeightUnit, "gr", this.state.productWeight),
      length : this.convertUnits(this.state.productDimensionsUnit, "mm", this.state.productLenght),
      width: this.convertUnits(this.state.productDimensionsUnit, "mm", this.state.productWidht),
      height : this.convertUnits(this.state.productDimensionsUnit, "mm", this.state.productHeight)         
    });

    if(this.state.asin === ''){
      this.setState({asin:null});
    }

    if ( this.state.productsVariation.length !== 0){
      for( let i = 0; i < this.state.productsVariation.length ; i++ ) {
        if( this.state.productsVariation[i].UPC !== "" &&  this.state.productsVariation[i].variationTypeColorV !== "" ){      
          let typeVariationSelected = ''
          let updateLink = domain +  "/api/v1/product/updateExtended?upc=" + this.state.productsVariation[i].UPC ;
          console.log("Variation ", updateLink);
          for( let q = 0; q < this.state.selectedOption.length ; q++ ){
            if (q === 0 ){
              typeVariationSelected = typeVariationSelected + this.state.selectedOption[q].label  
            }
            else{
              typeVariationSelected = typeVariationSelected + ";" + this.state.selectedOption[q].label 
            }          
          }

          let updateExtendedArray = []
          updateExtendedArray.push({
            features : this.state.features,
            ean : this.state.ean,
            model : this.state.model,
            asin : this.state.asin,
            description_en : this.state.description,
            name: this.state.name,
            description_es : this.state.itemDescriptionSpanish,
            ext_en : this.state.extendedDescription,
            ext_es : this.state.extendedDescriptionSpanish,
            color : this.state.productsVariation[i].colorUPCVariacionV,
            color_brand : this.state.productsVariation[i].variationTypeColorV,
            _class : this.state.class,
            department : this.state.dept,
            subclass : this.state.subclass,
            category : '0',
            brand : this.state.brand,
            sku_simple : this.state.sku_simple,
            url : this.state.webLink,
            video_url : this.state.videoLink,
            variation_type : typeVariationSelected,
            keywords : this.state.keywords,
            shipping_origin : this.state.shipping_origin,
            launch_date : this.state.launch_date,
            country_origin : this.state.country_origin,
            package_content : this.state.packageContent,
            power_specs : this.state.powerSpecs,
            warranty_length : this.state.warrantyLenth,
            company : this.state.company,
            status : this.state.status,
            compatibility : this.state.compatibility,
            material_composition : this.state.materialComposition,
            replenishment_type:this.state.replenishment_type,
            dimensions : dimenc[0],
            color_brand_es: this.state.productsVariation[i].variationTypeColorBrandEspV,
            lithium_battery: this.state.lithiumBattery,
            wireless: parseInt( this.state.wirelessProduct ),
            lifetime_warranty: parseInt( this.state.lifeTimeWarranty )
          });

          console.log("Array variacion ",updateExtendedArray );
          console.log("JSON Variacion ", JSON.stringify(updateExtendedArray[0]));

          var token = this.getCookie( 'token' );
          if ( token === null ) { this.props.history.push( '/' ) }   
          let headerProposal ={
            headers: {
              'X-Viastara-Token': token,
            }
          }
          var requestOptions = {
            method: 'POST',
            body: JSON.stringify(updateExtendedArray[0]),
            redirect: 'follow'
          };
          
          await fetch(updateLink , requestOptions)
          .then(response => response.text())
          .then(result => {
            let jsonResult = JSON.parse(result)
            console.log("Result extended", jsonResult);
    
              if (jsonResult.success == true){
                alert("Resultado Envio de informacion : Correcto");
                this.handlePageChange();
              }
            
            else{
              alert("Resultado Envio de informacion : " + JSON.stringify(jsonResult.error) );
            }
            
          })
          .catch(error => console.log('error', error));	

          // try {
          //   await axios
          //   .post(updateLink, JSON.stringify(updateExtendedArray[0]), headerProposal)
          //   .then(res => {            
          //     console.log("Result extended", res);
          //     if(i === this.state.productsVariation.length-1 )
          //     {
          //       alert("Resultado Envio de informacion : Correcto");
          //       this.handlePageChange();
          //     }
          //   });
          // } catch (errorPost) {
          //   console.log("Error Extended Variations ", errorPost);
          //   if(i === this.state.productsVariation.length-1 )
          //   {
          //     alert("Error Enviar informacion" + errorPost);
          //   }
          // }
        }
      }
    }
    else{    
      let updateLink = domain+  "/api/v1/product/updateExtended?upc=" + this.state.UPCList[0]
      let updateExtendedArray = [];
      console.log("General ",updateLink);
    
      updateExtendedArray.push({
        features : this.state.features,
        ean : this.state.ean,
        model : this.state.model,
        asin : this.state.asin,
        name : this.state.name,
        description_en : this.state.description,
        description_es : this.state.itemDescriptionSpanish,
        ext_en : this.state.extendedDescription,
        ext_es : this.state.extendedDescriptionSpanish,
        color : this.state.color,
        color_brand : this.state.colorBrand,
        _class : this.state.class,
        department : this.state.dept,
        subclass : this.state.subclass,
        category : '0',
        brand : this.state.brand,
        sku_simple: this.state.sku_simple,
        url : this.state.webLink,
        video_url : this.state.videoLink,
        variation_type : this.state.variationType,
        keywords : this.state.keywords,
        shipping_origin : this.state.shipping_origin,
        launch_date : this.state.launch_date,
        country_origin : this.state.country_origin,
        package_content : this.state.packageContent,
        power_specs : this.state.powerSpecs,
        warranty_length : this.state.warrantyLenth,
        company : this.state.company,
        status : this.state.status,
        compatibility : this.state.compatibility,
        material_composition : this.state.materialComposition,
        replenishment_type:this.state.replenishment_type,
        dimensions : dimenc[0],
        color_brand_es: this.state.colorBrandEspanol,
        lithium_battery: this.state.lithiumBattery,
        wireless: parseInt( this.state.wirelessProduct ),
        lifetime_warranty: parseInt( this.state.lifeTimeWarranty )
      });
    
      console.log('Array GENERAL ', updateExtendedArray );
      console.log('GENERAL JSON ', JSON.stringify(updateExtendedArray[0]) );
    
      var token = this.getCookie( 'token' );
      if ( token === null ) { this.props.history.push( '/' ) }   
      let headerProposal ={
        headers: {
          'X-Viastara-Token': token,
        }
      }

      var requestOptions = {
        method: 'POST',
        body: JSON.stringify(updateExtendedArray[0]),
        redirect: 'follow'
      };
      
      await fetch(updateLink , requestOptions)
      .then(response => response.text())
      .then(result => {
        let jsonResult = JSON.parse(result)
        console.log("Result extended", jsonResult);

          if (jsonResult.success == true){
            alert("Resultado Envio de informacion : Correcto");
            this.handlePageChange();
          }
        
        else{
          alert("Resultado Envio de informacion : " + JSON.stringify(jsonResult.error) );
        }
        
      })
      .catch(error => console.log('error', error));	

      // try {
      //   await axios
      //   .post(updateLink, JSON.stringify(updateExtendedArray[0]), headerProposal)
      //   .then(res => {            
      //     console.log("Result extended", res);
      //     alert("Resultado Envio de informacion : Correcto");
      //     this.handlePageChange();
      //   });
      // } catch (errorPost) {
      //   console.log("Error Extended General ",errorPost ); 
      //   alert("Error Enviar informacion" + errorPost);
      // }    
    }
  }

  updateImagesByUPC = async(e) =>{
    await this.setState({imagesByUPC : e}) 
  }

  changePageButtons=async(val)=>{
    await this.setState({
    currentState : val
  })
  }

  limpiarVariables(){
    this.setState({ 
      brand : "",
      class : "",
      color : "",
      dept : "",
      cost: "",
      description : "",
      itemNumber : "",
      pvp : "",
      status : "",
      stock : 0,
      subclass : "",
      upc : "",
      asin:  null,
      company : "",
      compatibility: "",
      country_origin: "",
      department: "",
      name:"",
      itemDescriptionSpanish: "",
      ean: "",
      extendedDescription: "",
      extendedDescriptionSpanish: "",
      keywords: "",
      launch_date: "2020-05-26",
      materialComposition: "",
      package_content: "",
      power_specs: "",
      replenishment_type: "",
      shipping_origin: "",
      colorBrand: "",
      webLink: "",
      variationType: "",
      videoLink: "",
      warranty_length: '12',
      warrantyUnit:'MESES',
      features: [],
      images : [],
      imagesText : "",
      packageWeightUnit : 'kg',
      metodo_envio: 'El marketplace lo calcula',
      plantilla_mercado_libre: 'predeterminado',
      tipo_publicacion_mercado_libre: 'clasica',
      condicion : 'new',
      sku: "",
      sku_simple : "" ,
      colorBrandEspanol:'',
      packageLenght :  '',
      packageWidht: '',
      packageHeight: '',
      productWeight: '',
      packageWeight: '',
      packageDimensionsUnit : '',
      dimensions:[],
      stock_salable_mx: 0,
      lithiumBattery:0,
      wirelessProduct:0,
      lifeTimeWarranty:0,
      productLenght:'',
      productWidht:'',
      productHeight:'',

      itemDescription:'',
      productDimensionsUnit:'cm',
      productWeightUnit:'kg',

      productsVariation:[],
      upcsVariation:[],
      numFeatures: 1,
      productsArray:[],
      UPCVariation:'',

      variationTypeColor:'',
      variationTypeSize:'',
      variationTypeCapacity:'',
      variationTypeConnectivity:'',
      variationTypeHeight:'',
      variationTypePower:'',
      variationTypeColorBrand:'',
      variationTypeColorBrandEsp:'',
      colorUPCVariacion:'',
      
      primaryImage:'',
      secondaryImage:'',
      lifeStyleImages:'',
      packagingImage:'',
      color_Secundario:'',

      serie:'',
      mpn:'',
      model:'', 
      url_descripcion:'',
      banner:'',

      precio_envio:0,
      genero:'Unisex',
      garantia_producto:'1 año del fabricante',
      producto_virtual:0,
      gtin:'',
      jan:'',
      tienda_oficial_mercado_libre:0,
      garante:'',
      contenido_neto:'',
      imagesByUPC:[], 
      price:'',

      yujuCategory: '512',
      imagesVariation:[],

      responseImages :[],
      responseImagesLS :[],
      errores: [],
      selectedOption : [],

    });
  }

  getBasicInfoExtended = async (upcSearch) =>{
    console.log(upcSearch);
    let infoBasicaGet=false;
    let infoExtendGet=false;

    this.limpiarVariables();    
    let urlGetInfo = domain + "/api/v1/product/get?upc=" + upcSearch;
    let UrlGetExtendedInfo = domain + "/api/v1/product/getExtended?upc=" + upcSearch;

    try {
      await axios
      .get(urlGetInfo)
      .then(res => {   
        console.log("res-data-product Basic ", res["data"]["product"]);

        if(res["data"]["product"] !== false){
          infoBasicaGet=true;
          this.setState({ 
            brand : res["data"]["product"].brand,
            class : res["data"]["product"].class,
            color : res["data"]["product"].color,
            dept : res["data"]["product"].dept,
            cost: res["data"]["product"].cost,
            description : res["data"]["product"].description,
            itemNumber : res["data"]["product"].itemNumber,
            pvp : res["data"]["product"].pvp,
            status : res["data"]["product"].status,
            stock : res["data"]["product"].stock,
            subclass : res["data"]["product"].subclass,
            upc : res["data"]["product"].upc,

            stock_salable_mx: res["data"]["product"].mx_salable
          });
        }
        else{
          console.log("Sin información");
          this.setState({ 
            brand : "",
            class : "",
            color : "",
            dept : "",
            cost: "",
            description : "",
            itemNumber : "",
            pvp : "",
            status : "",
            stock : "",
            subclass : "",
            upc : ""
          });
        }
      });
    } catch (errorPost) {
      console.log(errorPost);
    }

    let dimensionGeneral;
    try{
      await axios
      .get(UrlGetExtendedInfo)
      .then(res => {  
        console.log("res-data-product ExtendedInfo ", res["data"]["product"]);

        if (res["data"]["product"].brand || res["data"]["product"].itemNumber) {
          infoExtendGet=true;                    
          this.setState({
            asin:  res["data"]["product"].asin,
            brand: res["data"]["product"].brand,
            company : res["data"]["product"].company,
            compatibility: res["data"]["product"].compatibility,
            country_origin: res["data"]["product"].country_origin,
            //department: res["data"]["product"].department,
            model : res["data"]["product"].model,
            dept: res["data"]["product"].department,
            name : res["data"]["product"].name,
            itemDescriptionSpanish: res["data"]["product"].description_es,
            ean: res["data"]["product"].ean,
            extendedDescription :res["data"]["product"].ext_en,
            extendedDescriptionSpanish: res["data"]["product"].ext_es,
            itemNumber: res["data"]["product"].itemNumber,
            keywords: res["data"]["product"].keywords,
            launch_date: res["data"]["product"].launch_date,
            materialComposition: res["data"]["product"].material_composition,
            packageContent: res["data"]["product"].package_content,
            powerSpecs: res["data"]["product"].power_specs,
            replenishment_type: res["data"]["product"].replenishment_type,
            shipping_origin: res["data"]["product"].shipping_origin,
            subclass: res["data"]["product"].subclass,
            colorBrand: res["data"]["product"].color_brand,
            webLink: res["data"]["product"].url,
            variationType: res["data"]["product"].variation_type,
            //variation_type: res["data"]["product"].variation_type,
            videoLink: res["data"]["product"].video_url,
            warrantyLenth: res["data"]["product"].warranty_length,
            features: res["data"]["product"].features,
            images : res['data']['product'].images,
            imagesText : res['data']['product'].images.join(', '),        
            //metodo_envio: '1',
            metodo_envio: 'El marketplace lo calcula',
            plantilla_mercado_libre: 'predeterminado',
            tipo_publicacion_mercado_libre: 'clasica',
            condicion : 'new',
            sku: res["data"]["product"].itemNumber,
            sku_simple : res["data"]["product"].sku_simple,
            colorBrandEspanol: res["data"]["product"].color_brand_es,
            lithiumBattery: res["data"]["product"].lithium_battery,
            wirelessProduct: res["data"]["product"].wireless,
            lifeTimeWarranty:res["data"]["product"].lifetime_warranty
          })

          dimensionGeneral = res['data']['product'].dimensions;            
          console.log(res);
          if(dimensionGeneral !== null){
            this.setState({
              productDimensionsUnit: dimensionGeneral.dimensions_unit,
              productWeightUnit: dimensionGeneral.weight_unit,
              packageDimensionsUnit : dimensionGeneral.dimensions_unit,
              packageWeightUnit : dimensionGeneral.weight_unit,

              // packageLenght :  dimensionGeneral.pkg_length,
              // packageWidht: dimensionGeneral.pkg_width,
              // packageHeight: dimensionGeneral.pkg_height,              
              // packageWeight: dimensionGeneral.pkg_weight,

              // productWeight: dimensionGeneral.weight,
              // productLenght : dimensionGeneral.length,
              // productWidht : dimensionGeneral.width,
              // productHeight : dimensionGeneral.height,              

              packageLenght : this.convertUnits("mm", dimensionGeneral.dimensions_unit, dimensionGeneral.pkg_length),
              packageWidht: this.convertUnits("mm", dimensionGeneral.dimensions_unit, dimensionGeneral.pkg_width),
              packageHeight: this.convertUnits("mm", dimensionGeneral.dimensions_unit, dimensionGeneral.pkg_height),          
              packageWeight: this.convertUnits("gr", dimensionGeneral.weight_unit, dimensionGeneral.pkg_weight),

              productWeight: this.convertUnits("gr", dimensionGeneral.weight_unit, dimensionGeneral.weight),
              productLenght : this.convertUnits("mm", dimensionGeneral.dimensions_unit, dimensionGeneral.length),
              productWidht : this.convertUnits("mm", dimensionGeneral.dimensions_unit, dimensionGeneral.width),
              productHeight : this.convertUnits("mm", dimensionGeneral.dimensions_unit, dimensionGeneral.height)
            });


          }
        }
        else{             
          this.limpiarVariables();
        }
      });
      console.log(this.state);
    }
    catch( errorP){
      console.log("Error ", errorP);
    }
  
    if(!infoBasicaGet && !infoExtendGet){
      alert("Sin información, Verifique que el UPC sea correcto");
    }
    else
    {
      if (this.state.UPCList.find(tag => tag.toLowerCase() === upcSearch.toLowerCase())) {
        console.log("Existe")
      }
      else{
        await this.setState({ UPCList: [upcSearch , ...this.state.UPCList] });
      }
    }

    //YUJU data
    this.getYujuData();

    // IMPORTANTE REVISAR
    this.setState({
      upc:upcSearch
    });

    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state));
  }


  updateDimensions = async (e) =>{
    let OldDimension = this.state.productDimensionsUnit;
    let newDimension = e.target.value;

    await this.setState({
      productWidht: this.convertUnits(OldDimension, newDimension, this.state.productWidht),
      productLenght: this.convertUnits(OldDimension, newDimension, this.state.productLenght),
      productHeight: this.convertUnits(OldDimension, newDimension, this.state.productHeight),

      packageWidht: this.convertUnits(OldDimension, newDimension, this.state.packageWidht),
      packageLenght: this.convertUnits(OldDimension, newDimension, this.state.packageLenght),
      packageHeight: this.convertUnits(OldDimension, newDimension, this.state.packageHeight),

      productDimensionsUnit: e.target.value,
      packageDimensionsUnit: e.target.value,
    })

    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 

    // this.handelInputChange(e);
  }

  updateWeight = async (e) =>{
    let OldDimension = this.state.productWeightUnit;
    let newDimension = e.target.value;

    await this.setState({
      productWeight: this.convertUnits(OldDimension, newDimension, this.state.productWeight),
      packageWeight: this.convertUnits(OldDimension, newDimension, this.state.packageWeight),

      productWeightUnit: e.target.value,
      packageWeightUnit: e.target.value
    });

    localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 

   // this.handelInputChange(e);
  }

  convertUnits(from, to, value){ 
    if(from === to){
      return value;
    }   
    return Number(convert(value).from(from.replace("gr","g")).to(to.replace("gr","g"))).toFixed(3);
  }

  validPageState(){
    switch(this.state.currentState){
      case 1:
        return (<BasicInfo handelInputChangeNumber={this.handelInputChangeNumber} createProductJson={this.createProductJson} handelInputChange={this.handelInputChange} handlePageChange={this.handlePageChange} state ={this.state}/>);
      case 2: 
        return (<ExtendInfoStep1 handelInputChangeNumber={this.handelInputChangeNumber} handelInputChange={this.handelInputChange} handlePageChange={this.handlePageChange} handlePageChangeBefore ={this.handlePageChangeBefore} state ={this.state}/>);
      case 3:
        return (<ExtendInfoStep5 updateWeight={this.updateWeight} updateDimensions={this.updateDimensions} handelInputChangeNumber={this.handelInputChangeNumber} handelInputChange={this.handelInputChange} handlePageChange={this.handlePageChange} handlePageChangeBefore ={this.handlePageChangeBefore} state ={this.state}/>);
      case 4: 
        return (<Testingselect  updateKeyWords={this.updateKeyWords} handelInputChange={this.handelInputChange} buttonFillData={this.buttonFillData} comboboxFill= { this.comboboxFill } addFeaures={this.addFeaures} handlePageChange={this.handlePageChange} handlePageChangeBefore ={this.handlePageChangeBefore} state ={this.state} saveVariation={this.saveVariation} comboboxUPCVariations ={this.comboboxUPCVariations} saveSelectedOptionType={this.saveSelectedOptionType}/>); 
      case 5:
        return (<ImageChoice changeVariationUPC={this.changeVariationUPC} updateImagesByUPC={this.updateImagesByUPC} updateImgVariationsLS={this.updateImgVariationsLS}  updateImgVariations={this.updateImgVariations} handelInputChangeNumber={this.handelInputChangeNumber} handelInputChange={this.handelInputChange} buttonFillData={this.buttonFillData} comboboxFill= { this.comboboxFill } addFeaures={this.addFeaures} handlePageChange={this.handlePageChange} handlePageChangeBefore ={this.handlePageChangeBefore} state ={this.state} saveVariation={this.saveVariation} comboboxUPCVariations ={this.comboboxUPCVariations}/>);    
      case 6:
        return(<EnviarInformacion updateExtended={this.updateExtended} handlePageChangeBefore={this.handlePageChangeBefore} faltantesLista ={this.state.faltantesLista}/>);     
      case 7:
        return(<Yuju testValidarDatosYuju = {this.testValidarDatosYuju} handelInputChangeNumber={this.handelInputChangeNumber} handelInputChange={this.handelInputChange} buttonFillData={this.buttonFillData} comboboxFill= { this.comboboxFill } addFeaures={this.addFeaures} handlePageChange={this.handlePageChange} handlePageChangeBefore ={this.handlePageChangeBefore} state ={this.state} saveVariation={this.saveVariation} comboboxUPCVariations ={this.comboboxUPCVariations}/>);     
      
      default:
        return <div>Error</div>
    }
  }

  CSV_Info = async(data) =>{
    await this.setState({
      CSV : data
    })
  }
  deleteAll= async(e) =>{
    if (e.target.value == "Delete"){
      e.target.value = "empty";
      await this.setState({
        CSV : []
      })
    }
    else{
      
      this.getInfoUPC2(e)
      var { CSV } = this.state;
      let newCSV = CSV.filter((x) => x != e.target.value )
      e.target.value = "empty";
			await this.setState({
				CSV : newCSV,
      });
      
    }

  }

  GuardarParaDespues= async ()=> {
    await this.setState({
      CSV : [...this.state.CSV, this.state.UPCList ],
    });
  }

  render(){
    
    return(
      <React.Fragment>
        <Header renderProposal = {() => this.renderShoppinCartList()} />
            <NavUL powers={ localStorage.getItem('level') }  newProposal = {() => this.newProposal()} site = "shooping" state = { this.state } />
            
            <div className="containerFormulario">

            <div className="HeaderFormularioDiv">
                <div className="SearchUpcDiv">
                  <label htmlFor="">Search UPC</label>
                  <div className = "">
                    <input onKeyPress={this.getInfoUPC} onChange={this.handelInputChange} value={this.state.upc} name='upc' className="filtro" id="filterProducts" type="text" placeholder="Filter here…" style={{width:"50%"}}></input>
                  </div>
                </div>
                <div className="TestDiv">
                  <Csv_Reader CSV_Info ={ this.CSV_Info }/>
                </div>
                <div className="ListaDiv">
                  <select name=""  onChange ={(e) => this.deleteAll(e)} >
                  <option value="empty"> </option>
                    <option value="Delete">Delete all</option>
                    { this.state.CSV.map((CVS_UPC , index) =>(
                      <option value={ CVS_UPC }>{CVS_UPC}</option>
                    ) )}

                  </select>
                </div>
              </div>

              <label htmlFor="">UPC Grouped</label>              
              <div className ="" style={{marginTop: "10px", marginBottom:"10px"} } onChange ={this.updateTextarea} >
                <div className="input-tag">
                  <ul className="input-tag__tags">
                    { this.state.UPCList.map((tag, i) => (
                      <li key={tag} >
                        <p onClick={() => {this.getBasicInfoExtended(tag)}}>{tag}</p>
                        <button type="button" onClick={() => { this.removeTag(i); }}>+</button>
                      </li>
                    ))}
                    <li className="input-tag__tags__input"><input type="text" onKeyDown={this.inputKeyDown} ref={c => { this.tagInput = c; }} /></li>
                  </ul>
                </div>
              </div>
              <CurrentPage  changePageButtons ={ this.changePageButtons }/>
              {this.validPageState()} 
              
            </div>                
      </React.Fragment> );
  }
 
}

export default login;
