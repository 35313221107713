import React, { Component }  from 'react';


import NavUL from "../navbarUL";
import Header from "../Header";

class extended_info extends Component {
    constructor( props ) {
        super( props );
    
        this.state = {
          variables: [],
          page : 1
        }
    }
    componentDidMount(){
        this.getData();
    }
    getData= async() =>{
        let data = "https://cato.skleo.com/api/v1/meta/completeProduct"
        
        await fetch(data )
                 .then(response => response.text())
                 .then(result => {
                     let res = JSON.parse(result)
                     this.setState({
                        variables : res
                     })
                    //  console.log("ESte Es: "res)
                 })
                 .catch(error => console.log('error', error));	
     }
     saveCurrentPage= async(index)=>{
         
        this.setState({
            page : index
        })
        
     }

     pageNumer(){
        //  console.log(this.state.variables)
        var myArr = []
         for(const prop in this.state.variables){
             
             myArr.push(prop)
            
         }
        //  console.log(myArr)
         return<React.Fragment>
             {myArr.map((name, index) => {
                 return<button className="btnUpdateImg" key={"PageButton"+index} onClick={(e) => this.saveCurrentPage(index)} >{index}</button>
             })}
         </React.Fragment>
     }

     componentReturn(field, index){
        switch (field.type) {
            case "text_input":
                //solo numeros
                return(                                
                    <React.Fragment key={"fracgment-"+index}>
                        <label htmlFor=""> {field.field.replace("_"," ").replace(/(?=[A-Z])/," ").toUpperCase().replace("_"," ")} </label>
                        <input type="number" name = {field.field} key={index} ></input>
                    </React.Fragment >
                )
            break;
            case "text":
                //
                return(                                
                    <React.Fragment key={"fracgment-"+index}>
                        <label htmlFor=""> {field.field.replace("_"," ").replace(/(?=[A-Z])/," ").toUpperCase().replace("_"," ")} </label>
                        <input type="text" name = {field.field} key={index}></input>
                    </React.Fragment >
                )
            break;
            case "text_field":
                // descripcion
                return(                                
                    <React.Fragment key={"fracgment-"+index}>
                        <label htmlFor=""> {field.field.replace("_"," ").replace(/(?=[A-Z])/," ").toUpperCase().replace("_"," ")} </label>
                        <textarea type="text" name = {field.field} key={index}></textarea>
                    </React.Fragment >
                )
            break;
            case "int":
                return(                                
                    <React.Fragment key={"fracgment-"+index}>
                        <label htmlFor=""> {field.field.replace("_"," ").replace(/(?=[A-Z])/," ").toUpperCase().replace("_"," ")} </label>
                        <input type="text" name = {field.field} key={index}></input>
                    </React.Fragment >
                )
            break;
            case "checkbox":
                if(field._default > 0){
                    return(                                
                        <React.Fragment key={"fracgment-"+index}>
                            <label htmlFor=""> {field.field.replace("_"," ").replace(/(?=[A-Z])/," ").toUpperCase().replace("_"," ")} </label>
                            <input type="checkbox" name = {field.field} key={index} checked></input>
                        </React.Fragment >
                    )
                }
                else{
                    return(                                
                        <React.Fragment key={"fracgment-"+index}>
                            <label htmlFor=""> {field.field.replace("_"," ").replace(/(?=[A-Z])/," ").toUpperCase().replace("_"," ")} </label>
                            <input type="checkbox" name = {field.field} key={index}></input>
                        </React.Fragment >
                    )
                }
                    
            break;
            case "date":
                return(                                
                    <React.Fragment key={"fracgment-"+index}>
                        <label htmlFor=""> {field.field.replace("_"," ").replace(/(?=[A-Z])/," ").toUpperCase().replace("_"," ")} </label>
                        <input type="text" name = {field.field} key={index}></input>
                    </React.Fragment >
                )
            break;
        }
     }


     inputs(par){
        var myArr = []
         for(const prop in this.state.variables){             
             myArr.push(prop)
         }
        var ComponentsInArray = []
        var ArrayTorender = []
        ComponentsInArray = this.state.variables[myArr[this.state.page]]
        for(var prop in ComponentsInArray){
            ArrayTorender.push(ComponentsInArray[prop])  
        }
        
        
        return <React.Fragment>
            {ArrayTorender.map((field, index) => {
                    // console.log(field.field, index)
                    if((index+1)%2 == 0){
                        if(par){
                            return(this.componentReturn(field, index));                            
                        }
                    }else{
                        if(!par){
                            return(this.componentReturn(field, index));  
                        }
                    }
                }  
            )}
            </React.Fragment>
        
    }

    render()
    {
        return(        
        <React.Fragment>
            <Header renderProposal = {() => this.renderShoppinCartList()} />
            <NavUL powers={ localStorage.getItem('level') }  newProposal = {() => this.newProposal()} site = "shooping" state = { this.state } />
            <div className="containerFormulario">
                <div className ="Botones-Pagina">
                    {this.pageNumer()}
                </div>
                <div className="formExt">
                    <div className="filtroData1">
                        { this.inputs(true) }
                    </div>
                    <div className="filtroData4">
                        { this.inputs(false) }
                    </div>
                </div>                            
            </div>
        </React.Fragment>
            )

    }
}
export default  extended_info;