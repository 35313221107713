import React, { Component } from 'react'
import { CSVReader } from 'react-papaparse'
export default class Csv_Reader extends Component {
  handleOnDrop = (data) => {
    let numArray
    let arrayCSV = []
    for( let i = 0; i < data[0].data.length ; i++ ){
      if(data[0].data[i].toUpperCase() == "UPC"){
        numArray = i
      }
    }
    console.log(data[0].data[numArray] )
    for( let i = 1; i < data.length ; i++ )
    {
          if (data[i].data[numArray] !== "" ){
            if (arrayCSV.find(item => item.toLowerCase() === data[i].data[numArray].toLowerCase())) {
              this.props.fillUpcError("REPETIDO: " + data[i].data[numArray])
            }
            else{
              arrayCSV.push(data[i].data[numArray])
            }
            
          }
    }
     this.props.CSV_Info(arrayCSV)
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  handleOnRemoveFile = (data) => {

    console.log(data)
 
  }
  render() {
    return (
      <CSVReader
        onDrop={this.handleOnDrop}
        onError={this.handleOnError}
        addRemoveButton
        noProgressBar 
        onRemoveFile={this.handleOnRemoveFile}
        style={{
            dropArea: {
              borderStyle: 'dashed',
              borderColor: '#ccc',
              borderRadius: 20,
              borderWidth: 2,
              backgroundColor: '#FFFFFF',
              padding: '10px',
              height: "50px",
              dropFile: {
                width: 100,
                height: 40,
                background: '#ccc',
                fileSizeInfo: {
                    display: "none",
                  color: '#cccccc',
                  backgroundColor: '#cccccc',
                  borderRadius: 3,
                  lineHeight: 1,
                  marginBottom: '0.5em',
                //   padding: '0 0.4em',
                  padding: '10px',
                },
                fileNameInfo: {
                  color: '#fff',
                  backgroundColor: '#cccccc',
                  borderRadius: 3,
                  fontSize: 14,
                  lineHeight: 1,
                  padding: '0 0.4em',
                },
                removeButton: {
                  color: 'red',
                },
                progressBar: {
                  backgroundColor: 'pink'
                },
              },
            },
          }}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
    )
  }
}