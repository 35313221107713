import React, { Component } from "react";
import { Route } from 'react-router-dom';
import axios from "axios";
import NavUL from "../navbarUL";
import Header from "../Header";
import UPCSearch from './Components/UPCBuscador'
import { Checkbox } from "material-ui";
import {Redirect} from 'react-router-dom';
import { CommunicationMessage } from "material-ui/svg-icons";
import pages from "material-ui/svg-icons/social/pages";
import convert from "convert-units";
import message from "material-ui/svg-icons/communication/message";



const domain = "https://cato.skleo.com"
var intervalID;
class main_marketplace extends Component {
    
    constructor(props) {
        super(props);
        this.state = {      
            upc: "999999999999", 
            ProductsGeneral : [],
            productsYuju : [],
            currentPage : 1,
            Renderintems : 10,   
            BotonesMap : [],         
            allcheck : false,
            UPCList: [],
            currentStorePK : 0 ,
            currentMarketPK : 0 ,
            stores : [],
            marketplaces : [],
            upcToMarket:[],
            product: [],
            ExtendedInfo : [],
            StockDataInfo:[],
            stockChange : [],
            ListaMarketPlace : [],
            serverResponce : '',
            checkStatus : [],
            UPCERROR:[]
        }
    }

    fillUpcError= async(value) =>{
        if (this.state.UPCERROR.find(tag => tag.toLowerCase() === value.toLowerCase())) {
            return;
        }
        this.state.UPCERROR.push(value);
        console.log("ERRORES")
        console.log(this.state.UPCERROR)
    }

    componentDidMount(){
        this.getStore()
       // this.getInfoProducto()
    }

    componentWillUnmount() {
        clearInterval(intervalID);
    }

    getStore= async() =>{
       let GetStores = "https://cato.skleo.com/api/v1/yuju/getStore"
       
       await fetch(GetStores )
				.then(response => response.text())
				.then(result => {
                    let res = JSON.parse(result)
                    this.setState({
                        stores : res
                    })
					//console.log(res)
				})
				.catch(error => console.log('error', error));	
    }

    getInfoProducto =async () =>{
        let getInfoProductoAPI = "https://cato.skleo.com/api/v1/yuju/getInfoProduct"

        await fetch(getInfoProductoAPI )
        .then(response => response.text())
        .then(result => {
            let res = JSON.parse(result)
            this.setState({
                productsYuju : res
            })
            //console.log(res)
        })
        .catch(error => console.log('error', error));	
    }

    fillStores(){
        return <React.Fragment>
            {this.state.stores.map((store, index) => {
                    //console.log(store)
                    return <option key={index+"Option"} value={store.pk} >{store.name}</option>
                }  
            )}
            </React.Fragment>
        
    }

    secondStore(e){
        //console.log(e.target.value)
        for( let i = 0; i < this.state.stores.length; i++ ){
            if(this.state.stores[i].pk == e.target.value)
            {
                this.setState({
                    marketplaces : this.state.stores[i].marketplaces,
                    currentStorePK :  this.state.stores[i].pk
                })
            }
            else if (e.target.value == 0){
                this.setState({
                    marketplaces : []
                })
            }
        }
        //console.log(this.state.currentStore)
    }

    fillmarketplaces(){
        return <React.Fragment>
            {this.state.marketplaces.map((marketplace, index) => {
                   // console.log(marketplace)
                    return <option key={index + "Option2"} value={marketplace.pk}>{marketplace.name}</option>
                }  
            )}
            </React.Fragment>
        
    }

    saveSecondStore(e){
        //console.log(e.target.value)
        
                this.setState({
                    
                    currentMarketPK :  e.target.value
                })

    }

    getCookie = function( name ) {
        var r = document.cookie.match( "\\b" + name + "=([^;]*)\\b" );
        return r ? r[ 1 ] : null;
    };

    cookieCheck = async () => {
        var token = this.getCookie( 'token' );
        if ( token == null ) { return  <Redirect  to="/" /> }
    };

    fillData= async (info , infoExtenden, StockData) =>{
        let ProductExist = false;
        for ( let i = 0; i < this.state.ExtendedInfo.length; i++ ){
            //console.log("Products UPC: ", this.state.ExtendedInfo[i].upc , infoExtenden.upc)
            if (this.state.ExtendedInfo[i].upc == infoExtenden.upc){
                ProductExist = true;
            }            
        }
        if (ProductExist === false){
            let Am;
            let Ml;
            let Mx;
            let Amchx;
            let Mlchx;
                    if(StockData[0]["werehouses"][0]["success"]){
                        Am = StockData[0]["werehouses"][0]["stock"];
                    }
                    else{
                        Am = "Falso";
                    }
                    if(StockData[0]["werehouses"][1]["success"]){
                        Ml = StockData[0]["werehouses"][1]["stock"];
                    }
                    else{
                        Ml = "Falso";
                    }
                    if(StockData[0]["werehouses"][2]["success"]){
                        Mx = StockData[0]["werehouses"][2]["stock"];
                    }
                    else{
                        Mx = "Falso";
                    }
                    if(StockData[0]["werehouses"][0]["transit"] > 0){
                        Amchx = true;
                    }
                    else{
                        Amchx = false;
                    }
                    if(StockData[0]["werehouses"][1]["transit"] > 0){
                        Mlchx = true;
                    }
                    else{
                        Mlchx = false;
                    }
                await this.setState({
                    product : [...this.state.product, info] ,
                    ExtendedInfo : [...this.state.ExtendedInfo, infoExtenden] ,
                    StockDataInfo : [...this.state.StockDataInfo, StockData]
                })
               //console.log(infoExtenden , info)
            var values ={ 
                upc : info.upc,
                sku : info.itemNumber,
                images : infoExtenden.images,                
                description_es : infoExtenden.description_es,
                amazonStock : Am,
                mlStock : Ml,
                mxStock : Mx, 
                chx_amazon: Amchx,
                chx_ml : Mlchx,
                status:"Working",
                message:"",
                item_pk : StockData[0]["item_pk"],
                parent_pk: StockData[0]["parent_pk"]     
            }
            
            await this.setState({
                ProductsGeneral : [...this.state.ProductsGeneral , values]
            })
        }
        //console.log(StockData);
    }

    handelInputChange = async (event) =>{
        event.persist();        
        let namevar = event.target.name;
        let valuevar = event.target.value;
        await this.setState( {
          [namevar] : (namevar = valuevar)
        });
    };

    producto= () =>{
        //console.log(this.state.product)
        try{
        	return(
                <React.Fragment>
                    { this.state.product[0].map((producto, index) =>{
                        return(
                            <p key={index+"P"}>{producto.description}</p>
                            )
                    })}
                </React.Fragment>
            )
        }
        catch{
            return(<div>Error</div>)
        }
    }

    AddToList= (e) => {
        //console.log(this.state.ListaMarketPlace)
        let val = this.state.ListaMarketPlace.indexOf(e) >= 0
        //console.log(val)
        if (val == false){
            this.setState({
                ListaMarketPlace :[e, ...this.state.ListaMarketPlace]
            })
           // console.log(e)
        }
        
    }

    extendClass(){
        return(
            <React.Fragment>
                <div className='TarjetaMarketPlace'>
               
                {this.state.ExtendedInfo.map((ExtendedInfo, index) => 
                    <React.Fragment key={index+"Fragment"}>
                    <div className='TarjetaMarketPlace-img' ><img src= {ExtendedInfo.images[0]} height="100px"></img></div>
                    <div className='contenido'>
                        <p>Name: {this.state.product[index].description}</p>
                        <p>Brand: {this.state.product[index].brand}</p>
                        <p>Class: {this.state.product[index].class}</p>
                        <p>Color: {this.state.product[index].color}</p>
                        <button onClick={() =>  this.AddToList(this.state.product[index].upc)}>Add</button>   
                    </div>
                    </React.Fragment>
                )}
                </div>
            </React.Fragment>
        )        
    }

    returnValues(){
        switch(this.state.ExtendedInfo.length){
            case 0:
                return (<div>Contains {this.state.ExtendedInfo.length} </div>);
            default:
                return this.extendClass()
        }
    }
    renderTableHeader() {
        try{
        let header = Object.keys(this.state.ExtendedInfo[0])
        let product= ['ID','UPC','SKU','IMAGE','DESCRIPTION', 'STOCK MX', "STOCK AMAZON", "STOCK ML", "FBA", "FBML", "status", "comment"]
        return product.map((key, index) => {
            {if ( key == "SELECT"){
                return <th key={index+"th1"}> <p style={{fontSize : "10px"}}>   {key.toUpperCase()} <input type="checkbox" onChange= {(e) => this.handleChangeAllCheck(e)}  ></input></p>  </th>
            }
            else{
                return <th key={index+"th2"}> <p style={{fontSize : "10px"}}> {key.toUpperCase()} </p> </th>
            }
        }
            
        })
        }
        catch{

        }
    }

    renderTableHeaderErrors() {
        try{
        let header = Object.keys(this.state.ExtendedInfo[0])
        let product= ['Error']
        return product.map((key, index) => {
            return <th key={index+"th2"}>{key.toUpperCase()}</th>            
        })
        }
        catch{

        }
    }
     handleChangeAllCheck = async (e) =>{

        if (e.target.checked){
            //console.log("add")
            await this.setState({
                allcheck : true
            })
            for( let i = 0; i < this.state.ProductsGeneral.length; i++ ){
                
                    this.state.upcToMarket.push(this.state.ProductsGeneral[i].upc) 
                
            }
            
            
        }
        else if (e.target.checked === false)
        {
				await this.setState({
					
					allcheck : false					
                });  
                await this.setState({					
					upcToMarket: []					
				});          
        }
        console.log("ToMarketPlace: ", this.state.upcToMarket)
     }
     borrarProducto= async(upc)=>{
        {
            const copiedItems = [...this.state.ProductsGeneral];
            const arr = copiedItems.filter(e => e.upc !== upc);
				await this.setState({
					ProductsGeneral: arr					
				});           
        }
     }
     handleChangeAmazon = async (e,upc) =>{
        if (e.target.checked){
            const copiedItems = [...this.state.ProductsGeneral];
            for( let i = 0; i < this.state.ProductsGeneral.length; i++ ){
                if(upc == this.state.ProductsGeneral[i].upc){
                    copiedItems[i].chx_amazon = true;
                }
            }
				await this.setState({
					ProductsGeneral: copiedItems					
				});    
        }
        else if (e.target.checked === false)
        {
            const copiedItems = [...this.state.ProductsGeneral];
            for( let i = 0; i < this.state.ProductsGeneral.length; i++ ){
                if(upc == this.state.ProductsGeneral[i].upc){
                    copiedItems[i].chx_amazon = false;
                }
            }
				await this.setState({
					ProductsGeneral: copiedItems					
				});            
        }
        console.log("Borrado: ", this.state.upcToMarket)
     }
     handleChangeMl = async (e,upc) =>{
        if (e.target.checked){
            const copiedItems = [...this.state.ProductsGeneral];
            for( let i = 0; i < this.state.ProductsGeneral.length; i++ ){
                if(upc == this.state.ProductsGeneral[i].upc){
                    copiedItems[i].chx_ml = true;
                }
            }
				await this.setState({
					ProductsGeneral: copiedItems					
				});    
        }
        else if (e.target.checked === false)
        {
            const copiedItems = [...this.state.ProductsGeneral];
            for( let i = 0; i < this.state.ProductsGeneral.length; i++ ){
                if(upc == this.state.ProductsGeneral[i].upc){
                    copiedItems[i].chx_ml = false;
                }
            }
				await this.setState({
					ProductsGeneral: copiedItems					
				});            
        }
        console.log("Borrado: ", this.state.upcToMarket)
     }
     SenInfo=async()=> {
         let productsFinal  =  [];
         let productsArray = [];
         let pkSend = 0
         //let url = "https://cato.skleo.com/api/v1/yuju/exportProducts"
         let url = "https://cato.skleo.com/api/v1/yuju/exportVariatiosv002"
        // console.log(this.state.currentStorePK, this.state.currentMarketPK)
         if ( this.state.currentStorePK != 0 ){
            pkSend = this.state.currentStorePK
         } 
         if (this.state.currentMarketPK != 0 ){
            pkSend = this.state.currentMarketPK
         }
        // console.log(pkSend)
        let productCopy = this.state.ProductsGeneral;
         if (pkSend !== 0){
            for(let x = 0; x < this.state.upcToMarket.length; x++){
                for( let i = 0; i < this.state.ProductsGeneral.length; i++ ){
                   // console.log(this.state.ProductsGeneral);
                    if (this.state.ProductsGeneral[i].upc === this.state.upcToMarket[x]){
                        if(this.state.ProductsGeneral[i].sku != null && this.state.ProductsGeneral[i].sku != "" && this.state.ProductsGeneral[i].sku_simple != null && this.state.ProductsGeneral[i].sku_simple != ""){
                            productsArray.push({
                                upc : this.state.ProductsGeneral[i].upc,
                                shop_pk: pkSend,
                                sku:this.state.ProductsGeneral[i].sku,
                                sku_simple:this.state.ProductsGeneral[i].sku_simple
                            })
                           // console.log(this.state.ProductsGeneral[i].sku, this.state.ProductsGeneral[i].sku_simple)
                            productCopy[i].status = "Working";
                        }
                        else{
                            productCopy[i].status = "Fail"
                            productCopy[i].message = "No SKU SKU_SIMPLE"
                        }
                    }
                }
             }
             
             await this.setState({
                 ProductsGeneral:productCopy
             })

             productsFinal= ({
                products : productsArray
             })
             
             let json = JSON.stringify(productsFinal)

            // console.log( json )
            try {
                await axios
                            .post(url, json)
                            .then(res => {            
                                //console.log("result ", res);
                                if (res.data[0].status === "send" ){
                                    this.setState({ serverResponce : res.data[0].message }) 
                                    alert("Envio de Informacion");                                   
                                }
                                else
                                {
                                    
                                    for(let i=0 ; i < res.data[0].message.length ; i++){ 

                                        for(let i=0 ; i < res.data[0].message.length ; i++){
                                            if (res.data[0].message[i].error[0].length != 0){ 
                                                    let text = "";
                                                    
                                                    for(let x =0;x < res.data[0].message[i].error[0].length; x++ ){
                                                        console.log(res.data[0].message[i].error[0][x])
                                                        text = text + "\n" + res.data[0].message[i].error[0][x];
                                                    }
                                                    alert("Error En evio de informacion del producto: " + res.data[0].message[i].upc + text);                                                
                                            }                                        
                                            
                                        }                                    
                                    }
                                }    
                                
                            });
              } catch (errorPost) {
                alert("Error En evio de informacion");
              }

                            
         }
         else{
             alert("Selecciona una tienda");
         }

     }
     handleChangeValue= async(e, upc)=>{
        let productSelect
        for( let i = 0; i < this.state.product.length; i++ ){
            if (this.state.product[i].upc === upc){
                productSelect = this.state.product
                productSelect[i].mx_salable = e.target.value
            }
        }
        
        //console.log("this Product: ", productSelect)
        await this.setState({
            product : productSelect
        })
     }
     isAllCheckTrue(upc){
        
         let existe = false;

         if (this.state.allcheck){ 
                       
            
            for(let i = 0; i < this.state.upcToMarket.length ; i++){
                if (this.state.upcToMarket[i] === upc ){
                    existe = true
                }
            }
            if (!existe){
                //  this.setState({
                //     upcToMarket : [upc , ...this.state.upcToMarket]
                // })
                
            }
             
            return true;
         }
         else{
            for(let i = 0; i < this.state.upcToMarket.length ; i++){
                if (this.state.upcToMarket[i] === upc ){
                    existe = true
                }
            }
            return existe;
         }
     }

repitConsult= async()=>{
intervalID = setInterval(() => {
    this.consultStatus()
    }, 120000);

    //this.intervalID = setTimeout(this.consultStatus() , 10000);         
}

consultStatus_Button= async()=>{
this.consultStatus();
}

putLast= async()=>{
await this.setState({serverResponce : 'f_2L_zsHzaI3zjzB0XeX8zokHQDf122a'})
    this.consultStatus()
}

consultStatus= async()=>{
let url  = domain + "/api/v1/yuju/getFeeds?pk=" + this.state.serverResponce
// url = "https://cato.skleo.com/api/v1/yuju/getFeeds?pk=UJeA_5w5j_9YcqsoKQePblx90w3ZcAAs"
try {
    await axios
                .post(url)
                .then(res => {
                    this.setState({ checkStatus : res.data }) 
                    
                });
    } catch (errorPost) {
    //console.log(errorPost);
    }
    
    //console.log(this.state.checkStatus)
    let Allproducts = []
    let AllPk = []
    if (this.state.checkStatus.errors.length > 0){
        for(let i=0 ; i < this.state.checkStatus.errors[0].length ; i++){
            for(let k=0 ; k < this.state.ProductsGeneral.length ; k++){                     
            if (this.state.ProductsGeneral[k].sku_simple === this.state.checkStatus.errors[0][i].sku_simple){
                Allproducts = this.state.ProductsGeneral
                Allproducts[k].message = this.state.checkStatus.errors[0][i].message[0]
                Allproducts[k].status = this.state.checkStatus.status
            }
            }
        }        
    }
    if (this.state.checkStatus.success.length > 0){
        for(let i=0 ; i < this.state.checkStatus.success[0].length ; i++){
            for(let k=0 ; k < this.state.ProductsGeneral.length ; k++){                        
                if (this.state.ProductsGeneral[k].sku_simple === this.state.checkStatus.success[0][i].sku_simple){
                    Allproducts = this.state.ProductsGeneral
                    Allproducts[k].message = this.state.checkStatus.success[0][i].message
                    //console.log(this.state.checkStatus.status)
                    if(this.state.checkStatus.status === 'Wait'){
                      Allproducts[k].status = this.state.checkStatus.status
                    }
                    else if (this.state.checkStatus.status === 'Complete' || this.state.checkStatus.status === 'Fail'){
                      AllPk.push({
                          "pk":this.state.checkStatus.success[0][i].pk ,"shop_pk": this.state.currentStorePK ,
                        })
                      Allproducts[k].status = "Complete"
                    }                          
                }
            }
        }
        
        }
    
    if (Allproducts.length !== 0){
    await this.setState({
        ProductsGeneral : Allproducts
        })
    }
    if(AllPk.length != 0){
        let getInfoProduct = "https://cato.skleo.com/api/v1/yuju/getInfoProduct"
        let productsFinal = []
        productsFinal= ({
        products : AllPk
        })
        let json = JSON.stringify(productsFinal)
        try {
        await axios
                    .post(getInfoProduct, json)
                    .then(res => {
                        //console.log( res.data )
                    });
        } catch (errorPost) {
        //console.log(errorPost);
        }
    }
    //console.log(AllPk)
//   if(this.state.checkStatus.status ==="Fail" || this.state.checkStatus.status ==="Complete" ){
//       if(intervalID != null){
//         clearInterval(intervalID);
//       }    
//   }
}

renderTableData= () => {    
    return <tbody>
        <tr>{this.renderTableHeader()}</tr>
            {this.state.ProductsGeneral.map((info, index) => {
            const { description_es , images, sku, upc, amazonStock, mlStock, mxStock, chx_amazon, chx_ml, status, message } = info //destructuring
            this.PageRenderItems()
                if ( index >= (( this.state.Renderintems * this.state.currentPage ) - this.state.Renderintems )  &&  index < ( this.state.Renderintems * this.state.currentPage ) ){
                    return (
                        <tr key={index + 1 + "tr1"}>
                            
                            <td><p style={{fontSize : "10px"}}>{index + 1 }</p></td>                        
                            {/* <td> <input type="checkbox" onChange={ (e) => this.handleChange(e , upc) } checked={ this.isAllCheckTrue(upc) } ></input></td> */}
                            <td><p style={{fontSize : "10px"}}>{upc}</p></td>
                            <td><p style={{fontSize : "10px"}}>{sku}</p></td>
                            <tb><div><img src={images[0]} height='45px' className='imageTable' ></img></div></tb>
                            <td width="30px"><p style={{fontSize : "10px"}}>{description_es}</p></td>
                            <td><p style={{fontSize : "10px"}}>{mxStock}</p></td>
                            <td><p style={{fontSize : "10px"}}>{amazonStock}</p></td>
                            <td><p style={{fontSize : "10px"}}>{mlStock}</p></td>       
                            <td> <input type="checkbox" onChange={ (e) => this.handleChangeAmazon(e , upc) } checked={ chx_amazon } ></input></td>
                            <td> <input type="checkbox" onChange={ (e) => this.handleChangeMl(e , upc) } checked={ chx_ml } ></input></td>  
                            <td><p style={{fontSize : "10px"}}>{status}</p></td>
                            <td><p style={{fontSize : "10px"}}>{message}</p></td>
                        </tr>
                    )
                }
            
            })}
    </tbody>    
}

renderTableDataErrors= () => {    
    return <tbody>
        <tr>{this.renderTableHeaderErrors()}</tr>
            {this.state.UPCERROR.map((info, index) => {
                if ( index >= (( this.state.Renderintems * this.state.currentPage ) - this.state.Renderintems )  &&  index < ( this.state.Renderintems * this.state.currentPage ) ){
                    return (
                        <tr key={index + 1 + "tr1"}>
                            <td><p>{info}</p></td>
                        </tr>
                    )
                }
            
            })}
    </tbody>    
}

tableValues(){
    return (
        <div>
            <h1 id='title'>Products</h1>
            <table id='students'>
                {this.renderTableData()}
            </table>
            
        </div>
        )
}

tableErrors(){
    return (
        <div>
            <h1 id='title'>Errores</h1>
            <table id='students'>
                {this.renderTableDataErrors()}
            </table>
            
        </div>
        )
}

returEmpyValue(product){
    //console.log(product)
    for( let i = 0; i < product.length; i++ ){
        let value = product[i]
        if ( value === "" || value ===null){
            //console.log("No tiene valor: " + value)
        }
    }
}
PageRenderItems = async() =>{
   let Pages = this.state.ProductsGeneral.length / this.state.Renderintems;
   this.state.BotonesMap = []
   if (!Number.isInteger(Pages)){
        Pages = parseInt(( Pages + 1 ))
   }
   for(let z =1; z < Pages + 1 ; z++){
        this.state.BotonesMap.push(z);
   }    
}
CreateButtons(){
    return<div className="ButtonDiv" style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }} >
            {this.state.BotonesMap.map( ( val , index) => { 
                return (
                <button className="btnForm btnNext"  style={{margin:"10px"}} onClick={ () =>  this.forceUpdateCall(val) }>{val}</button>
                )

             } )}
        </div>
    
}

forceUpdateCall(val){
    this.state.currentPage = val
    this.forceUpdate();
}
testPrint = async() => {
    //console.log("Test")
    let productsFinal  =  [];
    let productsArray = [];
    let pkSend = 0
    let url = "https://cato.skleo.com/api/v1/yuju/exportProducts"
    // console.log(this.state.currentStorePK, this.state.currentMarketPK)
    if ( this.state.currentStorePK != 0 ){
        pkSend = this.state.currentStorePK
    } 
    if (this.state.currentMarketPK != 0 ){
        pkSend = this.state.currentMarketPK
    }
    // console.log(pkSend)
    if (pkSend !== 0){
        for(let x = 0; x < this.state.upcToMarket.length; x++){
            for( let i = 0; i < this.state.product.length; i++ ){
                if (this.state.product[i].upc === this.state.upcToMarket[x]){
                    this.returEmpyValue(this.state.product[i])
                    productsArray.push({
                        upc : this.state.product[i].upc,
                        shop_pk: pkSend,
                        stock:this.state.product[i].stock,
                    })
                }
            }
        }
        productsFinal= ({
            products : productsArray
        })
        
        let json = JSON.stringify(productsFinal)

        //console.log( json )
}
}

downloadErrors = () => {
    const texto = "ERRORES\n" + this.state.UPCERROR.join("\n")
    const element = document.createElement("a");
    const file = new Blob([texto], {type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
    element.download = "Errores en Upc.txt";
    document.body.appendChild(element);
    element.click();
  }

  handelInputChange = async (event) =>{
    event.persist();
    const { currentState } = this.state;
    let namevar = event.target.name;
    let valuevar = event.target.value;
    await this.setState( {
      [namevar] : (namevar = valuevar)
    });
    this.forceUpdate();
    // localStorage.setItem('myArrayExtendedInfo', JSON.stringify(this.state)); 
  };

  handelInputChangeNumber = (evt) => {
    let valuevar = evt.target.value;
    console.log("evt.charCode FORMULARIO.JSX " , evt.charCode);
    if((evt.charCode>= 48 && evt.charCode <= 57) || evt.charCode === 46 ||evt.charCode === 0){
      if(valuevar.indexOf('.') > -1){
        if(evt.charCode === 46)
          evt.preventDefault();
      }
    }else
      evt.preventDefault();
  };

  SincronizarFunction = async()=>{
      let url = "https://cato.skleo.com/api/v1/yuju/fbmTraffic";
      let jsonSync = {products:[]}
    for( let i = 0; i < this.state.ProductsGeneral.length; i++ ){
        let MLS;
        let AZS;
        if(this.state.ProductsGeneral[i].mlStock == "Falso"){
            MLS = 0;
        }
        else{
            MLS = this.state.ProductsGeneral[i].mlStock;
        }
        if(this.state.ProductsGeneral[i].amazonStock == "Falso"){
            AZS = 0
        }
        else{
            AZS = this.state.ProductsGeneral[i].amazonStock;
        }
        let itemSync = {
            upc:this.state.ProductsGeneral[i].upc,
            stock_amazon:AZS,
            stock_ml:MLS,
            amazon:this.state.ProductsGeneral[i].chx_amazon,
            ml:this.state.ProductsGeneral[i].chx_ml
        }
        jsonSync.products.push(itemSync);
    }
    console.log(jsonSync);
    try {
        await axios
        .post(url, jsonSync)
        .then(res => {
            console.log(res);
            if(res["data"]["status"] === "send"){
                this.setState({
                    serverResponce:res["data"]["message"]
                })
                alert("Sincronizado");
            }            
        });
    } catch (errorPost) {
        alert("Error en la sincronizacion");
        this.setState({
            serverResponce : ""                        
        })
    }
  };

  SincronizarConsulta = async()=>{
      if(this.state.serverResponce !== ""){
        let url = "https://cato.skleo.com/api/v1/yuju/getFeeds_v002?pk=" + this.state.serverResponce;
        let copy_general = this.state.ProductsGeneral;
        try {
            await axios
            .post(url)
            .then(res => {
                console.log(res);
                console.log(res["data"]["success"][0][0]);
                if(res["data"]["success"][0].length > 0){
                    for(let a = 0; a < res["data"]["success"][0].length; a++){
                        for(let i = 0; i < this.state.ProductsGeneral.length; i++){      
                            console.log(this.state.ProductsGeneral[i]["item_pk"], res["data"]["success"][0][a]["item_pk"]);                  
                            if(this.state.ProductsGeneral[i]["item_pk"].toString() === res["data"]["success"][0][a]["item_pk"].toString()){                            
                                copy_general[i]["message"] = res["data"]["success"][0][a]["message"];
                                copy_general[i]["status"] = res["data"]["status"];
                            }
                        }        
                    }
                    console.log("Copy general", copy_general);
                    this.setState({
                        ProductsGeneral : copy_general                        
                    })                    
                }            
            });
        } catch (errorPost) {
            alert("Error en la sincronizacion");
        }        
        console.log(url);
      }    
  }

render(){

return(
    <React.Fragment>
    <Header renderProposal = {() => this.renderShoppinCartList()} />
        <NavUL powers={ localStorage.getItem('level') }  newProposal = {() => this.newProposal()} site = "shooping" state = { this.state } />
        
        <div className="containerFormulario">
            
            <UPCSearch getCookie={this.getCookie} domain={domain} handelInputChange={this.handelInputChange} currentUPC={this.state.upc} fillUpcError={this.fillUpcError} fillData={this.fillData} />
            
            {/* <div className="CardMarketPlacePreview">
                <img src={this.state.ExtendedInfo.images[0]}></img>
            </div> */}
            {/* {this.returnValues()} */}
            <div className="DivH">{this.tableValues()}</div>
            <div className="ButtonDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <input style={{width:"200px"}}  onKeyPress={this.handelInputChangeNumber} name="Renderintems" onChange={this.handelInputChange} value={this.state.Renderintems} type="number"  min="1"></input>
            </div>
            {this.CreateButtons()} 
            <div className="ButtonDiv" style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                <button className="btnForm btnNext" onClick={() => this.SincronizarFunction()} style={{width:"200px", margin:"10px", textAlign: 'center', alignItems: "center"}} >Sincronizar</button>
                <button className="btnForm btnNext" onClick={() => this.SincronizarConsulta()} style={{width:"200px", margin:"10px", textAlign: 'center', alignItems: "center"}} >Consulta</button>
            </div>
        </div>
        
                        
    </React.Fragment> );
}
 
}
export default main_marketplace;
		