import React, { Component } from "react";
import uuid from "uuid/v4";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import crown from './crown.svg'

const domain = "https://cato.skleo.com"


let typeImg = "";

class DragAndDrop extends Component {

    constructor(props) {
		super(props);
		
		this.state = {
			currentFrame : 0,
			files: [],
			filesDeleteFromDB : [],
			urls: [],
			isDragging: false,
			responseImages :[],
			
			filesLS : [],
			urlsLS: [],
			isDraggingLS : false,
			responseImagesLS :[],

			itemsFromBackend:[],

			columnsFromBackend : [{
				ObjectGroup: {
				  name: "Images",
				  items: []
				}
			  }],

			movioImagen:false
			
		}
	}
	onDragEnd = async(result, columns) => {
		if (!result.destination) return;
		const { source, destination } = result;
	  
		if (source.droppableId !== destination.droppableId) {
		  const sourceColumn = columns[source.droppableId];
		  const destColumn = columns[destination.droppableId];
		  const sourceItems = [...sourceColumn.items];
		  const destItems = [...destColumn.items];
		  const [removed] = sourceItems.splice(source.index, 1);
		  destItems.splice(destination.index, 0, removed);
		  //console.log(destItems)
	
		} else {
		  const column = columns[source.droppableId];
		  const copiedItems = [...column.items];
		  const [removed] = copiedItems.splice(source.index, 1);
		  copiedItems.splice(destination.index, 0, removed);
		 // console.log(copiedItems)
		  await this.setState({
			columnsFromBackend :[{
				ObjectGroup: {
					name: "Images",
					items: copiedItems
				}
			}]
		  })


		await this.setState({
			  movioImagen:true
		  });

		}
		//console.log(this.state.columnsFromBackend)
	  };
	onRemove= async (index) =>{

		if(this.state.columnsFromBackend[0].ObjectGroup.items[index].indentificador === 'DB'){
			await this.setState({
				filesDeleteFromDB: [...this.state.filesDeleteFromDB, this.state.columnsFromBackend[0].ObjectGroup.items[index].file.name ]
			})
			var { columnsFromBackend } = this.state;
			let newcolumnsFromBackend = columnsFromBackend[0].ObjectGroup.items.filter((colum , x) => x !== index)
			await this.setState({
				...this.state,
				itemsFromBackend : newcolumnsFromBackend,
				columnsFromBackend :[{
					ObjectGroup: {
					name: "Images",
					items: newcolumnsFromBackend
					}
				}]
			});
			return;
		}
		try {
			for (let i = 0; i < this.state.files.length; i++) {
			if(this.state.columnsFromBackend[0].ObjectGroup.items[index].file.name === this.state.files[i].name){
				//console.log("file ", this.state.files[i].name, i, " Column ", this.state.columnsFromBackend[0].ObjectGroup.items[index].name, index)
				
				var {files, urls , columnsFromBackend } = this.state;
				let newcolumnsFromBackend = columnsFromBackend[0].ObjectGroup.items.filter((colum , x) => x !== index)
				let newFiles = files.filter((file, x) => x !== i);
				let newUrls = urls.filter((url, x) => x !== i);		
				await this.setState({
					...this.state,
					files: newFiles,
					urls: newUrls,
					itemsFromBackend : newcolumnsFromBackend,
					columnsFromBackend :[{
						ObjectGroup: {
						name: "Images",
						items: newcolumnsFromBackend
						}
					}]
				});
			}
		}
			
			
		}
		catch{
			//console.log('Error')
		}

		//console.log(this.state.columnsFromBackend[0].ObjectGroup.items)
		/*
		var {files, urls , columnsFromBackend } = this.state;
		let newcolumnsFromBackend = columnsFromBackend[0].ObjectGroup.items.filter((colum , i) => i !== index)
		let newFiles = files.filter((file, i) => i !== index);
		let newUrls = urls.filter((url, i) => i !== index);		
		await this.setState({
			...this.state,
			files: newFiles,
			urls: newUrls,
			columnsFromBackend :[{
				ObjectGroup: {
				  name: "Images",
				  items: newcolumnsFromBackend
				}
			  }]
		});*/
	}
	
	handleDrags(e) {
		//console.log("handleDrags ", e.target.getAttribute('name'))
		e.preventDefault();
		e.stopPropagation();		

		if( e.target.getAttribute('name') === "LS"){
			this.setState({
				...this.state,
				isDraggingLS: true
			});
			typeImg="LS";
		}
		else if (e.target.getAttribute('name') === "IM"){
			this.setState({
				...this.state,
				isDragging: true
			});
			typeImg="IM";
		}
	}
	
	handleDragEnter(e) {
		this.handleDrags(e);
	}
	
	handleDragOver(e) {
		this.handleDrags(e);
	}
	
	handleDragLeave(e) {
		//console.log("handleDragLeave" ,e.target.getAttribute('name'))

		e.preventDefault();
		e.stopPropagation();		
		
		if( e.target.getAttribute('name') === "LS"){
			this.setState({
				...this.state,
				isDraggingLS: false
			});
			typeImg="LS";
		}
		else if( e.target.getAttribute('name') === "IM"){
			this.setState({
				...this.state,
				isDragging: false
			});
			typeImg="IM";
		}
	}
	
	onChange(e) {
		e.preventDefault()
		const files = e.target.files;
		[].forEach.call(files, this.handleFiles.bind(this));
	}
	
	handleDrop (e){		
		

		e.stopPropagation();
		e.preventDefault();		
		const data = e.dataTransfer;
		const files = data.files;
		//console.log("Oops...you dropped this: ", files);	
		for( let i = 0; i < files.length ; i++ ) {
			this.handleFiles( files[i], files.length, i+1 );
		}
		//[].forEach.call(files, this.handleFiles.bind(this));
		//this.updateVal()
		if( e.target.getAttribute('name') === "LS"){
			this.setState({
				...this.state,
				isDraggingLS: false
			});
			typeImg="LS";
		}
		else if( e.target.getAttribute('name') === "IM"){
			this.setState({
				...this.state,
				isDragging: false
			});
			typeImg="IM";
		}
	}
	VerificarFiles =()=>{
			//console.log(this.state.files)
			//console.log(this.state.urls)
	}
	
	handleFiles = async (file, length, currentL) => {		
		// this could be refactored to not use the file reader	
		//console.log("Entre aqui")	
		var reader = new FileReader();		
		reader.onloadend = async() => {
			var imageUrl = window.URL.createObjectURL(file);	
			
			//console.log("imageUrl ",imageUrl, "file.Lenght", this.state.files.length, "typeImg ",typeImg);

			await this.setState({
				files: [file, ...this.state.files],
				urls: [imageUrl, ...this.state.urls]
			});
			if (currentL === length){
				this.updateVal()
			}
			//console.log("this Length: ",length , 'CurrentL ' ,currentL);
					
		}
			
		reader.readAsDataURL(file);
	}

	uploadImages = async () =>{
		await this.setState({
			movioImagen:false
		});

		for( let i = 0; i < this.state.filesDeleteFromDB.length; i++ )
		{
			let Borrar = this.state.filesDeleteFromDB[i]
			let deleteImagesAPI = domain + "/api/v1/product/deleteImage?upc=" + this.props.state.UPCVariation;

            await this.setState({
                imagesToDelete : { file: Borrar }
            })
            // console.log(this.state.imagesToDelete)
            var JsonDeleteImage =  JSON.stringify(this.state.imagesToDelete)
            console.log (JsonDeleteImage, deleteImagesAPI)
            try {
                await axios
                            .post(deleteImagesAPI, JsonDeleteImage)
                            .then(res => {            
                               // console.log("result ", res);
                            });
              } catch (errorPost) {
                console.log(errorPost);
              }
		}
		
		for( let i = 0; i < this.state.columnsFromBackend[0].ObjectGroup.items.length; i++ ) {
			let normalImages = '';
			//console.log(this.state.columnsFromBackend[0].ObjectGroup.items[i].indentificador, this.state.columnsFromBackend[0].ObjectGroup.items[i].file.name)
			if (this.state.columnsFromBackend[0].ObjectGroup.items[i].indentificador === "IM"){
				normalImages = domain + "/api/v1/product/addImage2?upc=" + this.props.state.UPCVariation;

				console.log(this.state.columnsFromBackend[0].ObjectGroup.items[i].file.name)
				var formdata = new FormData();
				formdata.append("image", this.state.columnsFromBackend[0].ObjectGroup.items[i].file);
				formdata.append('ranking', i + 1)
				var requestOptions = {
					method: 'POST',
					body: formdata,
					redirect: 'follow'
				};
				console.log("image", this.state.columnsFromBackend[0].ObjectGroup.items[i].file, 'ranking', i + 1)
				await fetch(normalImages , requestOptions)
				.then(response => response.text())
				.then(result => {
					console.log(result)
					var result1 = JSON.parse(result);
					console.log(result1['url'])
					this.setState( {  responseImages: [...this.state.responseImages, result1['url'] ]  } );
					
					this.setState({
						file:[],
						urls:[]
					});
				})
				.catch(error => console.log('error', error));	


			}
			else if (this.state.columnsFromBackend[0].ObjectGroup.items[i].indentificador === "LS"){

				normalImages = domain + "/api/v1/product/addLifeStyleImage2?upc=" + this.props.state.UPCVariation;
				//console.log(this.state.columnsFromBackend[0].ObjectGroup.items[i].file.name)
				var formdata = new FormData();
				formdata.append("image", this.state.columnsFromBackend[0].ObjectGroup.items[i].file);
				formdata.append('ranking', i + 1)
				var requestOptions = {
					method: 'POST',
					body: formdata,
					redirect: 'follow'
				};
				//console.log("image", this.state.columnsFromBackend[0].ObjectGroup.items[i].file, 'ranking', i + 1)
				await fetch(normalImages , requestOptions)
				.then(response => response.text())
				.then(result => {
					// this.setState( {  responseImages: [...this.state.responseImages, result ]  } ) 
					//console.log(result)
					var result1 = JSON.parse(result);
					//console.log(result1['url'])
					this.setState( {  responseImages: [...this.state.responseImages, result1['url'] ]  } );
					
					this.setState({
						file:[],
						urls:[]
					});
				})
				.catch(error => console.log('error', error));	
			}
			else if (this.state.columnsFromBackend[0].ObjectGroup.items[i].indentificador === "DB"){

				normalImages = domain + "/api/v1/product/updateRanking?upc=" + this.props.state.UPCVariation;
				// var token = this.getCookie( 'token' );
				// let headerProposal ={
				// 	headers: {
				// 		'X-Viastara-Token': token,
				// 	}
				// }
				let Json ={"file":this.state.columnsFromBackend[0].ObjectGroup.items[i].file.name, "ranking": i+1 }
				//console.log(Json)
				var JsonRank =  JSON.stringify(Json)
				//console.log(JsonRank)
				try {
					await axios
								.post(normalImages, JsonRank)
								.then(res => {            
									//console.log("result ", res);
								});
				  } catch (errorPost) {
					console.log(errorPost);
				  }
			}
		}
		this.setState({ columnsFromBackend: [{
			ObjectGroup: {
			  name: "Images",
			  items: []
			}
		  }] ,
		  itemsFromBackend : []
		});
		
		this.ChangeUPC(this.props.state.UPCVariation)

	}

	testUploadImg = async () => {
		//console.log(this.props.state.UPCList)
		//console.log(this.props.state.UPCVariation)
		let normalImages ="https://cato.skleo.com/api/v1/product/addImage2?upc=" + this.props.state.UPCVariation;
		//console.log(normalImages);

		for( let i = 0; i < this.state.files.length; i++ ) 
		{
			//console.log(this.state.files[i], this.state.urls[i]);

			var formdata = new FormData();
			formdata.append("image", this.state.files[i]);
			formdata.append('ranking', i + 1)
			var requestOptions = {
				method: 'POST',
				body: formdata,
				redirect: 'follow'
			};
			
			await fetch(normalImages , requestOptions)
			.then(response => response.text())
			.then(result => {
				// this.setState( {  responseImages: [...this.state.responseImages, result ]  } ) 
				//console.log(result)
				var result1 = JSON.parse(result);
				//console.log(result1['url'])
				this.setState( {  responseImages: [...this.state.responseImages, result1['url'] ]  } );
				
				this.setState({
					file:[],
					urls:[]
				});
			})
			.catch(error => console.log('error', error));			
		}

		this.props.updateImgVariations(this.state.responseImages);
		//console.log(this.state.responseImages);

		//console.log(this.props.state);
	}

	testUploadImgLS = async () => {
		//console.log(this.props.state.UPCList)
		//console.log(this.props.state.UPCVariation)
		
		let lifeStyleImage="https://cato.skleo.com/api/v1/product/addLifeStyleImage?upc=" + this.props.state.UPCVariation;
		//console.log("lifeStyleImage ", lifeStyleImage);
		for( let i = 0; i < this.state.filesLS.length; i++ ) 
		{
			//console.log("LS Images");
			//console.log(this.state.filesLS[i], this.state.urlsLS[i]);

			var formdata = new FormData();
			formdata.append("image", this.state.filesLS[i], this.state.urlsLS[i]);
			var requestOptions = {
				method: 'POST',
				body: formdata,
				redirect: 'follow'
			};
			await fetch(lifeStyleImage , requestOptions)
			.then(response => response.text())
			.then(result => {
				// this.setState( {  responseImages: [...this.state.responseImages, result ]  } ) 
				//console.log(result)
				var result1 = JSON.parse(result);
				//console.log(result1['url'])
				this.setState( {  responseImagesLS: [...this.state.responseImagesLS, result1['url'] ]  } );
			})
			.catch(error => console.log('errorLS', error));			
		}
		this.props.updateImgVariationsLS(this.state.responseImagesLS);

		//console.log(this.props.state);
	}

	imagesDrag(){
		const [columns] = this.state.columnsFromBackend;
		return (
		
		<div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
			<DragDropContext
			onDragEnd={result => this.onDragEnd(result, columns)}
			>
			{Object.entries(columns).map(([columnId, column], index) => {
				
				return (
				<div
					style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
					}}
					key={columnId}
				>
					<h2>{column.name}</h2>
					
					<div style={{ margin: 8 }}>
					<Droppable droppableId={columnId} key={columnId}>
						{(provided, snapshot) => {
						return (
							<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={{								
								padding: 4,
								minHeight: 500
							}}
							>
							{column.items.map((item, index) => {
								if (index === 0){
									return (
										<Draggable
											key={item.id}
											draggableId={item.id}
											index={index}
										>
											{(provided, snapshot) => {
											return (
												<div ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={{
													userSelect: "none",
													width: 500,
													padding: 16,
													margin: "0 0 8px 0",
													minHeight: "50px",
													backgroundColor: "rgb(217, 217, 217)",
													color: "black",
													...provided.draggableProps.style
												}}
												className="previewItem" 
												>
												<img src={item.content} height="100px" width="100px" className="imagePreview"></img>
													<div className="details">
														<p>{item.file.name}</p>
														<button onClick={() => this.onRemove(index)} style={{backgroundColor: "#778294",
																				color: "white",
																				width: "100px",
																				height: "30px",
																				borderRadius: "5px"}}>
														Delete</button>
														<p>{item.indentificador}</p>
														<img src={crown} height='35px'></img>
													</div>
												</div>
											);
											}}
										</Draggable>
										);
								}
								else{
									return (
										<Draggable
											key={item.id}
											draggableId={item.id}
											index={index}
										>
											{(provided, snapshot) => {
											return (
												<div ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={{
													userSelect: "none",
													width: 500,
													padding: 16,
													margin: "0 0 8px 0",
													minHeight: "50px",
													backgroundColor: "rgb(217, 217, 217)",
													color: "black",
													...provided.draggableProps.style
												}}
												className="previewItem" 
												>
												<img src={item.content} height="100px" width="100px" className="imagePreview"></img>
													<div className="details">
														<p>{item.file.name}</p>
														<button onClick={() => this.onRemove(index)} style={{backgroundColor: "#778294",
																				color: "white",
																				width: "100px",
																				height: "30px",
																				borderRadius: "5px"}}>
														Delete</button>
														<p>{item.indentificador}</p>
													</div>
												</div>
											);
											}}
										</Draggable>
										);
								}
								
							})}
							{provided.placeholder}
							</div>
						);
						}}
					</Droppable>
					</div>
				</div>
				);
			})}
			</DragDropContext>
		</div>
		);
	}

	imageUploaded= () =>{
		
		return(
		<React.Fragment>		
			{ this.props.state.responseImages.map((image, index) => {
				if(index == 0){
				return(
					<React.Fragment key={image}>
						<h1>Imagen primaria</h1>
						<a href={image}  target="_blank">{image}</a>
					</React.Fragment>
				)
			}
			else{
				return(
					<React.Fragment key={image}>
						<h1>Imagenes Secundarias</h1>
						<a href={image}  target="_blank">{image}</a>
					</React.Fragment>
				)
			}
			})}		
		</React.Fragment>
		)
	}

	imageUploadedLS= () =>{
		return(
		<React.Fragment>		
			{ this.props.state.responseImagesLS.map((image, index) => {
				//console.log("Index LS ", index);
				return(
					<React.Fragment key={image}>
						<h1>Imagen LS {  index + 1}</h1>
						<a href={image}  target="_blank">{image}</a>
					</React.Fragment>
				)
			})}		
		</React.Fragment>
		)
	}
	updateVal= async (file) =>{
		//console.log("Entre por aca")	
		 let dragAndDropFiles = []

		 await this.setState({
			 movioImagen:true
		 });
		
		//console.log(this.state.files)
		let items = this.state.columnsFromBackend[0].ObjectGroup.items;
		let existe = false;
		if (items.length >= 1){
			for(let i = 0; i < this.state.urls.length ; i++ ){
				for(  let x =0; x < items.length ; x++ ){
					if (items[x].file.name === this.state.files[i].name){
						existe = true
					}
					
				}
				if (existe === true){
					console.log("El Archivo ya existe")
				}
				else{
					dragAndDropFiles.push({
						id: uuid(),
						ranking: i,
						indentificador: typeImg,
						content: this.state.urls[i],
						file: this.state.files[i]
					});	
					existe = false
				}
			}
		}
		else{
			for( let i = 0; i < this.state.urls.length ; i++ ){
				
					dragAndDropFiles.push({
						id: uuid(),
						ranking: i,
						indentificador: typeImg,
						content: this.state.urls[i],
						file: this.state.files[i]
					});	
	
			}
		}
		
		// for( let i = 0; i < this.state.urls.length ; i++ ) {
					
		// }

		for( let i = 0; i < dragAndDropFiles.length ; i++ ) {
			//console.log( dragAndDropFiles[i] )

			this.state.itemsFromBackend.push({
				id: dragAndDropFiles[i].id,
				ranking: dragAndDropFiles[i].ranking,
				indentificador: dragAndDropFiles[i].indentificador,
				content: dragAndDropFiles[i].content,
				file: dragAndDropFiles[i].file
			})
		}
		
		//this.state.itemsFromBackend.push(guardadoDB)
		
		//console.log(this.state.itemsFromBackend)
		await this.setState({
			columnsFromBackend :[{
			  ObjectGroup: {
				name: "Images",
				items: this.state.itemsFromBackend
			  }
			}]
		})
		  
		this.el.scrollIntoView({ behavior: 'smooth' }); // Scrool Toda la pagina 
		var element = document.getElementById("divScroll");
		element.scrollTop = element.scrollHeight - element.clientHeight;
	}
	comparar(a,b){
        //console.log(a.ranking, b.ranking)
        return a.ranking - b.ranking
    }
	ChangeUPC= async(e) =>{
		
		let val = e
		if (val !== ""){
			// var token = this.getCookie( 'token' );
			// 	let headerProposal ={
			// 		headers: {
			// 			'X-Viastara-Token': token,
			// 		}
			// 	}
			let UrlGetExtendedInfo = domain + "/api/v1/product/getImages?upc=" + val;
			try{
				await axios
				.get(UrlGetExtendedInfo)
				.then(res => {
					//console.log(res);
					res["data"]["images"].sort(this.comparar)
					for( let i = 0; i < res["data"]["images"].length ; i++ ) {
					this.state.itemsFromBackend.push({
						id: uuid(),
						ranking: res["data"]["images"][i].ranking,
						indentificador: 'DB',
						content: res["data"]["images"][i].img,
						file: {name: res["data"]["images"][i].img.split('/').pop() }
					});	
				}})
			}
			
			catch{
				console.log("Server Error")
			}
			await this.setState({
				columnsFromBackend :[{
				  ObjectGroup: {
					name: "Images",
					items: this.state.itemsFromBackend
				  }
				}]
			  })
		}
	}

	handleComboBoxXhange = (event) =>{

		console.log("Movio imagen ",this.state.movioImagen);
if(this.state.movioImagen){
	const resp = window.confirm("Datos sin guardar");
	console.log(resp);
	if(resp){
		// Sin guardar
		event.persist();
		const val = event.target.value;
		this.props.changeVariationUPC(val);
		this.setState({ columnsFromBackend: [{
			ObjectGroup: {
				name: "Images",
				items: []
				}
			}],
			itemsFromBackend : [],
			files: [],
			filesDeleteFromDB : [],
			urls: []
		});
		//console.log("Valor", val)
		if (val !== ''){
			this.ChangeUPC(val)
		}

		
		this.setState({
			movioImagen:false
		});
	}
	else{
		// Quede en el mismo UPC
		event.target.value = this.props.state.UPCVariation;
	}
}
else{
	event.persist();
		const val = event.target.value;
		this.props.changeVariationUPC(val);
		this.setState({ columnsFromBackend: [{
			ObjectGroup: {
				name: "Images",
				items: []
				}
			}],
			itemsFromBackend : [],
			files: [],
			filesDeleteFromDB : [],
			urls: []
		});
		//console.log("Valor", val)
		if (val !== ''){
			this.ChangeUPC(val)
		}
}


        
        
	  }

	  LSFrame(dropClassLS){
	
		  return(
			  <React.Fragment>
					<h2>Imagenes Estilo de Vida</h2>
					<input type="file" onChange={this.onChange.bind(this)} accept="image/*"	multiple />
					<div  className={dropClassLS} name="LS" onDrop={this.handleDrop.bind(this)}	 onDragOver={this.handleDragOver.bind(this)} onDragEnter={this.handleDragEnter.bind(this)}
					onDragLeave={this.handleDragLeave.bind(this)} />	
					<button className="btnUpdateImg" onClick={() => this.uploadImages()}>Upload Images</button>		
			  </React.Fragment>
		  )

	  }
	  IPFrame(dropClass){

		return(
			<React.Fragment>
				<h2>Imagenes Primaria y Secundarias</h2>
								
				<input type="file" onChange={this.onChange.bind(this)} accept="image/*" multiple/>
				<div name="IM" className={dropClass} onDrop={this.handleDrop.bind(this)} onDragOver={this.handleDragOver.bind(this)} onDragEnter={this.handleDragEnter.bind(this)}
				onDragLeave={this.handleDragLeave.bind(this)} />
				<button className="btnUpdateImg" onClick={() => this.uploadImages()}>Upload Images</button>		
			</React.Fragment>
		)
	  }
	  updateCurrentFrame= (e)=>{
		  if (this.props.state.UPCVariation !== ""){
			this.setState({
				currentFrame : e 
			})
		  }
		  else{
			alert("Select UPC variation!");
		  }
		
	  }
	  
	  uploadFrames = (e , dropClass , dropClassLS )=>{
		switch(this.state.currentFrame){
        	case 1:
				return(
					this.IPFrame(dropClass)
					)
			case 2:
					return(
						this.LSFrame(dropClassLS)
					)
			default:
				return <div>Seleccionar una opcion</div>
		}

	  }
	
    render(){

        const {urls, files, isDragging, filesLS, urlsLS, isDraggingLS} = this.state;
		const dropClass = isDragging ? "dragDrop dragging" : "dragDrop";
		const dropClassLS = isDraggingLS ? "dragDrop dragging" : "dragDrop";

        return(
            <div className="">
				
                <h1 style={{marginBottom:"10px", textAlign:"center"}}>IMAGENES</h1>

				<select id="UPCVariation" name="UPCVariation" onChange={this.handleComboBoxXhange} >
					<option value="">-Seleccionar UPC-</option>
					{this.props.state.UPCList.map(x => <option key = {x} value = {x}>{x}</option>) }
            	</select>

				{/* <div className='SelectUPCIMG'><label htmlFor="" style={{marginRight:"10px"}}>UPC VARIATIONS</label><button onClick={() => this.ChangeUPC()}>ChangeUPC</button>	 { this.props.comboboxFill() }</div> */}
							
				<div className="formImgsExt">									
					<div className="dropImgExt">
					
						<div id="app">										
							<div className="uploadInput" >
								<button className="btnUpdateImg" onClick={() =>this.updateCurrentFrame(1)}>Imagenes Primaria y Secundarias</button>
								<button className="btnUpdateImg" onClick={() =>this.updateCurrentFrame(2)}>Imagenes Estilo de Vida</button>
								{this.uploadFrames(1, dropClass, dropClassLS )}
								{/* <h2>Imagenes Primaria y Secundarias</h2>
								
								<input type="file"
									onChange={this.onChange.bind(this)}
									accept="image/*"
									multiple
								/>
								<div   name="IM" className={dropClass} 
									onDrop={this.handleDrop.bind(this)}
									onDragOver={this.handleDragOver.bind(this)}
									onDragEnter={this.handleDragEnter.bind(this)}
									onDragLeave={this.handleDragLeave.bind(this)} >
								</div >
								<button className="btnUpdateImg" onClick={() => this.uploadImages()}>Upload Images</button>		 */}
								{/* <button className="btnUpdateImg" onClick={this.testUploadImg}>Upload Images</button>		 */}

							</div>
							
						</div>

						{/*<div id="appLS">
														
							 <div className="uploadInput" >
								<h2>Imagenes Estilo de Vida</h2>
								<input type="file"
									onChange={this.onChange.bind(this)}
									accept="image/*"
									multiple
								/>
								<div  className={dropClassLS} 
									 name="LS" onDrop={this.handleDrop.bind(this)}
									 onDragOver={this.handleDragOver.bind(this)}
									 onDragEnter={this.handleDragEnter.bind(this)}
									 onDragLeave={this.handleDragLeave.bind(this)}
									 >
								</div>	

								<button className="btnUpdateImg" onClick={this.testUploadImgLS}>Upload Images</button>		
								
							</div> 
							
						</div> */}
					</div>

					<div className="urlImgExt" style={{overflowY: "scroll", maxHeight:"500px"}} name="divScroll" id='divScroll' ref={el => { this.el = el; }} >
						<div className="imagePreviewContainer">
							{this.imagesDrag()}
							{/* {
								urls && (urls.map((url, i) => (
									<div key={files[i].name} className="previewItem" style={{height:"100px"}}>
										<img alt="" className="imagePreview" src={url} />
										<div className="details">
											<h6>{files[i].name.substr(0,10)}</h6>
											<i className="material-icons" 
											onClick={() => this.onRemove(i)}>delete</i>
											<input key={i+1} value={i+1}></input>
										</div>
									</div>
								)))
							} */}
						</div>
					</div>
				</div>

                <div className="">
                    
                    <div className ="divButtonForm">
                        <button className="btnForm btnAfter" onClick={this.props.handlePageChangeBefore}> Anterior Pestaña</button>
						
                        <button className="btnForm btnNext" onClick={this.props.handlePageChange}> Siguente Pestaña</button>
                    </div>
                </div>
            </div>
        )
    };
}
export default DragAndDrop;